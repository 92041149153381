import { motion } from "framer-motion"
import Dropzone from "react-dropzone"
import { FormInstance } from "antd"

type addBillOrOfferModalScreen3Type = {
    form: FormInstance
    visible: boolean
    documents: any[]
    setdocuments: (e: any) => void
}

export default function AddBillOrOfferModalScreen3(props: addBillOrOfferModalScreen3Type) {
    const handleNewDocs = (files: any) => {
        props.setdocuments(files)
    }

    return (
        <motion.div
            initial={{
                x: "100%",
            }}
            animate={{
                x: props.visible ? 0 : "100%",
            }}
            transition={{
                type: "ease",
            }}
            className="absolute top-0 left-0 w-full h-full pl-3 z-30 bg-white pb-2 overflow-y-scroll">
            <Dropzone onDrop={(acceptedFiles) => handleNewDocs(acceptedFiles)}>
                {({ getRootProps, getInputProps }) => (
                    <div className="h-full flex flex-col">
                        <h3 className="font-semibold text-lg pt-4">
                            Bilder, Dokumente, o. Ä. anhängen
                            <span className="text-xs text-gray-400 font-semibold text-center pl-4">Maximale Uploadgröße: 10 MB</span>
                        </h3>
                        <div
                            className={`w-full grow cursor-pointer flex border rounded-default mt-2 ${
                                !props.documents ? "items-center justify-center" : "flex-wrap p-4 gap-4"
                            }`}
                            {...getRootProps()}>
                            <input {...getInputProps()} />
                            {!props.documents && (
                                <p className="uppercase font-semibold text-gray-400">Dokumente hier ablegen oder Klicken und auswählen</p>
                            )}
                            {props.documents &&
                                props.documents?.map((item: any, index: number) => (
                                    <div className="h-32 w-32 border rounded-default flex items-center justify-center">
                                        <p className="truncate">{item.name}</p>
                                    </div>
                                ))}
                        </div>
                    </div>
                )}
            </Dropzone>
        </motion.div>
    )
}
