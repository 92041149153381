import { motion } from "framer-motion"
import { ReactComponent as CLOSE } from "../../../assets/CloseICON.svg"
import { ReactComponent as ARROWRIGHT } from "../../../assets/ChevronRight.svg"
import { ReactComponent as DELETE } from "../../../assets/DeleteICON.svg"
import { ReactComponent as ADDPERSON } from "../../../assets/AddPerson.svg"
import { ReactComponent as PLUS } from "../../../assets/PlusICON.svg"
import { ReactComponent as MINUS } from "../../../assets/Minus.svg"
import BaseInputField from "../../../components/elements/BaseInputField"
import dayjs from "dayjs"
import BaseInputSelection from "../../../components/elements/BaseInputSelection"
import BaseInputArea from "../../../components/elements/BaseInputArea"
import { InputObject } from "../../../components/types"
import { getSingleTaskType, sessionCalendarDataType } from "../types"
import { Form, Tag } from "antd"
import BasePopConfirm from "../../../components/elements/BasePopConfirm"
import { useForm } from "antd/es/form/Form"
import { ReactNode, useEffect } from "react"
import { HashLink } from "react-router-hash-link"
import { handleTimeChange } from "../../../services/functions"
import { RenderWhenAuthorized } from "../../../components/authentication/RenderWhenAuthorized"
import { Roles } from "../../../auth/RoleMapping"

type SessionDetailSidebarType = {
    searchForObject: (e: string) => void
    objects: InputObject[]
    objectIsLoading: boolean
    showDetailEvent: boolean
    handleDeleteSession: () => void
    close: () => void
    session?: sessionCalendarDataType
    updatedData?: sessionCalendarDataType
    setUpdatedData: (e?: any) => void // TODO
    handleUpdateSession: () => void
    handleAddNewSession: () => void
    assginedTasks: getSingleTaskType[]
    setassginedTasks: (e: any) => void // TODO
    unassignedTasks?: getSingleTaskType[]
    setunassignedTasks: (e: any) => void // TODO
    tasksByObjectIsLoading?: boolean
    addAttendee: () => void
    manualCheckInOrOut: (e: sessionCalendarDataType | undefined) => void
}

export default function SessionDetailSidebar(props: SessionDetailSidebarType) {
    console.log(props.unassignedTasks)

    const [form] = useForm()
    useEffect(() => {
        form.setFieldsValue({
            selectObject: props.session?.location,
            date: props.session ? dayjs(props.session.start).format("YYYY-MM-DD") : "",
            start: props.session ? dayjs(props.session?.start).format("HH:mm") : "",
            end: props.session ? dayjs(props.session?.end).format("HH:mm") : "",
            selectedTasks: props.session?.tasks,
        })
    }, [props.session])
    return (
        <Form form={form} onFinish={!props.session ? props.handleAddNewSession : props.handleUpdateSession}>
            <motion.div
                initial={{
                    width: 0,
                    padding: 0,
                    gap: 0,
                }}
                animate={{
                    width: props.showDetailEvent ? "24rem" : "0px",
                    padding: props.showDetailEvent ? "1rem" : "0px",
                    gap: props.showDetailEvent ? "0.5rem" : "0px",
                }}
                transition={{
                    duration: 0.5,
                    type: "ease",
                }}
                className="overflow-x-hidden hidden sm:flex flex-col bg-white rounded-default relative shadow-lg ml-3 overflow-y-scroll h-full">
                {/* Button Row */}
                <div className={`flex w-full ${props.session ? "justify-between" : "justify-end"} h-8 gap-4 pr-2`}>
                    {props.session && (
                        <div className="flex items-center justify-center gap-0">
                            <Tag
                                color={
                                    props.session?.status === "scheduled"
                                        ? "yellow"
                                        : props.session?.status === "completed"
                                        ? "green"
                                        : props.session?.status === "failed"
                                        ? "red-inverse"
                                        : "blue"
                                }>
                                Status:{" "}
                                {props.session?.status === "scheduled"
                                    ? "Geplant"
                                    : props.session?.status === "completed"
                                    ? "Abgeschlossen"
                                    : props.session?.status === "failed"
                                    ? "Fehlgeschlagen"
                                    : "Wird bearbeitet..."}
                            </Tag>
                            <Tag color={props.session?.checkInStatus === "checked-in" ? "green-inverse" : ""}>
                                CheckInStatus : {props.session?.checkInStatus === "checked-in" ? "Aktiv" : "Inaktiv"}
                            </Tag>
                        </div>
                    )}
                    <div className="flex gap-4">
                        {props.session && (
                            <BasePopConfirm
                                label={"Termin löschen?"}
                                placement="bottomLeft"
                                okButtonLabel={"Löschen"}
                                okButtonClick={props.handleDeleteSession}
                                children={<DELETE className="w-6 h-6 hover:text-red-500 cursor-pointer" />}
                            />
                        )}
                        <Form.Item onReset={() => props.setUpdatedData(undefined)}>
                            <ARROWRIGHT
                                type="reset"
                                onClick={() => {
                                    props.close()
                                }}
                                className="w-6 h-6 cursor-pointer mb-1"
                            />
                        </Form.Item>
                    </div>
                </div>
                <div className="flex flex-col gap-2">
                    <h3 className="text-xl font-semibold px-2 truncate">{props.session ? props.session?.title : "Neuer Termin"}</h3>
                    <div className="border-b w-full" />
                    {props.session && (
                        <div className="flex gap-0 justify-start pb-2">
                            <Tag>Dauer geschätzt: {props.session?.totalDurationMinutesEstimated?.toString() ?? 0}min</Tag>
                            <Tag
                                color={
                                    props.session.totalDurationMinutesActual && props.session.totalDurationMinutesEstimated
                                        ? props.session?.totalDurationMinutesEstimated + 10 > props.session?.totalDurationMinutesActual
                                            ? "red-inverse"
                                            : ""
                                        : ""
                                }>
                                Dauer tatsächlich:{" "}
                                {props.session?.totalDurationMinutesActual ? props.session?.totalDurationMinutesActual + " min" : "keine Angabe"}
                            </Tag>
                        </div>
                    )}
                </div>
                {/* Tag Row */}
                <div className={`flex flex-col gap-2 overflow-y-scroll grow w-full ${!props.session && "pb-14"}`}>
                    <Form.Item className="mb-0" name="selectObject" rules={[{ required: true, message: "Bitte ausfüllen!" }]}>
                        <BaseInputSelection
                            disabled={props.session ? true : false}
                            label={"Objekt"}
                            placeholder={"Objekt auswählen"}
                            variant="filled"
                            value={props.updatedData?.object_id ?? props.session?.object_id ?? props.session?.location}
                            data={props.objects}
                            onSearch={props.searchForObject}
                            setId={(e) => {
                                form.setFieldsValue({ selectObject: e })
                                props.setUpdatedData((prev: sessionCalendarDataType) => ({
                                    ...prev,
                                    object_id: e,
                                }))
                            }}
                            customLoading={props.objectIsLoading}
                        />
                    </Form.Item>
                    <Form.Item name="date" className="mb-0" rules={[{ required: true, message: "Bitte ausfüllen!" }]}>
                        <BaseInputField
                            disabled={props.session && props.session?.status === "completed"}
                            label={"Datum"}
                            min={dayjs().add(1, "day").format("YYYY-MM-DD")}
                            variant="filled"
                            value={props.updatedData?.date ?? undefined}
                            type={"date"}
                            onChange={(e) =>
                                props.setUpdatedData((prev: sessionCalendarDataType) => ({
                                    ...prev,
                                    date: e,
                                }))
                            }
                        />
                    </Form.Item>
                    <Form.Item name="start" className="mb-0 w-full" rules={[{ required: true, message: "Bitte ausfüllen!" }]}>
                        <BaseInputField
                            disabled={props.session && props.session?.status === "completed"}
                            label={"Startzeit"}
                            variant="filled"
                            explainText="Bitte nur volle oder halbe Stunden auswählen!"
                            type={"time"}
                            onChange={(e) =>
                                props.setUpdatedData((prev: sessionCalendarDataType) => ({
                                    ...prev,
                                    start: handleTimeChange(e),
                                }))
                            }
                        />
                    </Form.Item>
                    <Form.Item name="end" className="mb-0 w-full" rules={[{ required: true, message: "Bitte ausfüllen!" }]}>
                        <BaseInputField
                            explainText="Bitte nur volle oder halbe Stunden auswählen!"
                            disabled={props.session && props.session?.status === "completed"}
                            label={"Endzeit"}
                            variant="filled"
                            steps={1800}
                            min={props.updatedData?.start?.toString() ?? undefined}
                            type={"time"}
                            onChange={(e) =>
                                props.setUpdatedData((prev: sessionCalendarDataType) => ({
                                    ...prev,
                                    end: handleTimeChange(e),
                                }))
                            }
                        />
                    </Form.Item>
                    <BaseInputArea
                        disabled={props.session && props.session?.status === "completed"}
                        label={"Hinweise"}
                        placeholder="Ein Hinweis zur Ausführung..."
                        variant="filled"
                        value={props.updatedData?.description ?? props.session?.description}
                        onChange={(e) =>
                            props.setUpdatedData((prev: sessionCalendarDataType) => ({
                                ...prev,
                                description: e,
                            }))
                        }
                    />
                    {/* Tasks */}
                    <div className="flex flex-col">
                        {/* FAILED TASKS */}
                        {props.session?.failedTasks && props.session?.failedTasks?.length > 0 && (
                            <>
                                <label className={`pl-2`}>Fehlgeschlagene Aufgaben</label>
                                <div className="border-b w-[98%] pt-1" />
                                {props.session?.failedTasks?.length !== 0 &&
                                    props.session?.failedTasks?.map((item) => (
                                        <TaskListElement
                                            disabled
                                            activity={item.activity}
                                            estimatedDurationMinutes={item.estimatedDurationMinutes}
                                            icon={<></>}
                                            addOrRemoveFunction={() => {}}
                                        />
                                    ))}
                            </>
                        )}
                        {/* SELECTED TASKS */}
                        <label className={`pl-2 ${props.assginedTasks.length === 0 && "text-error"}`}>
                            Zugeordnete Aufgaben {props.assginedTasks.length === 0 && " - Bitte Aufgaben hinzufügen"}
                        </label>
                        <div className="border-b w-[98%] pt-1" />
                        {(props.assginedTasks?.length === 0 || !props.assginedTasks) && (
                            <p className="font-semibold text-gray-300 text-xs py-1 text-center">Keine Aufgaben zu diesem Objekt</p>
                        )}
                        {props.assginedTasks?.length !== 0 &&
                            props.assginedTasks?.map((item) => (
                                <TaskListElement
                                    disabled={props.session && props.session?.status !== "scheduled"}
                                    activity={item.activity}
                                    estimatedDurationMinutes={item.estimatedDurationMinutes}
                                    icon={<MINUS className={`w-6 h-6 ${props.assginedTasks.length <= 1 && "text-gray-400"}`} />}
                                    addOrRemoveFunction={() => {
                                        if (props.assginedTasks.length > 1) {
                                            props.setassginedTasks((prev: getSingleTaskType[]) =>
                                                prev.filter((prevItem: { _id: string }) => prevItem._id !== item._id)
                                            )
                                            props.setunassignedTasks((prev: getSingleTaskType[]) => [...prev, item])
                                        }
                                    }}
                                />
                            ))}
                        {/* AVAILABLE TASKS */}
                        {(!props.session?.status || props.session?.status === "scheduled") && (
                            <>
                                <div className="border-b-2 w-[98%] pt-1 mb-4" />
                                <label className="pl-2">Verfügbare Aufgaben</label>
                                <div className="border-b w-[98%] pt-1" />
                                {(props.unassignedTasks?.length === 0 || !props.unassignedTasks) && (
                                    <p className="font-semibold text-gray-300 text-xs py-1 text-center">Keine Aufgaben zu diesem Objekt</p>
                                )}
                                {props.unassignedTasks?.length !== 0 &&
                                    props.unassignedTasks?.map((item) => (
                                        <TaskListElement
                                            activity={item.activity}
                                            estimatedDurationMinutes={item.estimatedDurationMinutes}
                                            icon={<PLUS className="w-6 h-6" />}
                                            addOrRemoveFunction={() => {
                                                props.setunassignedTasks((prev: getSingleTaskType[]) =>
                                                    prev.filter((prevItem: { _id: string }) => prevItem._id !== item._id)
                                                )
                                                props.setassginedTasks((prev: getSingleTaskType[]) => [...prev, item])
                                            }}
                                        />
                                    ))}
                                <div className="border-b-2 w-[98%] pt-1 mb-4" />
                            </>
                        )}
                    </div>
                    {/* CHECKIN & MA ADD */}
                    {props.session && (
                        <div className="flex flex-col gap-2 bg-white w-full pb-4">
                            <RenderWhenAuthorized requiresAll={[Roles.termine_management]}>
                                <div className="flex gap-2 w-full ">
                                    {props.session?.status === "scheduled" && (
                                        <button className="btn btn-primary btn-outline grow" onClick={props.addAttendee}>
                                            <ADDPERSON className="w-5 h-5" />
                                            Mitarbeiter
                                        </button>
                                    )}
                                    {!(props.session && props.session?.status === "completed") && (
                                        <div className="btn btn-primary btn-outline w-fit" onClick={() => props.manualCheckInOrOut(props.session)}>
                                            {props.session.checkInStatus === "checked-in" ? "CheckOut" : "CheckIn"}
                                        </div>
                                    )}
                                    <HashLink className="btn btn-primary btn-outline w-fit" to={"/termin/" + props.session.id}>
                                        Details
                                    </HashLink>
                                </div>
                            </RenderWhenAuthorized>
                        </div>
                    )}
                </div>
                {/* Exit & Save */}
                {!(props.session && props.session?.status === "completed") && (
                    <motion.div
                        initial={{
                            // width: 0,
                            height: 0,
                            paddingTop: "1rem",
                        }}
                        animate={{
                            // width: props.updatedData ? "" : "0px",
                            height: props.updatedData || (props.session?.tasks ? props.session?.tasks : []) !== props.assginedTasks ? "" : "0px",
                            paddingTop: props.updatedData || (props.session?.tasks ? props.session?.tasks : []) !== props.assginedTasks ? "" : "1rem",
                        }}
                        transition={{
                            type: "ease",
                            duration: 0.5,
                        }}
                        className={`flex flex-row justify-between overflow-y-hidden ${
                            !props.session && "absolute pt-2 bottom-2 w-full pr-9 bg-white"
                        }`}>
                        <Form.Item>
                            <button
                                type="reset"
                                className="btn btn-sm btn-error btn-outline"
                                onClick={() => {
                                    props.setUpdatedData(undefined)
                                    props.close()
                                }}>
                                <CLOSE className="w-6 h-6" />
                            </button>
                        </Form.Item>
                        <Form.Item>
                            <button className="btn btn-success btn-sm" type="submit">
                                Speichern
                            </button>
                        </Form.Item>
                    </motion.div>
                )}
            </motion.div>
        </Form>
    )
}

type TaskListElementType = {
    activity: string
    disabled?: boolean
    estimatedDurationMinutes: number
    addOrRemoveFunction: () => void
    icon: ReactNode
}

function TaskListElement(props: TaskListElementType) {
    return (
        <div className="flex flex-row gap-0.5 border mt-1 rounded-default">
            <div className="w-full leading-4 p-2">
                <p className="text-xs font-semibold">{props.activity}</p>
                <p className="text-gray-400 text-xs">{props.estimatedDurationMinutes} min geschätzt</p>
            </div>
            <RenderWhenAuthorized requiresAll={[Roles.termine_management]}>
                {!props.disabled && (
                    <div
                        onClick={props.addOrRemoveFunction}
                        className="border-l flex items-center justify-center py-2 px-3 cursor-pointer rounded-r-default hover:bg-black/10">
                        {props.icon}
                    </div>
                )}
            </RenderWhenAuthorized>
        </div>
    )
}
