import React, { createContext, useState } from "react"
import { SidePanelContextProps, SidePanelContextProviderProps } from "../types"

export const SidePanelContext = createContext<SidePanelContextProps | undefined>(undefined)

export const SidePanelContextProvider: React.FC<SidePanelContextProviderProps> = ({ children }: SidePanelContextProviderProps) => {
    const [open, setOpen] = useState(false)
    const [sidePanelId, setSidePanelId] = useState<number>(0)
    const [animation, setAnimation] = useState(false)

    const trigger = (nr: number) => {
        if (!open) {
            setSidePanelId(nr)
            setOpen(!open)
            setAnimation(!animation)
        } else {
            setAnimation(!animation)
            setTimeout(() => {
                setOpen(!open)
                setSidePanelId(nr)
            }, 250)
        }
    }

    return (
        <SidePanelContext.Provider
            value={{
                open,
                animation,
                trigger,
                sidePanelId,
            }}>
            {children}
        </SidePanelContext.Provider>
    )
}
