import { useQueryClient } from "@tanstack/react-query"
import CameraComponent from "../../../components/elements/BaseCameraComponent"
import BaseModal from "../../../components/layout/BaseModal"
import { useAddImgsToTaskMutation } from "../api/services"

type AddImagesModalType = {
    modalId: number
    taskId: string
    queryKeyOfSession: string
    handleAddImgs: () => void
}

export default function AddImagesModal(props: AddImagesModalType) {
    const queryClient = useQueryClient()
    const { mutate: addImgsToTask } = useAddImgsToTaskMutation(queryClient, props.queryKeyOfSession)
    const uploadImage = async (imageDataUrl: string[]) => {
        // Convert Data URL to Blob
        const dataUrlsToBlobs = (dataUrls: string[]): Blob[] => {
            return dataUrls.map((dataUrl) => {
                const byteString = atob(dataUrl.split(",")[1])
                const mimeString = dataUrl.split(",")[0].split(":")[1].split(";")[0]
                const arrayBuffer = new ArrayBuffer(byteString.length)
                const uintArray = new Uint8Array(arrayBuffer)

                for (let i = 0; i < byteString.length; i++) {
                    uintArray[i] = byteString.charCodeAt(i)
                }

                return new Blob([arrayBuffer], { type: mimeString })
            })
        }

        const blobs = dataUrlsToBlobs(imageDataUrl)
        const formData = new FormData()

        console.log(blobs)
        // Append each Blob to FormData
        blobs.forEach((blob: any) => {
            if (blob) {
                formData.append("documents", blob)
            }
        })
        console.log({ id: props.taskId, formData: formData })
        addImgsToTask({ id: props.taskId, formData: formData })
        props.handleAddImgs()
    }

    return (
        <BaseModal big title={"Bilder hinzufügen"} modalId={props.modalId}>
            <CameraComponent upload={uploadImage} />
        </BaseModal>
    )
}
