import dayjs from "dayjs"
import { NotesObjectType, sessionGetDataType } from "../types"
import { HashLink } from "react-router-hash-link"

type TaskDetailNotesType = {
    notes: NotesObjectType[]
}

export default function TaskDetailNotes(props: TaskDetailNotesType) {
    return (
        <div className="bg-white rounded-default p-4 shadow-lg grow">
            <h3 className="text-xl mb-2 font-bold border-b">Notizen</h3>
            <div className="overflow-y-scroll">
                {props.notes.map((item) => (
                    <div className="w-full py-2 px-4 border-b flex flex-col">
                        <HashLink to={"/termin/" + (item.session as sessionGetDataType)._id} className="text-sm underline font-semibold">
                            Termin vom {dayjs((item.session as sessionGetDataType).startAt).format("DD.MM.YYYY")}
                        </HashLink>
                        <p className="text-sm px-3">{item.note}</p>
                        <p className="text-xs text-gray-400 text-right">
                            erstellt von {item.createdBy.first_name} {item.createdBy.last_name} am {dayjs(item.createdAt).format("DD.MM.YYYY")} um{" "}
                            {dayjs(item.createdAt).format("HH:mm")} Uhr
                        </p>
                    </div>
                ))}
                {props.notes.length === 0 && <p className="text-gray-400 text-xs">Zu dieser Aufgabe wurden keine Notizen erstellt.</p>}
            </div>
        </div>
    )
}
