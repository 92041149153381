import { motion } from "framer-motion"
import BaseInputSelection from "../../../../components/elements/BaseInputSelection"
import BaseInputArea from "../../../../components/elements/BaseInputArea"
import { Customer } from "../../../Kunden/types/Types"
import { ObjectType } from "../../../Objekte/types/types"
import { OfferPost } from "../../../../data/Types"
import { Form, FormInstance } from "antd"
import { useEffect } from "react"
import BaseCheckbox from "../../../../components/elements/BaseCheckbox"
import { InputObject } from "../../../../components/types"

type OfferModalScreen1Type = {
    visible: boolean
    form: FormInstance
    doesLValreadyExist?: boolean
    setsendLV: () => void
    newOffer: OfferPost
    setnewOffer: (e: any) => void
    objectId?: string
    customerArray: Customer[]
    objectArray?: ObjectType[]
    searchTerm: string
    setsearchTerm: (e: string) => void
    isLoadingCustomer: boolean
    errorCustomer: Error | null
}

export default function OfferModalScreen1(props: OfferModalScreen1Type) {
    // FE LOGIC
    useEffect(() => {
        props.setnewOffer({
            date: new Date(),
            mailText:
                "Bitte beachten Sie mögliche Fristen in den beigefügten Dokumenten. Zögern Sie nicht, uns bei Fragen oder weiteren Anliegen zu kontaktieren.",
        })
        props.form.setFieldsValue({
            customer_id: props.newOffer?.customer_id ?? undefined,
            object_id: props.newOffer?.object_id ?? undefined,
            additionalText: props.newOffer?.additionalText ?? undefined,
            mailText:
                props.newOffer?.mailText ??
                "Bitte beachten Sie mögliche Fristen in den beigefügten Dokumenten. Zögern Sie nicht, uns bei Fragen oder weiteren Anliegen zu kontaktieren.",
        })
    }, [])

    return (
        <Form form={props.form}>
            <motion.div
                initial={{
                    x: 0,
                }}
                animate={{
                    x: props.visible ? 0 : "-100%",
                }}
                transition={{
                    type: "ease",
                }}
                className="absolute top-0 left-0 w-full h-full overflow-hidden pl-2">
                <Form.Item className="mb-2 mt-4" name={"customer_id"} rules={[{ required: true, message: "Muss einen Wert enthalten!" }]}>
                    <BaseInputSelection
                        setId={(e) => {
                            props.form.setFieldsValue({ customer_id: e })
                            props.setnewOffer((prev: OfferPost) => ({
                                ...prev,
                                customer_id: e,
                            }))
                        }}
                        label={"Kunde *"}
                        placeholder={"Kunde auswählen"}
                        data={(props.customerArray ?? []) as InputObject[]}
                        onSearch={(e: string) => props.setsearchTerm(e)}
                        customLoading={props.isLoadingCustomer}
                        customError={props.errorCustomer}
                    />
                </Form.Item>
                <Form.Item className="mb-2" name={"object_id"} rules={[{ required: true, message: "Muss einen Wert enthalten!" }]}>
                    <BaseInputSelection
                        setId={(e) => {
                            props.form.setFieldsValue({ object_id: e })
                            props.setnewOffer((prev: OfferPost) => ({
                                ...prev,
                                object_id: e,
                            }))
                        }}
                        label={"Objekt *"}
                        placeholder={"Objekt auswählen"}
                        data={(props.objectArray ?? []) as InputObject[]}
                        disabled={props.objectArray?.length === 0 || !props.objectArray}
                    />
                </Form.Item>
                {props.newOffer?.customer_id !== "" && props.objectArray?.length === 0 && (
                    <p className="text-xs text-gray-300">Diesem Kunde sind keine Objekte zugewiesen.</p>
                )}
                <Form.Item className="mb-2" name={"additionalText"} rules={[{ required: false, message: "Muss einen Wert enthalten!" }]}>
                    <BaseInputArea
                        label={"Zusätzlicher Text auf dem Angebot"}
                        onChange={(e) =>
                            props.setnewOffer((prev: OfferPost) => ({
                                ...prev,
                                additionalText: e,
                            }))
                        }
                    />
                </Form.Item>
                <Form.Item className="mb-2" name={"mailText"} rules={[{ required: true, message: "Muss einen Wert enthalten!" }]}>
                    <BaseInputArea
                        label={"Text in E-Mail"}
                        onChange={(e) =>
                            props.setnewOffer((prev: OfferPost) => ({
                                ...prev,
                                mailText: e,
                            }))
                        }
                    />
                </Form.Item>
                {props.doesLValreadyExist ? (
                    <BaseCheckbox
                        classProps="py-2 self-start"
                        disabled={!props.doesLValreadyExist}
                        setcheck={props.setsendLV}
                        label={"Leistungskatalog anhängen"}
                    />
                ) : (
                    <p className="text-xs pt-4">
                        Momentan wurde noch kein Leistungsverzeichnis zum Objekt erstellt, weswegen Sie kein LV an das Angebot anhängen können.
                    </p>
                )}
            </motion.div>
        </Form>
    )
}
