import { Select } from "antd"
import { useMemo } from "react"
import { InputObject } from "../types"

type BaseInputSelectionType = {
    variant?: "outlined" | "borderless" | "filled" | undefined
    label: string
    placeholder: string
    classProps?: string
    disabled?: boolean
    value?: string
    data: InputObject[]
    setId: (e: string) => void
    onSearch?: (e: string) => void
    customLoading?: boolean
    customError?: Error | null
}

export default function BaseInputSelection(props: BaseInputSelectionType) {
    const arrayData = useMemo(() => {
        return (
            props.data?.map((obj) => ({
                value: obj._id,
                label: obj?.customer?.name
                    ? obj?.customer?.name
                    : obj?.first_name && obj?.last_name
                    ? obj?.first_name + " " + obj?.last_name
                    : obj?.name
                    ? obj.name + " | " + obj?.adress?.street + " " + obj?.adress?.nr + ", " + obj?.adress?.plz + " " + obj?.adress?.city // if data is admin (verwalter) array
                    : obj?.adress?.street + " " + obj?.adress?.nr + ", " + obj?.adress?.plz + " " + obj?.adress?.city || // if data is object array
                      "keine Eingabe",
            })) || []
        )
    }, [props.data])

    // filter the input
    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())

    // change & set data
    const onChange = (value: string) => {
        props.setId(value)
        if (props.onSearch) {
            props.onSearch(value)
        }
    }

    // dynamical request from API
    const filterDynamical = (input: string) => {
        // NOTE beobachten, könnte sein dass hier ein Fehler auftaucht
        if (
            props.onSearch
            // && input !== undefined && input !== null && input !== ""
        ) {
            props.onSearch(input)
        }
    }

    return (
        <div className={`flex flex-col w-full z-10 ${props.classProps}`}>
            <label className="text-sm pl-2 pb-1">{props.label}</label>
            <Select
                disabled={props.disabled}
                showSearch
                variant={props.variant}
                value={props.value}
                placeholder={props.placeholder}
                optionFilterProp="children"
                style={{ height: "3.5rem" }}
                filterOption={props.onSearch ? false : filterOption}
                onSearch={filterDynamical}
                onChange={onChange}
                options={arrayData}
                notFoundContent={
                    props.customLoading
                        ? "Lade Ergebnisse..."
                        : props.customError
                        ? "Error - Please contact Administrator"
                        : "Keine Ergebnisse zu der Suche"
                }
            />
        </div>
    )
}
