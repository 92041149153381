import BaseModal from "../../../components/layout/BaseModal"
import { QrReader } from "react-qr-reader"
import { ReactComponent as RELOAD } from "../../../assets/RefreshICON.svg"

type ScanQrCodeModalProps = {
    modalId: number
    startScanner: boolean
    setstartScanner: () => void
    shutDownCam: () => void
    handleScan: (e: any) => void
}

export default function ScanQrCodeModal(props: ScanQrCodeModalProps) {
    return (
        <BaseModal modalId={props.modalId} title={"QR Code"} size onClose={props.shutDownCam}>
            {props.startScanner ? (
                <div className="w-80 h-fit">
                    <QrReader
                        scanDelay={100}
                        onResult={props.handleScan}
                        className="w-full rounded-default"
                        constraints={{ facingMode: "environment" }}
                    />
                </div>
            ) : (
                <div className="flex w-full h-16 items-center justify-center">
                    <RELOAD className="w-8 h-8 text-primary" onClick={props.setstartScanner} />
                </div>
            )}
            <p className="w-80 font-semibold text-center leading-5 pb-2">Bitte scannen Sie den im Objekt ausgehängten QR Code.</p>
            <p className="w-80 text-gray-300 text-xs text-center">
                Kein QR-Code vorhanden? Bitte kontaktieren Sie Ihren Niederlassungsleiter telefonisch.
            </p>
        </BaseModal>
    )
}
