import { useParams } from "react-router-dom"
import BaseHeadBanner from "../../../../components/elements/BaseHeadBanner"
import BasePage from "../../../../components/layout/BasePage"
import ModalCheckAction from "../../../../components/elements/ModalCheckAction"
import { useContext, useEffect, useState } from "react"
import { ModalContext } from "../../../../components/contexts/ModalContext"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { getSingleBillData, getSingleOfferData } from "../../api/apiCalls"
import Loading from "../../../../components/common/Loading"
import ErrorPage from "../../../404/ErrorPage"
import { useArchiveBillMutation, useCancelBillMutation } from "../../api/services"
import { getPaymentRequirementFromBillData } from "../../../Dashboard/api/apiCalls"
import { useDownloadDocumentMutation } from "../../../api/services"
import BillDetailButtonRow from "./components/BillDetailButtonRow"
import BillDetailPaymentsAndFiles from "./components/BillDetailPaymentsAndFiles"
import BillDetailBillPart from "./components/BillDetailBillPart"
import BillDetailHead from "./components/BillDetailHead"
import ReminderModal from "../../components/payment/reminderModal/ReminderModal"
import { PR } from "../../../../data/Types"
import { useSetReminderMutation } from "../../../Dashboard/api/services"
import BasePreview from "../../../../components/elements/BasePreview"
import { useResendMailMutation } from "../../../../services/api/services"
import { API } from "../../../../api/constants"
import { SidePanelContext } from "../../../../components/contexts/SidePanelContext"
import BaseCheckbox from "../../../../components/elements/BaseCheckbox"
import BaseInputField from "../../../../components/elements/BaseInputField"

export default function BillDetailPage() {
    const { id } = useParams<string>()
    const { data, error, isLoading } = useQuery({
        queryKey: ["singleBill" + id],
        queryFn: () => getSingleBillData(id || ""),
    })
    const modalCon = useContext(ModalContext)
    const {
        data: connectedOffer,
        error: offerErr,
        isLoading: offerLoading,
    } = useQuery({
        queryKey: ["singleOffer" + data?.offer_id],
        queryFn: () => getSingleOfferData(data?.offer_id || ""),
        enabled: !!data?.offer_id,
    })
    const {
        data: connectedPaymentRequirements,
        error: PaymentRequirementsErr,
        isLoading: PaymentRequirementsLoading,
    } = useQuery({
        queryKey: ["PaymentRequirements" + id],
        queryFn: () => getPaymentRequirementFromBillData(data?._id || ""),
        enabled: !!data?._id,
    })

    // Archive Bill Mutation
    const queryClient = useQueryClient()
    const { mutate: archiveBill } = useArchiveBillMutation(queryClient, modalCon, 0)
    const handleArchiving = () => {
        archiveBill({
            id: id ?? data._id,
            body: { archived: !data.archived },
        })
    }

    // Cancel Bill Mutation
    const [whenCancelBillAlsoDeleteOpenPayReqs, setwhenCancelBillAlsoDeleteOpenPayReqs] = useState(true)
    const { mutate: cancelBill } = useCancelBillMutation(queryClient, modalCon, 0)
    const handleCanceling = () => {
        console.log({
            id: id ?? data._id,
            cancelUnpaidPaymentRequirements: whenCancelBillAlsoDeleteOpenPayReqs,
        })
        cancelBill({
            id: id ?? data._id,
            cancelUnpaidPaymentRequirements: whenCancelBillAlsoDeleteOpenPayReqs,
        })
    }

    // send Reminder
    const { mutate: setReminder } = useSetReminderMutation(queryClient, modalCon, 16, "PaymentRequirements" + id)
    const handleSetReminder = () => {
        setReminder(payment?._id ?? "")
    }

    // download file - bill itself or bill attachment
    const { mutate: downloadFile } = useDownloadDocumentMutation()
    const handleDownload = (fileData?: { s3Key: string; filename: string }) => {
        if (fileData) {
            // download bill attachement
            downloadFile({ id: data._id, s3Key: fileData.s3Key, filetype: "bill", filename: fileData.filename })
        } else {
            // download bill itself
            downloadFile({ id: data._id, s3Key: data.s3DocumentRef.s3Key, filetype: "bill", filename: "Rechnung " + data.nr + ".pdf" })
        }
    }

    // preview bill or attachement
    const sidePanelCon = useContext(SidePanelContext)
    const [previewElement, setpreviewElement] = useState<string>()
    const handleChangePreviewElement = (s3Key: string) => {
        if (previewElement === API + `/bill/${data?._id}/download?s3Key=${s3Key}`) {
            sidePanelCon?.trigger(3)
        } else {
            setpreviewElement(API + `/bill/${data?._id}/download?s3Key=${s3Key}`)
        }
    }
    useEffect(() => {
        if (previewElement) {
            sidePanelCon?.trigger(3)
        }
    }, [previewElement])

    // current payment
    const [payment, setpayment] = useState<PR>()

    // mail Preview for SidePanel
    const [mailPreviewContent, setMailPreviewContent] = useState<string>()
    const [resendUri, setResendUri] = useState<string>()
    const [resenEmailAddress, setresenEmailAddress] = useState<string>()
    const { mutate: resendMailFunction } = useResendMailMutation()
    const handleResendMail = (e: string) => {
        resendMailFunction({ path: e, body: resenEmailAddress ? { mailReceivers: [resenEmailAddress] } : undefined })
    }

    return (
        <BasePage>
            <BaseHeadBanner title={"Rechnung " + data?.nr + (data?.canceled ? " (STORNIERT)" : "")} />
            {(isLoading || offerLoading || PaymentRequirementsLoading) && <Loading />}
            {(error || offerErr || PaymentRequirementsErr) && <ErrorPage />}
            {data && !isLoading && !error && (
                <>
                    <BillDetailHead
                        data={data}
                        setResendUri={setResendUri}
                        setMailPreviewContent={setMailPreviewContent}
                        connectedOffer={connectedOffer}
                    />
                    <BillDetailBillPart data={data} positions={data.positions} netto={data.totalNetto} brutto={data.totalBrutto} />
                    <BillDetailPaymentsAndFiles
                        connectedPaymentRequirements={connectedPaymentRequirements}
                        setpayment={setpayment}
                        data={data}
                        handleDownload={handleDownload}
                        handleChangePreviewElement={handleChangePreviewElement}
                    />
                    <BillDetailButtonRow
                        stornoS3Ref={
                            data?.stornoBillS3DocumentRef && {
                                s3Key: data.stornoBillS3DocumentRef?.s3Key,
                                filename: data.stornoBillS3DocumentRef?.title,
                            }
                        }
                        handleDownload={handleDownload}
                        canceled={data.canceled}
                        handleChangePreviewElement={() => handleChangePreviewElement(data?.s3DocumentRef?.s3Key)}
                    />
                </>
            )}
            <ModalCheckAction
                modalId={0}
                headline={data?.archived ? "Archivieren Rückgängig machen?" : "Wirklich Archivieren?"}
                text={
                    data?.archived
                        ? "Sind Sie sicher, dass Sie die Rechnung nicht mehr archivieren wollen?"
                        : "Sind Sie sich sicher, dass Sie die Rechnung archivieren wollen?"
                }
                buttonText={data?.archived ? "Rückgängig" : "Archivieren"}
                buttonColor={"bg-blue-300 hover:bg-blue-400 border-none"}
                func={handleArchiving}
            />
            <ModalCheckAction
                modalId={2}
                headline={"Wirklich Stornieren?"}
                text={"Sind Sie sich sicher, dass Sie die Rechnung stornieren wollen? Sie müssen anschließend eine korrigierte Rechnung ausstellen."}
                buttonText={"Stornieren"}
                buttonColor={"btn-error"}
                additionalCode={
                    <BaseCheckbox
                        check={whenCancelBillAlsoDeleteOpenPayReqs}
                        setcheck={() => setwhenCancelBillAlsoDeleteOpenPayReqs(!whenCancelBillAlsoDeleteOpenPayReqs)}
                        label={"Bereits gestellte, offene Zahlungsanforderungen ebenfalls löschen?"}
                        classProps="mb-2"
                    />
                }
                func={handleCanceling}
            />
            <BasePreview previewType="document" sidePanelId={3} title={"Rechnungsvorschau"} content={previewElement} />
            <BasePreview
                previewType="mail"
                sidePanelId={4}
                title={"Mail Preview"}
                content={mailPreviewContent ?? ""}
                resendFunction={!data?.canceled ? () => handleResendMail(resendUri ?? "") : undefined}
                customSendSection={
                    <div className="flex flex-col h-fit max-w-[32rem]">
                        <BaseInputField
                            label={
                                "Sie haben die Möglichkeit, eine alternative E-Mail anzugeben, welche die Mail erhalten soll. Falls Das Feld leer bleibt, wird die Mail an die alten Empfänger zugestellt."
                            }
                            type={"mail"}
                            placeholder="max@muster.de"
                            onChange={setresenEmailAddress}
                        />
                    </div>
                }
            />
            <ReminderModal
                modalId={16}
                payment={payment}
                handleSetReminder={handleSetReminder}
                setMailPreviewContent={setMailPreviewContent}
                setResendUri={setResendUri}
                maxReminder={data?.maxReminders}
                intervalReminder={data?.reminderIntervalInDays}
            />
        </BasePage>
    )
}
