import React, { createContext } from "react"
import { SelfUserContextProps, SelfUserContextProviderProps } from "../types"
import { useQuery } from "@tanstack/react-query"
import { getEmployeeSelf } from "../../pages/api/apiCalls"

export const SelfUserContext = createContext<SelfUserContextProps | undefined>(undefined)

export const SelfUserContextProvider: React.FC<SelfUserContextProviderProps> = ({ children }: SelfUserContextProviderProps) => {
    const queryKeySelfEmployee = "self_employee"
    const {
        error: selfEmployeeError,
        isLoading: selfEmployeeIsLoading,
        data: selfEmployeeData,
    } = useQuery({
        queryKey: [queryKeySelfEmployee],
        queryFn: getEmployeeSelf,
        refetchOnWindowFocus: false, // Prevent refetching on window focus
        refetchOnMount: false, // Prevent refetching when the component remounts
    })

    return (
        <SelfUserContext.Provider
            value={{
                user: selfEmployeeData,
                isLoading: selfEmployeeIsLoading,
                error: selfEmployeeError,
            }}>
            {children}
        </SelfUserContext.Provider>
    )
}
