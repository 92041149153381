import { QueryClient, useMutation } from "@tanstack/react-query"
import { OrderRequestType } from "../types"
import { updateOrderRequestById } from "./apiCalls"
import { useState } from "react"
import { messageError, messagePending, messageSuccess } from "../../../services/messages"

// UPDATE ORDERREQUEST
export const useUpdateOrderRequest = (queryClient: QueryClient, queryKey: string) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, { id: string; body: OrderRequestType }>({
        mutationFn: updateOrderRequestById,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Die Anfrage wird gerade aktualisiert.")
        },
        onSuccess: () => {
            messageSuccess(notificationKey, "Fertig!", "Die Anfrage wurde erfolgreich aktualisiert.")
            queryClient.invalidateQueries({
                queryKey: [queryKey],
            })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Die Anfrage konnte nicht aktualisiert werden.")
            console.log(err)
        },
    })

    return mutation
}
