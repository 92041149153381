import { Roles } from "../../../auth/RoleMapping"
import BaseDocumentElement from "../../../components/elements/BaseDocumentElement"
import { DocumentDataType } from "../../../components/types"

type TaskDetailImgsType = {
    session_title: string
    images: DocumentDataType[]
    handleDownload: (s3Key: string, title: string) => void
}

export default function TaskDetailImgs(props: TaskDetailImgsType) {
    return (
        <div className="flex flex-col rounded-default bg-white shadow-lg p-4">
            <h3 className="text-xl mb-4 font-bold border-b">Hochgeladene Bilder</h3>
            <div className="flex flex-wrap overflow-x-hidden overflow-y-scroll max-h-64 gap-2 justify-center pb-2">
                {props.images?.length === 0 && <p className="text-xs text-gray-300 font-semibold">Keine Bilder hochgeladen</p>}
                {props.images &&
                    props.images.map((item, index) => (
                        <BaseDocumentElement
                            key={index}
                            title={props.session_title + ` (${index + 1})`}
                            timestamp={item.timestamp}
                            roles={[Roles.aufgaben_read_write]}
                            downloadFunc={() => props.handleDownload(item.s3Key, item.title)}
                        />
                    ))}
            </div>
        </div>
    )
}
