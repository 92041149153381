import { useNavigate } from "react-router-dom"
import { PaymentTableRowType } from "../../types"
import dayjs from "dayjs"
import { RenderWhenAuthorized } from "../../../../components/authentication/RenderWhenAuthorized"
import { Roles } from "../../../../auth/RoleMapping"
import { ReactComponent as EscalationIcon } from "./../../../../assets/EscalationRequiredICON.svg"
import { ReactComponent as INFO } from "./../../../../assets/InfoICON.svg"
import { ReactComponent as CHECK } from "./../../../../assets/ApprovaICON.svg"
import { Popover } from "antd"

export default function PaymentTableRow(props: PaymentTableRowType) {
    const navigate = useNavigate()
    const target = dayjs(props.delivery)
    const today = dayjs()

    return (
        <tr className={`border-b border-gray-200 cursor-pointer relative  ${props.archived && "opacity-40 bg-gray-100"}`}>
            <td onClick={() => navigate("/buchhaltung/rechnung/" + props.id)}>
                <div
                    className={`h-3 w-3 rounded-full ml-1 ${
                        props.status === "settled" ? "bg-green-500" : target < today && props.status === "unpaid" ? "bg-red-500" : "bg-yellow-400"
                    }`}></div>
            </td>
            <td onClick={() => navigate("/buchhaltung/rechnung/" + props.id)} className="hidden lg:table-cell">
                <p className="line-clamp-1 text-base text-center">{dayjs(props.delivery).format("DD.MM.YYYY")}</p>
            </td>
            <td onClick={() => navigate("/buchhaltung/rechnung/" + props.id)}>
                <p title={props.title} className="line-clamp-1 text-base text-center">
                    Rechnung {props.title}
                </p>
            </td>
            <td onClick={() => navigate("/buchhaltung/rechnung/" + props.id)}>
                <p className="line-clamp-1 text-base text-center">{props.netto?.toFixed(2)?.toString()?.replace(".", ",")} €</p>
            </td>
            <td onClick={() => navigate("/buchhaltung/rechnung/" + props.id)}>
                <p className="line-clamp-1 text-base text-center">{props.brutto?.toFixed(2)?.toString()?.replace(".", ",")} €</p>
            </td>
            <td onClick={() => navigate("/buchhaltung/rechnung/" + props.id)} className="hidden md:table-cell">
                <p title={props.customer} className="line-clamp-1 text-base text-center">
                    {props.customer}
                </p>
            </td>
            <td onClick={() => navigate("/buchhaltung/rechnung/" + props.id)} className="hidden xl:table-cell">
                <p className={`line-clamp-1 text-center `}>
                    {props.escalationRequired ? (
                        <div className="badge badge-error">Mahnungen Überfällig</div>
                    ) : props.status === "settled" ? (
                        <div className="badge badge-success">Beglichen</div>
                    ) : props.status === "canceled" ? (
                        <div className="badge badge-outline">Storniert</div>
                    ) : props.status === "unpaid" && target < today ? (
                        <div className="badge badge-error">{`${today.diff(target, "day") + 1} Tage überfällig`}</div>
                    ) : (
                        <div className="badge badge-warning">Ausstehend</div>
                    )}
                </p>
            </td>
            <RenderWhenAuthorized requiresAll={[Roles.buchhaltung_zahlungen_read_write]}>
                {!props.escalationRequired ? (
                    <td onClick={props.modalOpen}>
                        <INFO className="w-6 h-6 z-10 " />
                    </td>
                ) : (
                    <td onClick={props.openSolveEscalationModal}>
                        <EscalationIcon className="w-6 h-6 z-10 " />
                    </td>
                )}
            </RenderWhenAuthorized>
            <RenderWhenAuthorized requiresAll={[Roles.buchhaltung_zahlungen_read_write]}>
                <td>
                    {props.status !== "settled" && props.status !== "canceled" && (
                        <p title="Als bezahlt markieren">
                            {/* TODO use BaseComponent when its merged */}
                            <Popover
                                placement="bottomLeft"
                                title="Erledigt?"
                                content={
                                    <div className="flex flex-col items-end">
                                        <p>Die Zahlungsaufforderung als beglichenmarkerieren.</p>
                                        <button onClick={props.markAsPaid} className="btn btn-sm btn-primary w-fit mt-1">
                                            OK
                                        </button>
                                    </div>
                                }>
                                <CHECK className="w-6 h-6 z-10 " />
                            </Popover>
                        </p>
                    )}
                </td>
            </RenderWhenAuthorized>
        </tr>
    )
}
