import { ReactNode } from "react"

type BaseButtonProps = {
    label: string
    func: () => void
    classProps?: string
    disabled?: boolean
    type?: "button" | "submit" | "reset" 
    icon: ReactNode
}

export default function BaseButton(props: BaseButtonProps) {
    return (
        <button type={props.type} disabled={props.disabled} className={`btn bg-white shadow-lg border-none hover:bg-white/40 ${props.classProps}`} onClick={props.func}>
            {props.icon}
            {props.label}
        </button>
    )
}
