import { Form } from "antd"
import BaseModal from "../../../components/layout/BaseModal"
import BaseInputField from "../../../components/elements/BaseInputField"
import BaseInputSelection from "../../../components/elements/BaseInputSelection"
import BaseInputArea from "../../../components/elements/BaseInputArea"
import { OrderRequestType } from "../types"
import { useDelayWhenSearchWithPagination } from "../../../services/functions"
import { useEffect, useMemo, useState } from "react"
import { useQuery } from "@tanstack/react-query"
import { getPaginatedData } from "../../api/apiCalls"
import BaseSelection from "../../../components/elements/BaseSelection"

type AcceptRequestModalType = {
    modalId: number
    displayedRequest: OrderRequestType
    acceptRequest: () => void
    setacceptedOrderRequest: (e: any) => void
}

export default function AcceptRequestModal(props: AcceptRequestModalType) {
    // GET OBJECT DATA
    const [searchTermObject, setsearchTermObject] = useState<string>("")
    const searchParamsObject = useDelayWhenSearchWithPagination(searchTermObject)
    const queryKeyObject = "object" + searchParamsObject
    const {
        error: allObjectsError,
        isLoading: allObjectsIsLoading,
        data: allObjectsData,
    } = useQuery({
        queryKey: [queryKeyObject],
        queryFn: () =>
            getPaginatedData({
                pageType: "object",
                page: 1,
                limit: 20,
                search: searchParamsObject,
            }),
    })
    const displayedAllObjects = useMemo(() => allObjectsData?.docs, [allObjectsData?.docs])

    // FE LOGIC
    const [form] = Form.useForm()
    const clearValues = () => {
        form.setFieldsValue({
            object: undefined,
            description: undefined,
            price: undefined,
            estimatedDurationMinutes: undefined,
            priority: "Standard",
        })
    }
    useEffect(() => {
        props.setacceptedOrderRequest({
            description: props.displayedRequest.descriptionTaskFromCustomer,
            price: props.displayedRequest.priceForReleaseFromCustomer,
            priority: "default",
        })
        form.setFieldsValue({
            description: props.displayedRequest.descriptionTaskFromCustomer,
            price: props.displayedRequest.priceForReleaseFromCustomer,
            priority: "Standard",
        })
    }, [props.displayedRequest])

    return (
        <BaseModal modalId={props.modalId} title={"Auftrag annehmen"} size>
            <div className="flex flex-col gap-4 w-96 pt-4">
                <Form
                    form={form}
                    onFinish={() => {
                        props.acceptRequest()
                        clearValues()
                    }}>
                    <Form.Item className="mb-2" name={"object"} rules={[{ required: true, message: "Muss einen Wert enthalten!" }]}>
                        <BaseInputSelection
                            label={props.displayedRequest.objectFromCustomer}
                            placeholder={""}
                            data={displayedAllObjects}
                            onSearch={setsearchTermObject}
                            setId={(e: string) => {
                                form.setFieldsValue({ object: e })
                                props.setacceptedOrderRequest((prev: OrderRequestType) => ({
                                    ...prev,
                                    object: e,
                                }))
                            }}
                        />
                    </Form.Item>
                    <Form.Item className="mb-2" name={"description"} rules={[{ required: true, message: "Muss einen Wert enthalten!" }]}>
                        <BaseInputArea
                            label={"Beschreibung"}
                            minRows={6}
                            maxRows={12}
                            onChange={(e: string) =>
                                props.setacceptedOrderRequest((prev: OrderRequestType) => ({
                                    ...prev,
                                    descriptionTask: e,
                                }))
                            }
                        />
                    </Form.Item>
                    <Form.Item className="mb-2" name={"price"} rules={[{ required: true, message: "Muss einen Wert enthalten!" }]}>
                        <BaseInputField
                            label={"Preis"}
                            type={"number"}
                            onChange={(e: string) =>
                                props.setacceptedOrderRequest((prev: OrderRequestType) => ({
                                    ...prev,
                                    priceForRelease: Number(e),
                                }))
                            }
                        />
                    </Form.Item>
                    <Form.Item className="mb-2" name={"estimatedDurationMinutes"} rules={[{ required: true, message: "Muss einen Wert enthalten!" }]}>
                        <BaseInputField
                            label={"Geschätzte Dauer in Min."}
                            type={"number"}
                            onChange={(e: string) =>
                                props.setacceptedOrderRequest((prev: OrderRequestType) => ({
                                    ...prev,
                                    estimatedDurationMinutes: Number(e),
                                }))
                            }
                        />
                    </Form.Item>
                    <Form.Item className="mb-2" name={"priority"} rules={[{ required: true, message: "Muss einen Wert enthalten!" }]}>
                        <BaseSelection
                            label={"Besondere Priorität"}
                            placeholder={""}
                            value={"Standard"}
                            data={["Standard", "Hoch"]}
                            setId={(e: string) => {
                                form.setFieldsValue({ priority: e })
                                props.setacceptedOrderRequest((prev: OrderRequestType) => {
                                    return {
                                        ...prev,
                                        priority: e === "Hoch" ? "important" : "default",
                                    }
                                })
                            }}
                        />
                    </Form.Item>
                    <Form.Item className="flex w-full justify-end mb-0 pt-2">
                        <button className="btn btn-success" type="submit">
                            Annehmen
                        </button>
                    </Form.Item>
                </Form>
            </div>
        </BaseModal>
    )
}
