import { Form } from "antd"
import BaseModal from "../../../components/layout/BaseModal"
import BaseInputSelection from "../../../components/elements/BaseInputSelection"
import { InputObject } from "../../../components/types"

type SelectEmployeeModalType = {
    modalId: number
    employees: InputObject[]
    selectData: (e: string) => void
    setSearch: (e: string) => void
    customLoading: boolean
    onClick: () => void
}

export default function SelectEmployeeModal(props: SelectEmployeeModalType) {
    const [form] = Form.useForm()

    return (
        <BaseModal
            size
            modalId={props.modalId}
            title={"Mitarbeiter auswählen"}
            bottomRow={
                <div className="flex w-full justify-end">
                    <button className="btn btn-primary" onClick={props.onClick}>
                        Termin erstellen
                    </button>
                </div>
            }>
            <Form.Item className="mb-2" name="Select" rules={[{ required: true, message: "Bitte ausfüllen!" }]}>
                <div className={`flex justify-center w-96`}>
                    <BaseInputSelection
                        label={""}
                        placeholder={"Auswählen"}
                        data={props.employees}
                        classProps={`pt-2 w-80 pb-3`}
                        onSearch={props.setSearch}
                        setId={props.selectData}
                        customLoading={props.customLoading}
                    />
                </div>
            </Form.Item>
        </BaseModal>
    )
}
