import { useContext, useState } from "react"
import BaseModal from "../../../../components/layout/BaseModal"
import OfferModalScreen1 from "./ModalOfferScreen1"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import Loading from "../../../../components/common/Loading"
import ErrorPage from "../../../404/ErrorPage"
import { getSingleCustomerData } from "../../../Kunden/api/apiCalls"
import { useAddOfferMutation } from "../../api/services"
import { ModalContext } from "../../../../components/contexts/ModalContext"
import { OfferPost } from "../../../../data/Types"
import { useDelayWhenSearchWithPagination } from "../../../../services/functions"
import { Roles } from "../../../../auth/RoleMapping"
import { RenderWhenAuthorized } from "../../../../components/authentication/RenderWhenAuthorized"
import { getPaginatedData } from "../../../api/apiCalls"
import { Form } from "antd"
import AddBillOrOfferModalScreen2 from "./AddBillOrOfferModalScreen2"
import AddBillOrOfferModalScreen3 from "./AddBillOrOfferModalScreen3"
import AddBillOrOfferModalScreen4 from "./AddBillOrOfferModalScreen4"

type AddOfferModalType = {
    queryKey: string
}

export default function AddOfferModal(props: AddOfferModalType) {
    const [newOffer, setnewOffer] = useState<OfferPost>()

    const [searchTerm, setsearchTerm] = useState("")
    const searchParams = useDelayWhenSearchWithPagination(searchTerm)
    const queryKeyAllCustomer = "customerDataToAddBill" + searchParams
    const {
        data: customerData,
        error: errorCustomer,
        isLoading: isLoadingCustomer,
    } = useQuery({
        queryKey: [queryKeyAllCustomer],
        queryFn: () => getPaginatedData({ pageType: "customer", page: 1, limit: 10, search: searchParams, archived: false, deleted: false }),
    })

    const queryKeySingleCustomer = "singleCustomerData" + newOffer?.customer_id
    const {
        data: singleCustomer,
        error: errorSingleCustomer,
        isLoading: isLoadingSingleCustomer,
    } = useQuery({
        queryKey: [queryKeySingleCustomer],
        queryFn: () => getSingleCustomerData(newOffer?.customer_id ?? ""),
        enabled: !!newOffer?.customer_id,
    })
    const [sendLV, setsendLV] = useState(false)
    const [documents, setdocuments] = useState<any[]>()
    const queryClient = useQueryClient()
    const modalCon = useContext(ModalContext)
    const { mutate: addOffer } = useAddOfferMutation(queryClient, modalCon, props.queryKey)
    const handleNewOffer = () => {
        const formData = new FormData()
        formData.append("offer", JSON.stringify(newOffer))
        documents?.forEach((doc: any) => {
            if (doc) {
                formData.append("additionalDocuments", doc)
            }
        })
        addOffer({ body: formData, leistungsVerzeichnis: sendLV })
        clearFormAndModal()
    }

    // FE LOGIC
    const [screen, setscreen] = useState<number>(1)
    const [form] = Form.useForm()
    const clearFormAndModal = () => {
        form.resetFields()
        setsendLV(false)
        setscreen(1)
        modalCon?.trigger(0)
    }
    const handleNextModalScreen = async (screen: number, form: any) => {
        try {
            await form.validateFields()
            setscreen(screen)
        } catch {
            console.log("Correction")
        }
    }

    return (
        <RenderWhenAuthorized requiresAll={[Roles.buchhaltung_read_write]}>
            <BaseModal
                title={"Neues Angebot erstellen"}
                modalId={1}
                bottomRow={
                    <div className="flex justify-between items-center">
                        <button
                            disabled={screen === 1}
                            className={`underline text-sm ${screen === 1 && "cursor-not-allowed text-gray-300"}`}
                            onClick={() => setscreen(screen - 1)}>
                            zurück
                        </button>
                        {screen === 4 ? (
                            <button className={`btn btn-primary`} onClick={handleNewOffer}>
                                Abschicken
                            </button>
                        ) : screen === 3 ? (
                            <button className="btn btn-primary btn-outline" onClick={() => handleNextModalScreen(4, form)}>
                                Weiter
                            </button>
                        ) : screen === 2 ? (
                            <button className="btn btn-primary btn-outline" onClick={() => handleNextModalScreen(3, form)}>
                                Weiter
                            </button>
                        ) : (
                            <button className="btn btn-primary btn-outline" onClick={() => handleNextModalScreen(2, form)}>
                                Weiter
                            </button>
                        )}
                    </div>
                }>
                <div className="overflow-hidden relative h-full w-full">
                    {isLoadingSingleCustomer && <Loading />}
                    {errorSingleCustomer && <ErrorPage />}
                    {!errorSingleCustomer && (
                        <>
                            <OfferModalScreen1
                                visible={true}
                                form={form}
                                doesLValreadyExist={
                                    singleCustomer?.objects?.find((item: { _id: string | undefined }) => item._id === newOffer?.object_id)
                                        ?.listOfServices
                                }
                                setsendLV={() => setsendLV(!sendLV)}
                                newOffer={newOffer as OfferPost}
                                setnewOffer={setnewOffer}
                                objectArray={singleCustomer?.objects}
                                customerArray={customerData?.docs}
                                searchTerm={searchTerm}
                                setsearchTerm={setsearchTerm}
                                isLoadingCustomer={isLoadingCustomer}
                                errorCustomer={errorCustomer}
                            />
                            <AddBillOrOfferModalScreen2
                                form={form}
                                visible={screen >= 2}
                                newBillOrOffer={newOffer as OfferPost}
                                setnewBillOrOffer={setnewOffer}
                            />
                            <AddBillOrOfferModalScreen3 form={form} visible={screen >= 3} documents={documents ?? []} setdocuments={setdocuments} />
                            <AddBillOrOfferModalScreen4 visible={screen >= 4} newBillOrOffer={newOffer as OfferPost} customer={singleCustomer} />
                        </>
                    )}
                </div>
            </BaseModal>
        </RenderWhenAuthorized>
    )
}
