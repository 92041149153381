import { useContext } from "react"
import { ModalContext } from "../../../components/contexts/ModalContext"
import { Employee } from "../types/Types"
import EmployeeCard from "./EmployeeCard"
import { useNavigate } from "react-router-dom"
import { Pagination } from "antd"
import ErrorPage from "../../404/ErrorPage"
import Loading from "../../../components/common/Loading"
import BaseGrowBody from "../../../components/elements/BaseGrowBody"

type EmployeeBodyType = {
    error: Error | null
    isLoading: boolean
    displayedEmployee: Employee[]
    page: number
    handlePageChange: () => void
    totalDocs: number
    removeInput: () => void
    setselectedEmplyoeeId: (e: string) => void
}

export default function EmployeeBody(props: EmployeeBodyType) {
    const modalCon = useContext(ModalContext)
    const navigate = useNavigate()
    return (
        <>
            {/* Body with Cards */}
            {!props.displayedEmployee || props.displayedEmployee.length === 0 ? (
                <div className="flex flex-col justify-center items-center w-full">
                    <p className="text-gray-300 uppercase font-medium pt-4">Keine Mitarbeiter gefunden</p>
                    <button className="btn btn-ghost mt-2 btn-outline max-w-xs" onClick={props.removeInput}>
                        Eingabe zurücksetzen
                    </button>
                </div>
            ) : (
                <BaseGrowBody classProps=" pb-8 overflow-y-scroll grid md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4 grid-rows-subgrid pt-4">
                    {props.error && <ErrorPage />}
                    {props.isLoading && <Loading />}
                    {!props.error &&
                        !props.isLoading &&
                        props.displayedEmployee &&
                        props.displayedEmployee?.map((item: Employee, index: number) => (
                            <EmployeeCard
                                setEmployee={() => navigate("/mitarbeiter/" + item._id)}
                                key={index}
                                item={item}
                                navigateToCalendar={() => navigate("/kalender/" + item._id)}
                                archiveFunc={() => {
                                    props.setselectedEmplyoeeId(item._id ?? "")
                                    item.archived ? modalCon?.trigger(5) : modalCon?.trigger(3)
                                }}
                                deleteFunc={() => {
                                    props.setselectedEmplyoeeId(item._id ?? "")
                                    modalCon?.trigger(4)
                                }}
                            />
                        ))}
                </BaseGrowBody>
            )}
            <div className="flex justify-center w-full p-4">
                <Pagination
                    defaultCurrent={1}
                    current={props.page}
                    pageSize={20}
                    onChange={props.handlePageChange}
                    total={props.totalDocs}
                    showSizeChanger={false}
                />
            </div>
        </>
    )
}
