/**
 * @description Enum for Roles where each role value has the same name as the role in the keycloak server.
 */
export enum Roles {
    // Dashboard
    dashboard_read = "rbac_dashboard_read",

    // Mitarbeiter (Employee)
    mitarbeiter_read = "rbac_mitarbeiter_read",
    mitarbeiter_read_ou = "rbac_mitarbeiter_read_ou",
    mitarbeiter_read_write = "rbac_mitarbeiter_read_write",
    mitarbeiter_read_write_ou = "rbac_mitarbeiter_read_write_ou",
    mitarbeiter_delete = "rbac_mitarbeiter_delete",
    mitarbeiter_create = "rbac_mitarbeiter_create",
    mitarbeiter_manage_permissions = "rbac_mitarbeiter_manage_permissions",

    // Buchhaltung (Accounting)
    buchhaltung_read = "rbac_buchhaltung_read",
    buchhaltung_read_write = "rbac_buchhaltung_read_write",
    buchhaltung_zahlungen_read = "rbac_buchhaltung_zahlungen_read",
    buchhaltung_zahlungen_read_write = "rbac_buchhaltung_zahlungen_read_write",

    // Objekte (Objects)
    objekte_read = "rbac_objekte_read",
    objekte_read_write = "rbac_objekte_read_write",
    objekte_delete = "rbac_objekte_delete",
    objekte_create = "rbac_objekte_create",
    objekte_qr_code_generate = "rbac_objekte_qr_code_generate",

    // Kunden (Customers)
    kunden_read = "rbac_kunden_read",
    kunden_read_write = "rbac_kunden_read_write",
    kunden_delete = "rbac_kunden_delete",
    kunden_create = "rbac_kunden_create",

    // Tasks
    aufgaben_read = "rbac_task_read",
    aufgaben_read_write = "rbac_task_read_write",
    aufgaben_create = "rbac_task_create",
    aufgaben_delete = "rbac_task_delete",

    // Task Permissions
    aufgaben_permissions_self = "rbac_taskPermission_self",    
    aufgaben_permissions_costcenter = "rbac_taskPermission_costcenter",    
    aufgaben_permissions_all = "rbac_taskPermission_all",
    
    // Session
    termine_checkin = "rbac_session_check_in_out",
    termine_manual_checkin = "rbac_session_manual_check_in_out",
    termine_execute = "rbac_session_execute",
    termine_management = "rbac_session_management",

    // CostCenter (Niederlassungen)
    niederlassungen_read = "rbac_costcenter_read",
    niederlassungen_read_write = "rbac_costcenter_read_write",
    niederlassungen_delete = "rbac_costcenter_delete",
    niederlassungen_create = "rbac_costcenter_create",

    // Help
    help_read_write = "rbac_help_read_write",

    // Zip
    rbac_export_all_files="rbac_export_all_files"
}
