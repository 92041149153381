import BaseInputArea from "../../../components/elements/BaseInputArea"
import BaseInputField from "../../../components/elements/BaseInputField"
import { ReactComponent as EDIT } from "../../../assets/EditICON.svg"
import { ReactComponent as CLOSE } from "../../../assets/CloseICON.svg"
import { getSingleTaskTemplateType, getSingleTaskType } from "../types"
import { Form } from "antd"
import { useEffect, useState } from "react"
import BaseSelection from "../../../components/elements/BaseSelection"
import dayjs from "dayjs"
import { returnAddressString } from "../../../services/functions"
import { ObjectType } from "../../Objekte/types/types"

type TaskDetailPlainInformationType = {
    displayedTask?: getSingleTaskType
    displayedTaskTemplate?: getSingleTaskTemplateType
    selectedObjectAddress?: string
    resetDataToOriginal: () => void
    editTaskData: any // TODO -> integrate one general function
    updateTask: () => void
}

export default function TaskDetailPlainInformation(props: TaskDetailPlainInformationType) {
    const [form] = Form.useForm()
    useEffect(() => {
        form.setFieldsValue(
            props.displayedTaskTemplate
                ? {
                      activity: props.displayedTaskTemplate.activity,
                      prio: props.displayedTaskTemplate.priority === "default" ? "Standard" : "Hoch",
                      estimatedDurationMinutes: props.displayedTaskTemplate.estimatedDurationMinutes,
                      showDaysBeforeDueDate: props.displayedTaskTemplate.recurrenceCreationPattern.showDaysBeforeDueDate,
                      state:
                          props.displayedTaskTemplate.state === "active"
                              ? "Aktiv"
                              : props.displayedTaskTemplate.state === "paused"
                              ? "Pausiert"
                              : "Fertig",
                      interval: props.displayedTaskTemplate.recurrenceCreationPattern.interval,
                      recurrenceType:
                          props.displayedTaskTemplate.recurrenceCreationPattern.recurrenceType === "week"
                              ? "Woche"
                              : props.displayedTaskTemplate.recurrenceCreationPattern.recurrenceType === "month"
                              ? "Monat"
                              : "Jahr",
                      endByDate: props.displayedTaskTemplate.recurrenceCreationPattern.endByDate
                          ? dayjs(props.displayedTaskTemplate.recurrenceCreationPattern.endByDate).format("YYYY-MM-DD")
                          : undefined,
                  }
                : props.displayedTask
                ? {
                      activity: props.displayedTask.activity,
                      state:
                          props.displayedTask.state === "created"
                              ? "Erstellt"
                              : props.displayedTask.state === "execution-failed"
                              ? "Zurückgewiesen"
                              : props.displayedTask.state === "waiting-for-customer-approval"
                              ? "Wartet auf Kunden"
                              : props.displayedTask.state === "assigned"
                              ? "Zugewiesen"
                              : props.displayedTask.state === "unassigned"
                              ? "Zuweisung entfernt"
                              : "Erneut Zugewiesen",
                      prio: props.displayedTask.priority === "default" ? "Standard" : "Hoch",
                      dueAt: dayjs(props.displayedTask.dueAt).format("YYYY-MM-DD"),
                      estimatedDurationMinutes: props.displayedTask.estimatedDurationMinutes,
                  }
                : {}
        )
    }, [form, props.displayedTask, props.displayedTaskTemplate])
    const [editable, seteditable] = useState<boolean>(false)
    return (
        <section className="flex flex-col p-4 bg-white rounded-default shadow-lg w-full grow overflow-x-hidden overflow-y-scroll">
            <Form
                form={form}
                onFinish={() => {
                    props.updateTask()
                    seteditable(false)
                }}>
                <div className="flex justify-end w-full items-center gap-4">
                    {editable ? (
                        <>
                            <p title="Abbrechen">
                                <CLOSE
                                    onClick={() => {
                                        seteditable(false)
                                        props.resetDataToOriginal()
                                    }}
                                    className="w-6 h-6 cursor-pointer"
                                />
                            </p>
                            <Form.Item className="m-0">
                                <button disabled={!editable} type="submit" className="btn btn-success btn-sm">
                                    Speichern
                                </button>
                            </Form.Item>
                        </>
                    ) : (
                        <p title="Bearbeiten">
                            <EDIT className="w-6 h-6 cursor-pointer" onClick={() => seteditable(!editable)} />
                        </p>
                    )}
                </div>
                <Form.Item className="mb-2" name="activity" rules={[{ required: true, message: "Muss einen Wert enthalten" }]}>
                    <BaseInputField
                        variant="filled"
                        label={"Titel"}
                        type={"text"}
                        disabled={!editable}
                        onChange={(e) =>
                            props.editTaskData((prev: getSingleTaskTemplateType) => ({
                                ...prev,
                                activity: e,
                            }))
                        }
                    />
                </Form.Item>
                <BaseInputArea
                    label={"Beschreibung (optional)"}
                    value={props.displayedTask?.description ?? props.displayedTaskTemplate?.description}
                    variant="filled"
                    classProps="mb-2"
                    disabled={!editable}
                    onChange={(e) =>
                        props.editTaskData((prev: getSingleTaskTemplateType) => ({
                            ...prev,
                            description: e,
                        }))
                    }
                />
                <BaseInputField
                    variant="filled"
                    label={"Objekt"}
                    type={"text"}
                    value={
                        (props.displayedTaskTemplate?.object && returnAddressString(props.displayedTaskTemplate?.object as ObjectType)) ??
                        (props.displayedTask?.object && returnAddressString(props.displayedTask?.object as ObjectType))
                    }
                    disabled
                    classProps="mb-2"
                    onChange={function (e: string): void {
                        throw new Error("Function not implemented.")
                    }}
                />
                <BaseInputField
                    variant="filled"
                    label={"Kostenstelle / Niederlassung"}
                    type={"text"}
                    value={props.displayedTask?.costCenter?.name ?? props.displayedTaskTemplate?.costCenter?.name}
                    disabled
                    classProps="mb-2"
                    onChange={function (e: string): void {
                        throw new Error("Function not implemented.")
                    }}
                />
                <Form.Item className="mb-2" name="prio" rules={[{ required: true, message: "Muss einen Wert enthalten" }]}>
                    <BaseSelection
                        disabled={!editable}
                        label={"Priorität"}
                        placeholder={"Prio"}
                        variant="filled"
                        data={["Standard", "Hoch"]}
                        classProps="mb-2"
                        setId={(e) =>
                            props.editTaskData((prev: getSingleTaskTemplateType) => ({
                                ...prev,
                                priority: e === "Hoch" ? "important" : "default",
                            }))
                        }
                    />
                </Form.Item>
                <Form.Item className="mb-2" name="state" rules={[{ required: true, message: "Muss einen Wert enthalten" }]}>
                    <BaseSelection
                        disabled={!editable}
                        label={"Status"}
                        variant="filled"
                        placeholder={"Status auswählen"}
                        data={props.displayedTask ? ["Erstellt", "Wartet auf Kunden"] : ["Aktiv", "Pausiert", "Fertig"]}
                        classProps="mb-2"
                        setId={(e) =>
                            props.editTaskData((prev: getSingleTaskTemplateType) => ({
                                ...prev,
                                state:
                                    e === "Erstellt"
                                        ? "created"
                                        : e === "Wartet auf Kunden"
                                        ? "waiting-for-customer-approval"
                                        : e === "Aktiv"
                                        ? "active"
                                        : e === "Pausiert"
                                        ? "paused"
                                        : "completed",
                            }))
                        }
                    />
                </Form.Item>

                {props.displayedTask && props.displayedTask.dueAt && (
                    <Form.Item className="mb-2" name="dueAt" rules={[{ required: true, message: "Muss einen Wert enthalten" }]}>
                        <BaseInputField
                            variant="filled"
                            label={"Fällig am"}
                            type={"Date"}
                            disabled={!editable}
                            onChange={(e) =>
                                props.editTaskData((prev: getSingleTaskTemplateType) => ({
                                    ...prev,
                                    dueAt: e,
                                }))
                            }
                        />
                    </Form.Item>
                )}
                <Form.Item className="mb-2" name="estimatedDurationMinutes" rules={[{ required: true, message: "Muss einen Wert enthalten" }]}>
                    <BaseInputField
                        variant="filled"
                        label={"Geschätzte Dauer in min"}
                        disabled={!editable}
                        type={"Number"}
                        onChange={(e) =>
                            props.editTaskData((prev: getSingleTaskTemplateType) => ({
                                ...prev,
                                estimatedDurationMinutes: e,
                            }))
                        }
                    />
                </Form.Item>
                {props.displayedTaskTemplate && (
                    <>
                        <label className="pl-2 pt-1">Ausführungsinterval</label>
                        <div className="flex gap-2 w-full pb-3 pl-2">
                            <p className="pt-5 font-semibold">Ausführung</p>
                            <Form.Item className="mb-2" name="interval" rules={[{ required: true, message: "Muss einen Wert enthalten" }]}>
                                <BaseSelection
                                    label={""}
                                    disabled={!editable}
                                    placeholder={"X"}
                                    classProps="!w-32"
                                    data={
                                        props.displayedTaskTemplate.recurrenceCreationPattern.recurrenceType === "week"
                                            ? ["1", "2", "3", "4"]
                                            : props.displayedTaskTemplate?.recurrenceCreationPattern?.recurrenceType === "month"
                                            ? ["1", "2", "3", "4", "5", "6", "7", "8"]
                                            : ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"]
                                    }
                                    setId={(e: string) => {
                                        props.editTaskData((prev: getSingleTaskTemplateType) => ({
                                            ...prev,
                                            recurrenceCreationPattern: {
                                                ...prev.recurrenceCreationPattern,
                                                interval: Number(e),
                                            },
                                        }))
                                    }}
                                />
                            </Form.Item>
                            <p className="pt-5 font-semibold">Mal</p>
                            <p className="pt-5 font-semibold">pro</p>
                            <Form.Item
                                className="mb-2 w-full"
                                name="recurrenceType"
                                rules={[{ required: true, message: "Muss einen Wert enthalten" }]}>
                                <BaseSelection
                                    label={""}
                                    placeholder={"Einheit"}
                                    disabled={!editable}
                                    data={["Woche", "Monat", "Jahr"]}
                                    setId={(e: string) => {
                                        props.editTaskData((prev: getSingleTaskTemplateType) => ({
                                            ...prev,
                                            recurrenceCreationPattern: {
                                                ...prev.recurrenceCreationPattern,
                                                recurrenceType: e === "Woche" ? "week" : e === "Monat" ? "month" : "year",
                                            },
                                        }))
                                    }}
                                />
                            </Form.Item>
                        </div>
                        <Form.Item className="mb-2" name="showDaysBeforeDueDate" rules={[{ required: true, message: "Muss einen Wert enthalten" }]}>
                            <BaseInputField
                                variant="filled"
                                label={"Wie viele Tage vor Fälligkeit wird die Aufgabe erscheinen"}
                                disabled={!editable}
                                type={"Number"}
                                onChange={(e) =>
                                    props.editTaskData((prev: getSingleTaskTemplateType) => ({
                                        ...prev,
                                        recurrenceCreationPattern: {
                                            ...prev.recurrenceCreationPattern,
                                            showDayBeforeDueDate: e,
                                        },
                                    }))
                                }
                            />
                        </Form.Item>
                        <Form.Item className="mb-2" name="endByDate" rules={[{ required: false, message: "Muss einen Wert enthalten" }]}>
                            <BaseInputField
                                label={"Auslaufdatum"}
                                type={"Date"}
                                disabled={!editable}
                                min={dayjs().add(14, "days").format("YYYY-MM-DD")}
                                onChange={(e: string) => {
                                    props.editTaskData((prev: any) => {
                                        return {
                                            ...prev,
                                            recurrenceCreationPattern: {
                                                ...prev.recurrenceCreationPattern,
                                                endByDate: e,
                                            },
                                        }
                                    })
                                }}
                            />
                        </Form.Item>
                    </>
                )}
            </Form>
        </section>
    )
}
