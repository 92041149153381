import { useNavigate } from "react-router-dom"
import { BillTableRowType } from "../../types"
import dayjs from "dayjs"
import { ReactComponent as WARNING } from "../../../../assets/WarningICON.svg"

export default function BillTableRow({ id, title, customer, object, date, delivery, amount, type, archived, canceled }: BillTableRowType) {
    const navigate = useNavigate()

    const calculateTotal = (positions: any[]): string => {
        return positions
            ?.map((position) => position.amount * position.singlePrice * (1 + 0.01 * position.tax))
            .reduce((acc, value) => acc + value, 0)
            .toFixed(2)
            .toString()
            .replace(".", ",")
    }

    return (
        <tr
            onClick={() => navigate("/buchhaltung/rechnung/" + id)}
            className={`border-b border-gray-200 cursor-pointer ${archived && "opacity-40 bg-gray-100"} ${canceled && "text-black/30"}`}>
            <td className="hidden lg:table-cell">
                <p className="line-clamp-1 text-base text-center">
                    {canceled ? <p className="uppercase font-semibold">Storniert</p> : dayjs(date).format("DD.MM.YYYY")}
                </p>
            </td>
            <td>
                <p title={title} className="line-clamp-1 text-base text-center">
                    Rechnung {title}
                </p>
            </td>
            <td className="hidden lg:table-cell">
                <p className="line-clamp-1 text-base text-center">{type}</p>
            </td>
            <td>
                <p className="line-clamp-1 text-base text-center">{calculateTotal(amount)} €</p>
            </td>
            <td className="hidden md:table-cell">
                <p title={customer.name} className="line-clamp-1 text-base flex justify-center">
                    {customer.softDeleteAfter && (
                        <span className="pr-2">
                            <WARNING className="w-6 h-6 text-red-700" />
                        </span>
                    )}
                    {customer.name}
                </p>
            </td>
            <td className="hidden md:table-cell">
                <p
                    title={object?.adress?.street + " " + object?.adress?.nr + ", " + object?.adress?.plz + " " + object?.adress?.city}
                    className="line-clamp-1 text-base flex justify-center">
                    {object.softDeleteAfter && (
                        <span className="pr-2">
                            <WARNING className="w-6 h-6 text-red-700" />
                        </span>
                    )}
                    {object?.adress
                        ? object?.adress?.street + " " + object?.adress?.nr + ", " + object?.adress?.plz + " " + object?.adress?.city
                        : "keine Angabe"}
                </p>
            </td>
        </tr>
    )
}
