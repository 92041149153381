import { useEffect, useState } from "react"
import BaseButtonRow from "../../../components/elements/BaseButtonRow"
import BaseGrowBody from "../../../components/elements/BaseGrowBody"
import BaseHeadBanner from "../../../components/elements/BaseHeadBanner"
import BasePage from "../../../components/layout/BasePage"
import TaskDetailPlainInformation from "../components/TaskDetailPlainInformation"
import { getSingleTaskTemplateType } from "../types"
import { getSingleTaskTemplate } from "../api/apiCalls"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { useParams } from "react-router-dom"
import { useUpdateTaskTemplateMutation, useUpdateTaskTemplateStateMutation } from "../api/services"
import BaseButton from "../../../components/elements/BaseButton"
import { ReactComponent as PLAY } from "../../../assets/Play.svg"
import { ReactComponent as PAUSE } from "../../../assets/Pause.svg"
import { ReactComponent as RELOAD } from "../../../assets/RefreshICON.svg"
import ErrorPage from "../../404/ErrorPage"
import Loading from "../../../components/common/Loading"

export default function TaskTemplateDetailPage() {
    const { id } = useParams()
    // TASK get single
    const queryKeySingleTaskTemplate = "taskTemplate" + id
    const {
        error: singleTaskTemplateError,
        isLoading: singleTaskTemplateIsLoading,
        data: singleTaskTemplateData,
    } = useQuery({
        queryKey: [queryKeySingleTaskTemplate],
        queryFn: () => getSingleTaskTemplate(id ?? ""),
        enabled: !!id,
    })
    const [displayedTaskTemplate, setdisplayedTaskTemplate] = useState<getSingleTaskTemplateType>()
    useEffect(() => setdisplayedTaskTemplate(singleTaskTemplateData), [singleTaskTemplateData])

    // RESET task template data
    const handleResetTaskTemplateData = () => {
        setdisplayedTaskTemplate(singleTaskTemplateData)
    }

    // UPDATE
    const queryClient = useQueryClient()
    const { mutate: updateTaskTemplate } = useUpdateTaskTemplateMutation(queryClient, queryKeySingleTaskTemplate)
    const handleUpdateTaskTemplate = () => {
        updateTaskTemplate({ id: id ?? "", body: displayedTaskTemplate as getSingleTaskTemplateType })
    }

    // UPDATE Status
    const { mutate: updateTaskTemplateState } = useUpdateTaskTemplateStateMutation(queryClient, queryKeySingleTaskTemplate)

    return (
        <>
            {singleTaskTemplateError && <ErrorPage />}
            {singleTaskTemplateIsLoading && <Loading />}
            {singleTaskTemplateData && (
                <BasePage>
                    <BaseHeadBanner title={"Wiederkehrende Aufgabe"} />
                    <BaseButtonRow>
                        <p
                            title={
                                "Aufgabe" + displayedTaskTemplate?.state === "active"
                                    ? "pausieren"
                                    : displayedTaskTemplate?.state === "paused"
                                    ? "aktivieren"
                                    : "erneut starten"
                            }>
                            <BaseButton
                                label={""}
                                func={() => updateTaskTemplateState(displayedTaskTemplate?._id ?? "")}
                                icon={
                                    displayedTaskTemplate?.state === "paused" ? (
                                        <PLAY className="w-6 h-6" />
                                    ) : displayedTaskTemplate?.state === "active" ? (
                                        <PAUSE className="w-6 h-6" />
                                    ) : (
                                        <RELOAD className="w-6 h-6" />
                                    )
                                }
                            />
                        </p>
                    </BaseButtonRow>
                    <BaseGrowBody>
                        <TaskDetailPlainInformation
                            displayedTaskTemplate={displayedTaskTemplate}
                            resetDataToOriginal={handleResetTaskTemplateData}
                            editTaskData={setdisplayedTaskTemplate}
                            updateTask={handleUpdateTaskTemplate}
                        />
                    </BaseGrowBody>
                </BasePage>
            )}
        </>
    )
}
