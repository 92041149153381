import { ReactNode } from "react"

type BaseButtonRowType = {
    children: ReactNode
    justifyEnd?: boolean
}

export default function BaseButtonRow(props: BaseButtonRowType) {
    return <div className={`h-14 w-full flex items-center my-2 gap-2 ${props.justifyEnd ? "justify-end" : "justify-between"}`}>{props.children}</div>
}
