import { useContext, useState } from "react"
import BaseModal from "../../../../components/layout/BaseModal"
import BillModalScreen1 from "./AddBillModalScreen1"
import AddBillOrOfferModalScreen2 from "./AddBillOrOfferModalScreen2"
import AddBillOrOfferModalScreen3 from "./AddBillOrOfferModalScreen3"
import AddBillOrOfferModalScreen4 from "./AddBillOrOfferModalScreen4"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import Loading from "../../../../components/common/Loading"
import ErrorPage from "../../../404/ErrorPage"
import { getSingleCustomerData } from "../../../Kunden/api/apiCalls"
import { ModalContext } from "../../../../components/contexts/ModalContext"
import { BillPost } from "../../../../data/Types"
import { useAddBillMutation } from "../../api/services"
import { useDelayWhenSearchWithPagination } from "../../../../services/functions"
import { Roles } from "../../../../auth/RoleMapping"
import { RenderWhenAuthorized } from "../../../../components/authentication/RenderWhenAuthorized"
import { getPaginatedData } from "../../../api/apiCalls"
import { Form } from "antd"

type AddBillModalType = {
    queryKey: string
}

export default function AddBillModal(props: AddBillModalType) {
    const [newBill, setnewBill] = useState<BillPost>()

    // GET all customer for search
    const [searchTerm, setsearchTerm] = useState("")
    const searchParams = useDelayWhenSearchWithPagination(searchTerm)
    const queryKeyAllCustomer = "customerDataToAddBill" + searchParams
    const {
        data: customerData,
        error: errorCustomer,
        isLoading: isLoadingCustomer,
    } = useQuery({
        queryKey: [queryKeyAllCustomer],
        queryFn: () => getPaginatedData({ pageType: "customer", page: 1, limit: 10, search: searchParams, archived: false, deleted: false }),
    })

    // GET single customer
    const queryKeySingleCustomer = "singleCustomerData" + newBill?.customer_id
    const {
        data: singleCustomer,
        error: errorSingleCustomer,
        isLoading: isLoadingSingleCustomer,
    } = useQuery({
        queryKey: [queryKeySingleCustomer],
        queryFn: () => getSingleCustomerData(newBill?.customer_id ?? ""),
        enabled: !!newBill?.customer_id,
    })

    const [documents, setdocuments] = useState<
        {
            title: ""
            fileUpload: File
            uploadedByUser: true
        }[]
    >()

    // all about creating the new bill
    const queryClient = useQueryClient()
    const modalCon = useContext(ModalContext)
    const { mutate: addBill } = useAddBillMutation(queryClient, props.queryKey)
    const handleNewBill = () => {
        const formData = new FormData()
        formData.append("bill", JSON.stringify(newBill))
        documents?.forEach((doc: any) => {
            if (doc) {
                formData.append("additionalDocuments", doc)
            }
        })
        addBill(formData)
        clearFormAndModal()
    }

    // FE LOGIC
    const [screen, setscreen] = useState<number>(1)
    const [form] = Form.useForm()
    const clearFormAndModal = () => {
        form.resetFields()
        setscreen(1)
        modalCon?.trigger(0)
    }
    const handleNextModalScreen = async (screen: number, form: any) => {
        try {
            await form.validateFields()
            setscreen(screen)
        } catch {
            console.log("Correction")
        }
    }

    return (
        <RenderWhenAuthorized requiresAll={[Roles.buchhaltung_read_write]}>
            <BaseModal
                title={"Neue Rechnung erstellen"}
                modalId={1}
                bottomRow={
                    <div className="flex justify-between items-center">
                        <button
                            disabled={screen === 1}
                            className={`underline text-sm ${screen === 1 && "cursor-not-allowed text-gray-300"}`}
                            onClick={() => setscreen(screen - 1)}>
                            zurück
                        </button>
                        {screen === 4 ? (
                            <button className={`btn btn-primary`} onClick={handleNewBill}>
                                Abschicken
                            </button>
                        ) : screen === 3 ? (
                            <button className="btn btn-primary btn-outline" onClick={() => handleNextModalScreen(4, form)}>
                                Weiter
                            </button>
                        ) : screen === 2 ? (
                            <button className="btn btn-primary btn-outline" onClick={() => handleNextModalScreen(3, form)}>
                                Weiter
                            </button>
                        ) : (
                            <button className="btn btn-primary btn-outline" onClick={() => handleNextModalScreen(2, form)}>
                                Weiter
                            </button>
                        )}
                    </div>
                }>
                <div className="overflow-hidden relative h-full w-full">
                    {isLoadingSingleCustomer && <Loading />}
                    {errorSingleCustomer && <ErrorPage />}
                    {!errorSingleCustomer && (
                        <>
                            <BillModalScreen1
                                visible={screen >= 1}
                                form={form}
                                newBill={newBill as BillPost}
                                setnewBill={setnewBill}
                                objectArray={singleCustomer?.objects}
                                customerArray={customerData?.docs}
                                searchTerm={searchTerm}
                                setsearchTerm={setsearchTerm}
                                isLoadingCustomer={isLoadingCustomer}
                                errorCustomer={errorCustomer}
                            />
                            <AddBillOrOfferModalScreen2
                                form={form}
                                visible={screen >= 2}
                                newBillOrOffer={newBill as BillPost}
                                setnewBillOrOffer={setnewBill}
                            />
                            <AddBillOrOfferModalScreen3 form={form} visible={screen >= 3} documents={documents ?? []} setdocuments={setdocuments} />
                            <AddBillOrOfferModalScreen4 visible={screen >= 4} newBillOrOffer={newBill as BillPost} customer={singleCustomer} />
                        </>
                    )}
                </div>
            </BaseModal>
        </RenderWhenAuthorized>
    )
}
