import { preventKeyDown } from "../../services/functions"
import { ReactComponent as SEARCH } from "../../assets/LupeICON.svg"

type BaseInputSearchType = {
    placeholder: string
    searchTerm: string
    classProps?: string
    hidden?: string
    setsearchTerm: (e: string) => void
}

export default function BaseInputSearch(props: BaseInputSearchType) {
    return (
        <label className={`flex bg-white rounded-default shadow-lg items-center px-2 ${props.hidden}`}>
            <SEARCH className="w-4 h-4 ml-2" />
            <input
                onKeyDown={preventKeyDown}
                onChange={(e) => props.setsearchTerm(e.target.value)}
                type="text"
                value={props.searchTerm ?? null}
                placeholder={props.placeholder}
                className={`w-80 rounded-default outline-none bg-none pl-2 pr-4 py-4 text-base-200 ${props.classProps}`}
            />
        </label>
    )
}
