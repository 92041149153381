import { Form } from "antd"
import BaseCheckbox from "../../../components/elements/BaseCheckbox"
import BaseInputArea from "../../../components/elements/BaseInputArea"
import BaseInputField from "../../../components/elements/BaseInputField"
import BaseInputSelection from "../../../components/elements/BaseInputSelection"
import BaseModal from "../../../components/layout/BaseModal"
import { InputObject } from "../../../components/types"
import { addTaskDataType, addTaskTemplateDataType } from "../types"
import { Dispatch, SetStateAction, useEffect } from "react"
import BaseSelection from "../../../components/elements/BaseSelection"
import dayjs from "dayjs"

type ChooseEmployeeOrObjectModalType = {
    modalId: number
    customLoading: boolean
    objects: InputObject[]
    setTaskData?: Dispatch<SetStateAction<addTaskDataType | undefined>>
    setTaskTemplateData?: Dispatch<SetStateAction<addTaskTemplateDataType>>
    buttonLabel: string
    selectObject: (e: string) => void
    setSearch: (e: string) => void
    onClick: () => void
    taskData?: addTaskDataType
    taskTemplateData?: addTaskTemplateDataType
}

export default function AddTaskModal(props: ChooseEmployeeOrObjectModalType) {
    const [form] = Form.useForm()
    const clearValues = () =>
        form.setFieldsValue({
            object: undefined,
            activity: undefined,
            description: undefined,
            estimatedDurationMinutes: undefined,
            priority: undefined,
            dueAt: undefined,
        })

    useEffect(() => {
        clearValues()
    }, [])
    return (
        <Form
            form={form}
            variant={"outlined"}
            onFinish={() => {
                props.onClick()
                clearValues()
            }}>
            <BaseModal
                modalId={props.modalId}
                title={"Aufgabe erstellen"}
                bottomRow={
                    <div className={`flex justify-end w-full`}>
                        <Form.Item>
                            <button className="btn btn-primary" type="submit">
                                {props.buttonLabel}
                            </button>
                        </Form.Item>
                    </div>
                }>
                <Form.Item className="mb-2" name="object" rules={[{ required: true, message: "Bitte ausfüllen!" }]}>
                    <BaseInputSelection
                        label={"Objekt auswählen"}
                        placeholder={"Auswählen"}
                        data={props.objects}
                        classProps={`pt-2`}
                        onSearch={props.setSearch}
                        setId={(e) => {
                            form.setFieldsValue({ object: e })
                            props.selectObject(e)
                        }}
                        customLoading={props.customLoading}
                    />
                </Form.Item>
                <Form.Item className="mb-2" name="activity" rules={[{ required: true, message: "Bitte ausfüllen!" }]}>
                    <BaseInputField
                        label={"Aufgabe"}
                        type={"text"}
                        placeholder="Kehren, Schnee schieben, etc..."
                        onChange={(e: string) => {
                            if (props.setTaskData) {
                                props.setTaskData((prev: any) => {
                                    return {
                                        ...prev,
                                        activity: e,
                                    }
                                })
                            }
                            if (props.setTaskTemplateData) {
                                props.setTaskTemplateData((prev: addTaskTemplateDataType) => ({
                                    ...prev,
                                    activity: e,
                                }))
                            }
                        }}
                    />
                </Form.Item>

                <Form.Item className="mb-2" name="description" rules={[{ required: false }]}>
                    <BaseInputArea
                        label={"Beschreibung (optional)"}
                        placeholder="Eine detailierte Beschreibung des Auftrags"
                        onChange={(e: string) => {
                            if (props.setTaskData) {
                                props.setTaskData((prev: any) => {
                                    return {
                                        ...prev,
                                        description: e,
                                    }
                                })
                            }
                            if (props.setTaskTemplateData) {
                                props.setTaskTemplateData((prev: addTaskTemplateDataType) => ({
                                    ...prev,
                                    description: e,
                                }))
                            }
                        }}
                    />
                </Form.Item>
                {props.setTaskData && (
                    <Form.Item className="mb-2" name="dueAt" rules={[{ required: true, message: "Bitte ausfüllen!" }]}>
                        <BaseInputField
                            label={"Fällig am"}
                            type={"Date"}
                            min={dayjs().add(1, "day").format("YYYY-MM-DD")}
                            onChange={(e: string) => {
                                if (props.setTaskData) {
                                    props.setTaskData((prev: any) => {
                                        return {
                                            ...prev,
                                            dueAt: e,
                                        }
                                    })
                                }
                            }}
                        />
                    </Form.Item>
                )}

                <Form.Item className="mb-2" name="estimatedDurationMinutes" rules={[{ required: true, message: "Bitte ausfüllen!" }]}>
                    <BaseInputField
                        label={"Geschätze Zeit in min"}
                        type={"Number"}
                        placeholder="60"
                        onChange={(e: string) => {
                            if (props.setTaskData) {
                                props.setTaskData((prev: any) => {
                                    return {
                                        ...prev,
                                        estimatedDurationMinutes: Number(e),
                                    }
                                })
                            }
                            if (props.setTaskTemplateData) {
                                props.setTaskTemplateData((prev: addTaskTemplateDataType) => ({
                                    ...prev,
                                    estimatedDurationMinutes: Number(e),
                                }))
                            }
                        }}
                    />
                </Form.Item>

                <div className="flex justify-around">
                    <BaseSelection
                        label={"Besondere Priorität"}
                        placeholder={""}
                        value={
                            props.taskTemplateData?.priority === "default"
                                ? "Standard"
                                : props.taskTemplateData?.priority === "important"
                                ? "Hoch"
                                : props.taskData?.priority === "default"
                                ? "Standard"
                                : props.taskData?.priority === "important"
                                ? "Hoch"
                                : "Standard"
                        }
                        data={["Standard", "Hoch"]}
                        setId={(e: string) => {
                            if (props.setTaskData) {
                                console.log(e)
                                props.setTaskData((prev: any) => {
                                    return {
                                        ...prev,
                                        priority: e === "Hoch" ? "important" : "default",
                                    }
                                })
                            }
                            if (props.setTaskTemplateData) {
                                props.setTaskTemplateData((prev: addTaskTemplateDataType) => ({
                                    ...prev,
                                    priority: e === "Hoch" ? "important" : "default",
                                }))
                            }
                        }}
                    />
                </div>
                {props.setTaskTemplateData && (
                    <div className="border-t py-4 mt-4">
                        <label className="pl-2 pt-1">Ausführungsinterval</label>
                        <div className="flex gap-2 w-full pb-3">
                            <p className="pt-5 font-semibold">Ausführung</p>
                            <BaseSelection
                                label={""}
                                placeholder={"X"}
                                value={props.taskTemplateData?.recurrenceCreationPattern.interval ?? undefined}
                                classProps="!w-32"
                                data={
                                    props.taskTemplateData?.recurrenceCreationPattern?.recurrenceType === "week"
                                        ? ["1", "2", "3", "4"]
                                        : props.taskTemplateData?.recurrenceCreationPattern?.recurrenceType === "month"
                                        ? ["1", "2", "3", "4", "5", "6", "7", "8"]
                                        : ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"]
                                }
                                setId={(e: string) => {
                                    if (props.setTaskTemplateData) {
                                        props.setTaskTemplateData((prev: addTaskTemplateDataType) => ({
                                            ...prev,
                                            recurrenceCreationPattern: {
                                                ...prev.recurrenceCreationPattern,
                                                interval: e,
                                            },
                                        }))
                                    }
                                }}
                            />
                            <p className="pt-5 font-semibold">Mal</p>
                            <p className="pt-5 font-semibold">pro</p>
                            <BaseSelection
                                label={""}
                                placeholder={"Einheit"}
                                data={["Woche", "Monat", "Jahr"]}
                                value={
                                    props.taskTemplateData?.recurrenceCreationPattern.recurrenceType === "week"
                                        ? "Woche"
                                        : props.taskTemplateData?.recurrenceCreationPattern.recurrenceType === "month"
                                        ? "Monat"
                                        : props.taskTemplateData?.recurrenceCreationPattern.recurrenceType === "year"
                                        ? "Jahr"
                                        : undefined
                                }
                                setId={(e: string) => {
                                    if (props.setTaskTemplateData) {
                                        props.setTaskTemplateData((prev: addTaskTemplateDataType) => ({
                                            ...prev,
                                            recurrenceCreationPattern: {
                                                ...prev.recurrenceCreationPattern,
                                                recurrenceType: e === "Woche" ? "week" : e === "Monat" ? "month" : "year",
                                            },
                                        }))
                                    }
                                }}
                            />
                        </div>
                        <Form.Item className="mb-2" name="appearence" rules={[{ required: true, message: "Bitte ausfüllen!" }]}>
                            <BaseInputField
                                label={"Wie viele Tage vor Fälligkeit soll die Aufgabe angezeigt werden?"}
                                type={"number"}
                                onChange={(e: string) => {
                                    if (props.setTaskTemplateData) {
                                        props.setTaskTemplateData((prev: addTaskTemplateDataType) => ({
                                            ...prev,
                                            recurrenceCreationPattern: {
                                                ...prev.recurrenceCreationPattern,
                                                showDaysBeforeDueDate: Number(e),
                                            },
                                        }))
                                    }
                                }}
                            />
                        </Form.Item>
                        <div className="flex border-t mt-5 pt-2">
                            <BaseCheckbox
                                check={props.taskTemplateData?.recurrenceCreationPattern.endByDate !== undefined}
                                setcheck={(e: boolean) => {
                                    if (props.setTaskTemplateData) {
                                        props.setTaskTemplateData((prev: addTaskTemplateDataType) => ({
                                            ...prev,
                                            recurrenceCreationPattern: {
                                                ...prev.recurrenceCreationPattern,
                                                endByDate: e ? dayjs().endOf("year").toDate() : undefined,
                                            },
                                        }))
                                    }
                                }}
                                label={"Die Aufgabe gehört zu einer Jahresrechnung und soll zum 31. Dezember diesen Jahres enden"}
                            />
                        </div>
                    </div>
                )}
            </BaseModal>
        </Form>
    )
}
