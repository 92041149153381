import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { BrowserRouter } from "react-router-dom"
import { ModalContextProvider } from "./components/contexts/ModalContext"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactKeycloakProvider } from "@react-keycloak/web"
import keycloak from "./auth/Keycloak"
import Loading from "./components/common/Loading"
import { eventLogger, tokenLogger } from "./auth/EventListeners"
import { pdfjs } from "react-pdf"
import { SidePanelContextProvider } from "./components/contexts/SidePanelContext"
import { SelfUserContextProvider } from "./components/contexts/SelfUserContext"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

pdfjs.GlobalWorkerOptions.workerSrc =
    process.env.NODE_ENV === "production"
        ? new URL(window.location.origin + "/pdf-js/pdf.worker.min.mjs", import.meta.url).toString()
        : new URL("/public/pdf-js/pdf.worker.min.mjs", import.meta.url).toString()

root.render(
    // <React.StrictMode>
    <QueryClientProvider client={new QueryClient()}>
        <BrowserRouter>
            <ReactKeycloakProvider
                authClient={keycloak}
                LoadingComponent={<Loading />}
                autoRefreshToken={true}
                onEvent={eventLogger}
                onTokens={tokenLogger}
                initOptions={{ onLoad: "login-required" }}>
                <SelfUserContextProvider>
                    <ModalContextProvider>
                        <SidePanelContextProvider>
                            <App />
                        </SidePanelContextProvider>
                    </ModalContextProvider>
                </SelfUserContextProvider>
            </ReactKeycloakProvider>
        </BrowserRouter>
    </QueryClientProvider>
    // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
