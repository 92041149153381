import { adressType } from "../../../data/Types"
import { Employee } from "../../Mitarbeiter/types/Types"
import { ObjectType } from "../../Objekte/types/types"

export type taskStates = "created" | "assigned" | "unassigned" | "completed" | "execution-failed" | "waiting-for-customer-approval" | "reassigned"
export type taskTemplateStates = "active" | "paused" | "completed"
export type sessionStates = "scheduled" | "in-progress" | "completed" | "failed"

export type ShortOverviewType = {
    description: string
    index: string | number
}

export type RowElementType = {
    visible?: boolean
    standingOrder?: boolean
}

export type TaskDashboardCardType = {
    headline: string
    data?: {}
    width?: string
    standingOrder?: boolean
}

export type ModalTaskScreen2Type = {
    visible: boolean
}

export type ModalTaskScreen3Type = {
    visible: boolean
}

export const TaskActionCode = {
    ORDERREQUEST_CREATE: {
        value: "orderrequest-create",
        translation: "Kundenanfrage angenommen und Aufgabe erstellt",
    },
    MANUAL_CREATE: {
        value: "manual-create",
        translation: "Erstellt",
    },
    AUTO_CREATE: {
        value: "auto-create",
        translation: "Automatisch erstellt",
    },
    ASSIGN: {
        value: "assign",
        translation: "Zugewiesen",
    },
    UNASSIGN: {
        value: "unassign",
        translation: "Zuweisung entfernt",
    },
    COMPLETE: {
        value: "complete",
        translation: "Abgeschlossen",
    },
    UPDATED: {
        value: "updated",
        translation: "Aktualisiert",
    },
    MARK_AS_EXECUTE_FAILED: {
        value: "mark-as-execute-failed",
        translation: "Als fehlgeschlagen markiert",
    },
    MARK_AS_WAITING_FOR_CUSTOMER_APPROVAL: {
        value: "mark-as-waiting-for-customer-approval",
        translation: 'Gesetzt auf "Wartet auf Kunden"',
    },
    NOTE_ADDED: {
        value: "note-added",
        translation: "Notiz hinzugefügt",
    },
    DOCUMENT_ADDED: {
        value: "document-added",
        translation: "Bild(er) hinzugefügt",
    },
} as const

export type HistoryObjectType = {
    actionCode: (typeof TaskActionCode)[keyof typeof TaskActionCode]["value"]
    timestamp: Date
    employee?: Employee
}
export type NotesObjectType = {
    note: string
    createdAt: Date
    createdBy: Employee
    session: sessionGetDataType | string
}

export type getSingleTaskType = {
    _id: string
    activity: string
    description?: string
    object:
        | {
              _id: string
              adress: adressType
          }
        | string
    costCenter: {
        _id: string
        name: string
    }
    createdBy: {
        last_name: string
        first_name: string
    }
    lastModifiedBy: {
        last_name: string
        first_name: string
    }
    lastModifiedAt: Date
    createAt: Date
    dueAt: Date
    priority: "default" | "important"
    taskFrequency: "once" | "recurring"
    state: taskStates
    estimatedDurationMinutes: number
    history: HistoryObjectType[]
    notes?: NotesObjectType[]
    documents?: any[]
}

export type getSingleTaskTemplateType = {
    _id: string
    activity: string
    description?: string
    object:
        | {
              _id: string
              adress: adressType
          }
        | string
    costCenter: {
        _id: string
        name: string
    }
    createdBy: {
        last_name: string
        first_name: string
    }
    createAt: Date
    lastModifiedBy: {
        last_name: string
        first_name: string
    }
    lastModifiedAt: Date
    state: taskTemplateStates
    priority: "default" | "important"
    estimatedDurationMinutes: number
    recurrenceCreationPattern: {
        showDaysBeforeDueDate: number
        interval: string
        recurrenceType: "week" | "month" | "year"
        endByDate?: Date
    }
    createdTasks: HistoryObjectType[]
}

export type addTaskDataType = {
    activity: string
    description?: string
    object: string
    state: taskStates
    dueAt: string
    priority: "default" | "important"
    taskFrequency: "once" | "recurring"
    estimatedDurationMinutes: number
}

export type addTaskTemplateDataType = {
    activity: string
    description?: string
    object: string
    state: taskTemplateStates
    priority: "default" | "important"
    estimatedDurationMinutes: number
    recurrenceCreationPattern: {
        showDaysBeforeDueDate: number
        interval: string
        recurrenceType: "day" | "week" | "month" | "year"
        endByDate?: Date
    }
    costCenter: string // TODO - kommt raus
}

export type addSessionDataType = {
    title: string
    description?: string // WOFÜR?
    attendees: string[]
    tasks: string[]
    startAt: string
    endAt: string
    object: string
    status: "scheduled" | "in-progress" | "completed" | "failed"
}

export type CoordinateType = {
    latitude: Number
    longitude: Number
    accuracy: Number
}

export type sessionGetDataType = {
    _id: string
    title: string // TODO adresse
    description?: string
    attendees?: string[]
    tasks: getSingleTaskType[] | string[]
    failedTasks: getSingleTaskType[] | string[]
    object: ObjectType | string
    status: "scheduled" | "in-progress" | "completed" | "failed" // TODO
    costCenter: string
    startAt: Date
    endAt: Date
    checkInAt?: Date
    checkOutAt?: Date
    checkInWhere?: CoordinateType
    checkOutWhere?: CoordinateType
    assignedBy: any // TODO employee data set?
    checkInStatus: "checked-in" | "checked-out"
    totalDurationMinutesEstimated: Number
    totalDurationMinutesActual: Number
}

export type sessionCalendarDataType = {
    id: string
    title: string
    date?: string
    start: Date
    end: Date
    calendarId: string
    category: "time"
    user: string
    object_id: string
    location: string
    totalDurationMinutesEstimated: number
    totalDurationMinutesActual: number
    status: sessionStates
    assignedBy: string
    description: string
    checkInStatus: "checked-in" | "checked-out"
    tasks: getSingleTaskType[]
    failedTasks?: getSingleTaskType[]
    attendees: {
        _id: string
        first_name: string
        last_name: string
    }[]
}
