import { useContext, useEffect, useState } from "react"
import BaseInputField from "../../../components/elements/BaseInputField"
import BaseModal from "../../../components/layout/BaseModal"
import { CostCenterType, Employee } from "../types/Types"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { ModalContext } from "../../../components/contexts/ModalContext"
import BaseInputArea from "../../../components/elements/BaseInputArea"
import { Roles } from "../../../auth/RoleMapping"
import { RenderWhenAuthorized } from "../../../components/authentication/RenderWhenAuthorized"
import { getUserGroupsFromKC } from "../api/apiCalls"
import Loading from "../../../components/common/Loading"
import ErrorPage from "../../404/ErrorPage"
import BaseSelection from "../../../components/elements/BaseSelection"
import { validateEmail } from "../../../services/functions"
import { usePostDataMutation } from "../../api/services"
import { Form } from "antd"

type AddPersonModalType = {
    queryUrl: string
    costcenter: CostCenterType[]
}

export default function AddPersonModal(props: AddPersonModalType) {
    const queryClient = useQueryClient()
    const modalCon = useContext(ModalContext)

    const { data, error, isLoading } = useQuery({
        queryKey: ["kcUserGroups"],
        queryFn: getUserGroupsFromKC,
    })

    const [newEmployee, setNewEmployee] = useState<Employee>()

    // ADD Functions
    const { mutate: addEmployee } = usePostDataMutation(queryClient, "Mitarbeiter", props.queryUrl)
    const handleNewEmployee = () => {
        addEmployee({ body: newEmployee as Employee, pageType: "employee" })
        clearValues()
        modalCon?.trigger(1)
    }

    // FORMULAR WORK
    const [form] = Form.useForm()
    const clearValues = () =>
        form.setFieldsValue({
            first_name: undefined,
            last_name: undefined,
            email: undefined,
            contract_start: undefined,
            birthday: undefined,
            role: undefined,
            costcenter: undefined,
            notes: undefined,
        })
    useEffect(() => clearValues, [])

    return (
        <RenderWhenAuthorized requiresAll={[Roles.mitarbeiter_create]}>
            <Form form={form} onFinish={handleNewEmployee}>
                <BaseModal
                    title="Neuen Mitarbeiter hinzufügen"
                    modalId={1}
                    bottomRow={
                        <div className="flex flex-row justify-end">
                            <button type="submit" className="btn btn-success self-end" disabled={!validateEmail(newEmployee?.email ?? "")}>
                                Speichern
                            </button>
                        </div>
                    }>
                    {isLoading && <Loading />}
                    {error && <ErrorPage />}
                    {!error && !isLoading && data && (
                        <>
                            <Form.Item className="mb-2" name="first_name" rules={[{ required: true, message: "Bitte ausfüllen!" }]}>
                                <BaseInputField
                                    label={"Vorname "}
                                    type={"text"}
                                    onChange={(e) =>
                                        setNewEmployee((prev: any) => ({
                                            ...prev,
                                            first_name: e,
                                        }))
                                    }
                                    classProps="pt-4"
                                />
                            </Form.Item>
                            <Form.Item className="mb-2" name="last_name" rules={[{ required: true, message: "Bitte ausfüllen!" }]}>
                                <BaseInputField
                                    label={"Nachname "}
                                    type={"text"}
                                    onChange={(e) =>
                                        setNewEmployee((prev: any) => ({
                                            ...prev,
                                            last_name: e,
                                        }))
                                    }
                                />
                            </Form.Item>
                            <Form.Item className="mb-2" name="email" rules={[{ required: true, message: "Bitte ausfüllen!" }]}>
                                <BaseInputField
                                    label={"E-Mail-Adresse "}
                                    type={"email"}
                                    onChange={(e) =>
                                        setNewEmployee((prev: any) => ({
                                            ...prev,
                                            email: e,
                                        }))
                                    }
                                />
                            </Form.Item>
                            <Form.Item className="mb-2" name="birthday" rules={[{ required: true, message: "Bitte ausfüllen!" }]}>
                                <BaseInputField
                                    label={"Geburtstag"}
                                    type={"date"}
                                    onChange={(e) =>
                                        setNewEmployee((prev: any) => ({
                                            ...prev,
                                            geb: e,
                                        }))
                                    }
                                />
                            </Form.Item>
                            <Form.Item className="mb-2" name="role" rules={[{ required: true, message: "Bitte ausfüllen!" }]}>
                                <BaseSelection
                                    label="Rolle"
                                    data={data.rbac.map((item: { id: string; name: string }) => item.name)}
                                    setId={(e) => {
                                        form.setFieldsValue({ role: e })
                                        setNewEmployee((prev: any) => ({
                                            ...prev,
                                            position: e,
                                        }))
                                    }}
                                    placeholder="Rolle auswählen"
                                />
                            </Form.Item>
                            <Form.Item className="mb-2" name="contract_start" rules={[{ required: true, message: "Bitte ausfüllen!" }]}>
                                <BaseInputField
                                    label="Vertragsbeginn"
                                    type="date"
                                    onChange={(e) =>
                                        setNewEmployee((prev: any) => ({
                                            ...prev,
                                            contractStart: e,
                                        }))
                                    }
                                />
                            </Form.Item>
                            <Form.Item className="mb-2" name="costcenter" rules={[{ required: true, message: "Bitte ausfüllen!" }]}>
                                <BaseSelection
                                    label="Kostenstelle"
                                    data={props.costcenter?.map((item: { _id?: string; name: string }) => item.name)}
                                    setId={(e) => {
                                        form.setFieldsValue({ costcenter: e })
                                        setNewEmployee((prev: any) => ({
                                            ...prev,
                                            kostenstelle: props.costcenter?.find((item: { name: string }) => e === item.name)?._id ?? e,
                                        }))
                                    }}
                                    placeholder="Kostenstelle auswählen"
                                />
                            </Form.Item>
                            <Form.Item className="mb-2" name="notes" rules={[{ required: false }]}>
                                <BaseInputArea
                                    label={"Bemerkung (optional)"}
                                    onChange={(e) =>
                                        setNewEmployee((prev: any) => ({
                                            ...prev,
                                            notes: e,
                                        }))
                                    }
                                />
                            </Form.Item>
                        </>
                    )}
                </BaseModal>
            </Form>
        </RenderWhenAuthorized>
    )
}
