import { QueryClient, useMutation } from "@tanstack/react-query"
import { addTaskDataType, addTaskTemplateDataType, CoordinateType, getSingleTaskTemplateType, getSingleTaskType, sessionGetDataType } from "../types"
import { useState } from "react"
import {
    addImgsToTaskMutation,
    addNoteToTask,
    assignTasksToSession,
    checkInOrOutToSessionManual,
    checkInOrOutToSessionQrCode,
    deleteSession,
    deleteTask,
    deleteTaskTemplate,
    markTaskAsComplete,
    postNewSession,
    postNewTask,
    postNewTaskTemplate,
    taskExecutionFailed,
    unAssignTasksToSession,
    updateSession,
    updateSessionAttendees,
    updateTask,
    updateTaskTemplate,
    updateTaskTemplateState,
} from "./apiCalls"
import { messageError, messagePending, messageSuccess } from "../../../services/messages"

// ===== TASK ===== //

// ADD task
export const useAddTaskMutation = (queryClient: QueryClient, refreshData: string) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, addTaskDataType>({
        mutationFn: postNewTask,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Die Aufgabe wird gerade erstellt.")
        },
        onSuccess: () => {
            messageSuccess(notificationKey, "Fertig!", "Die Aufgabe wurde erfolgreich erstellt.")
            queryClient.invalidateQueries({
                queryKey: [refreshData],
            })
        },
        onError: (err: any) => {
            messageError(notificationKey, "Fehler!", "Die Aufgabe konnte nicht erstellt werden.")
            console.log(err)
        },
    })
    return mutation
}

// UPDATE task
export const useUpdateTaskMutation = (queryClient: QueryClient, refreshData: string) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, { id: string; data: getSingleTaskType }>({
        mutationFn: updateTask,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Die Aufgabe wird gerade aktualisiert.")
        },
        onSuccess: () => {
            messageSuccess(notificationKey, "Fertig!", "Die Aufgabe wurde erfolgreich aktualisiert.")
            queryClient.invalidateQueries({
                queryKey: [refreshData],
            })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Die Aufgabe konnte nicht aktualisiert werden.")
            console.log(err)
        },
    })
    return mutation
}

// DELETE task
export const useDeleteTaskMutation = (queryClient: QueryClient, refreshData: string) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, string>({
        mutationFn: deleteTask,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Die Aufgabe wird gerade gelöscht.")
        },
        onSuccess: () => {
            messageSuccess(notificationKey, "Fertig!", "Die Aufgabe wurde erfolgreich gelöscht.")
            queryClient.invalidateQueries({
                queryKey: [refreshData],
            })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Die Aufgabe konnte nicht gelöscht werden.")
            console.log(err)
        },
    })
    return mutation
}

// ADD Note to Task
export const useAddNoteToTaskMutation = (queryClient: QueryClient, refreshData: string) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, { id: string; note: { note: string; session: string } }>({
        mutationFn: addNoteToTask,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [refreshData],
            })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Die Notiz konnte nicht zur Aufgabe hinzugefügt werden.")
            console.log(err)
        },
    })
    return mutation
}

// Task Execution Failed
export const useTaskExecutionFailedMutation = (queryClient: QueryClient, refreshData: string) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, { id: string; note: { note: string; session: string } }>({
        mutationFn: taskExecutionFailed,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [refreshData],
            })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Die Aufgabe konnte nicht als unabgeschlossen markiert werden.")
            console.log(err)
        },
    })
    return mutation
}

// Mark Task as complete
export const useAddImgsToTaskMutation = (queryClient: QueryClient, refreshData: string) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, { id: string; formData: FormData }>({
        mutationFn: addImgsToTaskMutation,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [refreshData],
            })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Die Bilder konnten nicht zur Aufgabe hochgeladen werden.")
            console.log(err)
        },
    })
    return mutation
}

// Mark Task as complete
export const useMarkTaskAsCompleteMutation = (queryClient: QueryClient, refreshData: string) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, { id: string; note?: { note: string; session: string } }>({
        mutationFn: markTaskAsComplete,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [refreshData],
            })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Die Aufgabe konnte nicht als erledigt markiert werden.")
            console.log(err)
        },
    })
    return mutation
}

// ===== TASK TEMPLATE ===== //

// ADD task template
export const useAddTaskTemplateMutation = (queryClient: QueryClient, refreshData: string) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, addTaskTemplateDataType>({
        mutationFn: postNewTaskTemplate,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Die Aufgabe wird gerade erstellt.")
        },
        onSuccess: () => {
            messageSuccess(notificationKey, "Fertig!", "Die Aufgabe wurde erfolgreich erstellt.")
            queryClient.invalidateQueries({
                queryKey: [refreshData],
            })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Die Aufgabe konnte nicht erstellt werden.")
            console.log(err)
        },
    })
    return mutation
}

// UPDATE task template
export const useUpdateTaskTemplateMutation = (queryClient: QueryClient, refreshData: string) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, { id: string; body: getSingleTaskTemplateType }>({
        mutationFn: updateTaskTemplate,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Die Aufgabenregel wird gerade aktualisiert.")
        },
        onSuccess: () => {
            messageSuccess(notificationKey, "Fertig!", "Die Aufgabenregel wurde erfolgreich aktualisiert.")
            queryClient.invalidateQueries({
                queryKey: [refreshData],
            })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Die Aufgabenregel konnte nicht aktualisiert werden.")
            console.log(err)
        },
    })
    return mutation
}

// UPDATE task template
export const useUpdateTaskTemplateStateMutation = (queryClient: QueryClient, refreshData: string) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, string>({
        mutationFn: updateTaskTemplateState,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Die Aufgabenregel wird gerade aktualisiert.")
        },
        onSuccess: () => {
            messageSuccess(notificationKey, "Fertig!", "Die Aufgabenregel wurde erfolgreich aktualisiert.")
            queryClient.invalidateQueries({
                queryKey: [refreshData],
            })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Die Aufgabenregel konnte nicht aktualisiert werden.")
            console.log(err)
        },
    })
    return mutation
}

// DELETE task template
export const useDeleteTaskTemplateMutation = (queryClient: QueryClient, refreshData: string) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, string>({
        mutationFn: deleteTaskTemplate,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Die Aufgabe wird gerade aktualisiert.")
        },
        onSuccess: () => {
            messageSuccess(notificationKey, "Fertig!", "Die Aufgabe wurde erfolgreich aktualisiert.")
            queryClient.invalidateQueries({
                queryKey: [refreshData],
            })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Die Aufgabe konnte nicht aktualisiert werden.")
            console.log(err)
        },
    })
    return mutation
}

// ===== SESSION ===== //

// ADD session
export const useAddSessionMutation = (queryClient: QueryClient, refreshData: string, handleAssignTasks: (e: string) => void) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, sessionGetDataType>({
        mutationFn: postNewSession,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Der Termin wird gerade erstellt.")
        },
        // TODO any
        onSuccess: async (data: any) => {
            handleAssignTasks(data._id)
            messageSuccess(notificationKey, "Fertig!", "Der Termin wurde erfolgreich erstellt.")
            queryClient.invalidateQueries({
                queryKey: [refreshData],
            })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Der Termin konnte nicht erstellt werden.")
            console.log(err)
        },
    })
    return mutation
}

// UPDATE session
export const useUpdateSessionMutation = (queryClient: QueryClient, refreshData: string) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, { id: string; body: sessionGetDataType }>({
        mutationFn: updateSession,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Der Termin wird gerade aktualisiert.")
        },
        onSuccess: () => {
            messageSuccess(notificationKey, "Fertig!", "Der Termin wurde erfolgreich aktualisiert.")
            queryClient.invalidateQueries({
                queryKey: [refreshData],
            })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Der Termin konnte nicht aktualisiert werden.")
            console.log(err)
        },
    })
    return mutation
}

// UPDATE session attendees
export const useUpdateSessionAttendeesMutation = (queryClient: QueryClient, refreshData: string) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, { id: string; body: sessionGetDataType }>({
        mutationFn: updateSessionAttendees,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Der Termin wird gerade aktualisiert.")
        },
        onSuccess: () => {
            messageSuccess(notificationKey, "Fertig!", "Der Termin wurde erfolgreich aktualisiert.")
            queryClient.invalidateQueries({
                queryKey: [refreshData],
            })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Der Termin konnte nicht aktualisiert werden.")
            console.log(err)
        },
    })
    return mutation
}

// DELETE session
export const useDeleteSessionMutation = (queryClient: QueryClient, refreshData: string) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, string>({
        mutationFn: deleteSession,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Der Termin wird gerade gelöscht.")
        },
        onSuccess: () => {
            messageSuccess(notificationKey, "Fertig!", "Der Termin wurde erfolgreich gelöscht.")
            queryClient.invalidateQueries({
                queryKey: [refreshData],
            })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Der Termin konnte nicht gelöscht werden.")
            console.log(err)
        },
    })
    return mutation
}

// ASSIGN task to session
export const useAssignTaskToSessionMutation = (queryClient: QueryClient, refreshData: string, resetEvent: () => void) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, { id: string; body: string[]; dontDisplaySuccessMessage?: boolean }>({
        mutationFn: assignTasksToSession,
        onMutate: (variables) => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            if (!variables.dontDisplaySuccessMessage) {
                messagePending(newKey, "In Bearbeitung...", "Die Aufgabe(n) wird zum Termin hinzugefügt.")
            }
        },
        onSuccess: (data, variables) => {
            resetEvent()
            if (!variables.dontDisplaySuccessMessage) {
                messageSuccess(notificationKey, "Fertig!", "Die Aufgabe(n) wurde(n) erfolgreich zum Termin hinzugefügt.")
            }
            queryClient.invalidateQueries({
                queryKey: [refreshData],
            })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Die Aufgabe(n) konnte(n) nicht zum Termin hinzugefügt werden.")
            console.log(err)
        },
    })
    return mutation
}

// CHECK IN OR OUT to session via qr code
export const useCheckInOrOutToSessionViaQrCodeMutation = (queryClient: QueryClient, refreshData: string, closeModal: () => void) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<
        unknown,
        Error,
        {
            session: string
            qrcode: string
            indicator: "out" | "in"
            coordinates: CoordinateType
        }
    >({
        mutationFn: checkInOrOutToSessionQrCode,
        onMutate: (variables) => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
        },
        onSuccess: (data, variables) => {
            closeModal()
            queryClient.invalidateQueries({
                queryKey: [refreshData],
            })
        },
        onError: (err, variables) => {
            if (variables.indicator === "in") messageError(notificationKey, "Fehler!", "Sie konnten nicht eingecheckt werden.")
            if (variables.indicator === "out") messageError(notificationKey, "Fehler!", "Sie konnten nicht ausgecheckt werden.")
            console.log(err)
        },
    })
    return mutation
}

// CHECK IN OR OUT to session manual
export const useCheckInOrOutToSessionMutation = (queryClient: QueryClient, refreshData: string) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, { id: string; indicator: "out" | "in" }>({
        mutationFn: checkInOrOutToSessionManual,
        onMutate: (variables) => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            if (variables.indicator === "in") messagePending(newKey, "In Bearbeitung...", "Der Termin wird manuell eröffnet.")
            if (variables.indicator === "out") messagePending(newKey, "In Bearbeitung...", "Der Termin wird manuell geschlossen.")
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({
                queryKey: [refreshData],
            })
            if (variables.indicator === "in")
                messageSuccess(notificationKey, "Fertig!", "Der Termin wurde manuell eröffnet und die Teilnehmer eingecheckt.")
            if (variables.indicator === "out")
                messageSuccess(notificationKey, "Fertig!", "Der Termin wurde manuell geschlossen und die Teilnehmer ausgecheckt.")
        },
        onError: (err, variables) => {
            if (variables.indicator === "in") messageError(notificationKey, "Fehler!", "Der Termin konnte nicht manuell eröffnet werden.")
            if (variables.indicator === "out") messageError(notificationKey, "Fehler!", "Der Termin konnte nicht manuell geschlossen werden.")
            console.log(err)
        },
    })
    return mutation
}

// UNASSIGN task to session
export const useUnAssignTaskToSessionMutation = (queryClient: QueryClient, refreshData: string, handleAssignTasks: (e: string) => void) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, { id: string; body: string[]; areBothAssigningsExecuted?: boolean }>({
        mutationFn: unAssignTasksToSession,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Die Aufgabe(n) wird aus dem Termin entfernt.")
        },
        onSuccess: (data, variables) => {
            if (variables.areBothAssigningsExecuted) {
                handleAssignTasks(variables.id)
            }
            messageSuccess(notificationKey, "Fertig!", "Die Aufgabe(n) wurde(n) erfolgreich aus dem Termin entfernt")
            queryClient.invalidateQueries({
                queryKey: [refreshData],
            })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Die Aufgabe(n) konnte(n) nicht aus dem Termin entfernt werden.")
            console.log(err)
        },
    })
    return mutation
}
