import { useState } from "react"
import BaseInputField from "../../../components/elements/BaseInputField"
import BaseModal from "../../../components/layout/BaseModal"
import { ObjectType } from "../types/types"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import BaseInputSelection from "../../../components/elements/BaseInputSelection"
import { usePostDataMutation } from "../../api/services"
import { getPaginatedData } from "../../api/apiCalls"
import { useDelayWhenSearchWithPagination } from "../../../services/functions"
import BaseSelection from "../../../components/elements/BaseSelection"
import { Form } from "antd"
import { CostCenterType } from "../../Mitarbeiter/types/Types"

type AddObjectModalType = {
    modalId: number
    refreshDataUrl: string
    closeModal: () => void
    costcenterdata: CostCenterType[]
}

export default function AddObjectModal(props: AddObjectModalType) {
    const [searchTerm, setsearchTerm] = useState("")
    const searchParams = useDelayWhenSearchWithPagination(searchTerm)
    const queryKey = "customerDataToAddObj" + searchParams
    const {
        data: customer,
        error: errorCustomer,
        isLoading: isLoadingCustomer,
    } = useQuery({
        queryKey: [queryKey],
        queryFn: () => getPaginatedData({ pageType: "customer", page: 1, limit: 10, search: searchParams, archived: false, deleted: false }),
    })
    
    const queryClient = useQueryClient()
    const [street, setstreet] = useState<string>("")
    const [costcenter, setcostcenter] = useState<string>("")
    const [nr, setnr] = useState<string>("")
    const [plz, setplz] = useState<string>("")
    const [city, setcity] = useState<string>("")
    const [customer_id, setCustomerId] = useState<string | undefined>(undefined)

    const { mutate: addObject } = usePostDataMutation(queryClient, "Objekt", props.refreshDataUrl)
    const handleNewOne = () => {
        const newObjectData: ObjectType = {
            customer_id: customer_id as string,
            costCenter: costcenter,
            adress: {
                street: street,
                nr: nr,
                plz: plz,
                city: city,
            },
        }
        console.log({ body: newObjectData, pageType: "object" })
        addObject({ body: newObjectData, pageType: "object" })
        clearFormValues()
        props.closeModal()
    }

    // FRONTEND LOGIC
    const [form] = Form.useForm()
    const clearFormValues = () => {
        form.setFieldsValue({
            customer: undefined,
            street: undefined,
            nr: undefined,
            plz: undefined,
            city: undefined,
            costcenter: undefined,
        })
    }

    return (
        <Form form={form} onFinish={() => handleNewOne()} variant="outlined">
            <BaseModal
                onClose={clearFormValues}
                title={"Objekt hinzufügen"}
                modalId={props.modalId}
                size
                bottomRow={
                    <div className="flex justify-end">
                        <Form.Item>
                            <button className="btn btn-success" type="submit">
                                Speichern
                            </button>
                        </Form.Item>
                    </div>
                }>
                <>
                    <Form.Item className="pt-2 mb-2" name={"customer"} rules={[{ required: true, message: "Muss einen Wert enthalten!" }]}>
                        <BaseInputSelection
                            setId={(e) => {
                                form.setFieldsValue({ customer: e })
                                setCustomerId(e)
                            }}
                            label={"Kunde"}
                            placeholder={"Kunde auswählen"}
                            data={customer?.docs ?? []}
                            classProps="pt-2"
                            onSearch={(e: string) => setsearchTerm(e)}
                            customLoading={isLoadingCustomer}
                            customError={errorCustomer}
                        />
                    </Form.Item>
                    <div className="flex flex-row gap-4 w-full max-w-screen-lg">
                        <Form.Item className="mb-2 w-96" name={"street"} rules={[{ required: true, message: "Muss einen Wert enthalten!" }]}>
                            <BaseInputField label={"Straße"} type={"text"} onChange={setstreet} />
                        </Form.Item>
                        <Form.Item className="mb-2 w-40" name={"nr"} rules={[{ required: true, message: "Muss einen Wert enthalten!" }]}>
                            <BaseInputField label={"Hausnummer"} type={"text"} onChange={setnr} />
                        </Form.Item>
                    </div>
                    <div className="flex flex-row gap-4 w-full max-w-screen-lg">
                        <Form.Item className="mb-2 w-40" name={"plz"} rules={[{ required: true, message: "Muss einen Wert enthalten!" }]}>
                            <BaseInputField label={"PLZ"} type={"number"} onChange={setplz} />
                        </Form.Item>
                        <Form.Item className="mb-2 w-96" name={"city"} rules={[{ required: true, message: "Muss einen Wert enthalten!" }]}>
                            <BaseInputField label={"Stadt"} type={"text"} onChange={setcity} />
                        </Form.Item>
                    </div>
                    <Form.Item className="mb-4" name={"costcenter"} rules={[{ required: true, message: "Muss einen Wert enthalten!" }]}>
                        <BaseSelection
                            label={"Niederlassung zurordnen"}
                            placeholder={""}
                            data={props.costcenterdata.map((item) => item.name)}
                            setId={(newItem: any) => {
                                form.setFieldsValue({ costcenter: newItem })
                                let id = props.costcenterdata?.find((item: { name: string }) => newItem === item.name)?._id
                                setcostcenter(id ?? "")
                            }}
                        />
                    </Form.Item>
                </>
            </BaseModal>
        </Form>
    )
}
