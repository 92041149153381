import { Form } from "antd"
import BaseModal from "../../../components/layout/BaseModal"
import BaseInputField from "../../../components/elements/BaseInputField"
import { useState } from "react"
import { ReactComponent as DOWNLOAD } from "../../../assets/DownloadICON.svg"
import { useExportBillsMutation } from "../api/services"
import dayjs from "dayjs"

type ExportBillModalProps = {
    modalId: number
    close: () => void
}

export default function ExportBillsModal(props: ExportBillModalProps) {
    const [form] = Form.useForm()
    const [start, setstart] = useState<string>()
    const [end, setend] = useState<string>()

    const { mutate: getExportedBills } = useExportBillsMutation()
    const handleExport = () => {
        getExportedBills({ startDate: dayjs(start), endDate: dayjs(end) })
        setstart(undefined)
        setend(undefined)
        form.setFieldsValue({
            start: undefined,
            end: undefined,
        })
        props.close()
    }

    return (
        <BaseModal title={"Rechnungen exportieren"} modalId={props.modalId} size>
            <Form form={form} onFinish={handleExport}>
                <Form.Item className="my-2" name={"start"} rules={[{ required: true, message: "Bitte ausfüllen!" }]}>
                    <BaseInputField label={"Startdatum"} type={"date"} onChange={setstart} />
                </Form.Item>
                <Form.Item className="mb-2" name={"end"} rules={[{ required: true, message: "Bitte ausfüllen!" }]}>
                    <BaseInputField label={"Enddatum"} type={"date"} onChange={setend} />
                </Form.Item>
                <button className="btn btn-primary w-full mt-2" type="submit">
                    <DOWNLOAD className="w-6 h-6" />
                    Export
                </button>
            </Form>
        </BaseModal>
    )
}
