import { QueryClient, useMutation } from "@tanstack/react-query"
import { API } from "../../../api/constants"
import { authenticatedAxiosInstance } from "../../../auth/AuthenticatedAxios"
import { OrderRequestType } from "../types"

export const getSingleOrderRequestById = (id: string) => authenticatedAxiosInstance.get(API + `/orderrequest/${id}`).then((res) => res.data)

export const updateOrderRequestById = (data: { id: string; body: OrderRequestType }) =>
    authenticatedAxiosInstance.put(API + `/orderrequest/${data.id}`, data.body).then((res) => res.data)

// NOTE only testing as long as there is no kc for 01-fe-cus
export const useAddOrderRequest = (queryClient: QueryClient, queryKey: string) => {
    const mutation = useMutation<unknown, Error, any>({
        mutationFn: addOrderRequest,
        onSuccess: () => {
            alert("Success")
            queryClient.invalidateQueries({ queryKey: [queryKey] })
        },
        onError: (err) => {
            alert("Error")
            console.log(err)
        },
    })

    return mutation
}
export const addOrderRequest = (data: any) => authenticatedAxiosInstance.post(API + `/orderrequest`, data).then((res) => res.data)
