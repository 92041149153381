import { useContext } from "react"
import { Roles } from "../../../../auth/RoleMapping"
import { RenderWhenAuthorized } from "../../../../components/authentication/RenderWhenAuthorized"
import { ModalContext } from "../../../../components/contexts/ModalContext"
import BaseInputField from "../../../../components/elements/BaseInputField"
import BaseDivider from "../../../../components/elements/BaseDivider"
import { DocumentDataType } from "../../../../components/types"
import BaseDocumentElement from "../../../../components/elements/BaseDocumentElement"
import BaseInputArea from "../../../../components/elements/BaseInputArea"
import { SidePanelContext } from "../../../../components/contexts/SidePanelContext"
import { useKeycloak } from "@react-keycloak/web"
import BaseSelection from "../../../../components/elements/BaseSelection"
import { CostCenterType } from "../../../Mitarbeiter/types/Types"
import { ObjectType } from "../../types/types"
import { Form } from "antd"

type ObjectDetailPartRightType = {
    objectData: ObjectType
    setobjectData: (e: any) => void // TODO
    costCenterData: CostCenterType[] // TODO
    documents?: DocumentDataType[]
    handleDownload: (e: string, f: string) => void
    setfileToDelete: (e: string) => void
    setselectedS3Key: (e: string) => void
}

export default function ObjectDetailPartRight(props: ObjectDetailPartRightType) {
    const { keycloak } = useKeycloak()
    const modalCon = useContext(ModalContext)
    const sidePanelCon = useContext(SidePanelContext)
    const obvFound = props.documents?.find((item) => item?.tags?.includes("Objektbetreuungsvertrag"))

    return (
        <section className="flex flex-col p-4 bg-white rounded-default shadow-lg w-full grow overflow-x-hidden overflow-y-scroll">
            <div className="flex flex-col lg:flex-row gap-2 lg:gap-4 lg:items-end">
                <BaseInputField
                    disabled={!keycloak.hasRealmRole(Roles.objekte_read)}
                    label={"Einheiten pro Eingang"}
                    type={"number"}
                    value={props.objectData.objectInfo?.unitperentrance}
                    onChange={(e: string) =>
                        props.setobjectData((prev: any) => ({
                            ...prev,
                            objectInfo: {
                                ...prev.objectInfo,
                                unitperentrance: Number(e),
                            },
                        }))
                    }
                />
                <BaseInputField
                    disabled={!keycloak.hasRealmRole(Roles.objekte_read)}
                    label={"Gesamt"}
                    type={"number"}
                    value={props.objectData.objectInfo?.amount}
                    onChange={(e: string) =>
                        props.setobjectData((prev: any) => ({
                            ...prev,
                            objectInfo: {
                                ...prev.objectInfo,
                                amount: Number(e),
                            },
                        }))
                    }
                />
                <BaseInputField
                    disabled={!keycloak.hasRealmRole(Roles.objekte_read)}
                    label={"Grundstücksfläche im m²"}
                    type={"number"}
                    value={props.objectData.objectInfo?.propertyArea}
                    onChange={(e: string) =>
                        props.setobjectData((prev: any) => ({
                            ...prev,
                            objectInfo: {
                                ...prev.objectInfo,
                                propertyArea: Number(e),
                            },
                        }))
                    }
                />
            </div>
            <BaseInputField
                disabled={!keycloak.hasRealmRole(Roles.objekte_read)}
                classProps="pt-2"
                label={"Vertragsbeginn"}
                type={"date"}
                value={props.objectData.objectInfo?.contractStart}
                onChange={(e: string) =>
                    props.setobjectData((prev: any) => ({
                        ...prev,
                        objectInfo: {
                            ...prev.objectInfo,
                            contractStart: e,
                        },
                    }))
                }
            />
            <BaseInputField
                disabled={!keycloak.hasRealmRole(Roles.objekte_read)}
                classProps="pt-2"
                label={"Vertragsende"}
                type={"date"}
                value={props.objectData.objectInfo?.contractEnd}
                onChange={(e: string) =>
                    props.setobjectData((prev: any) => ({
                        ...prev,
                        objectInfo: {
                            ...prev.objectInfo,
                            contractEnd: e,
                        },
                    }))
                }
            />
            <BaseInputField
                disabled={!keycloak.hasRealmRole(Roles.objekte_read)}
                classProps="pt-2"
                label={"Letzte Preisanpassung"}
                type={"date"}
                value={props.objectData.objectInfo?.lastPriceChange}
                onChange={(e: string) =>
                    props.setobjectData((prev: any) => ({
                        ...prev,
                        objectInfo: {
                            ...prev.objectInfo,
                            lastPriceChange: e,
                        },
                    }))
                }
            />
            <BaseInputField
                disabled={!keycloak.hasRealmRole(Roles.objekte_read)}
                classProps="pt-2"
                label={"Letzte Vertragsänderung"}
                type={"date"}
                value={props.objectData.objectInfo?.lastContractChange}
                onChange={(e: string) =>
                    props.setobjectData((prev: any) => ({
                        ...prev,
                        objectInfo: {
                            ...prev.objectInfo,
                            lastContractChange: e,
                        },
                    }))
                }
            />
            <Form.Item className="mb-2" name={"costcenter"} rules={[{ required: true, message: "Muss einen Wert enthalten!" }]}>
                <BaseSelection
                    disabled={!keycloak.hasRealmRole(Roles.niederlassungen_read_write)}
                    classProps="pt-2"
                    placeholder={""}
                    label={"Zugeordnet zu Niederlassung"}
                    value={props.costCenterData?.find((item) => item._id === props.objectData.costCenter)?.name ?? ""}
                    data={props.costCenterData?.map((item) => item.name)}
                    setId={(newItem: any) => {
                        let id = props.costCenterData?.find((item: { name: string }) => newItem === item.name)?._id
                        props.setobjectData((prev: any) => ({
                            ...prev,
                            costCenter: id,
                        }))
                    }}
                />
            </Form.Item>
            <BaseInputArea
                label={"Bemerkung"}
                classProps="pt-2"
                disabled={!keycloak.hasRealmRole(Roles.objekte_read)}
                value={props.objectData.objectInfo?.notes}
                onChange={(e: string) =>
                    props.setobjectData((prev: any) => ({
                        ...prev,
                        objectInfo: {
                            ...prev.objectInfo,
                            notes: e,
                        },
                    }))
                }
            />
            <BaseDivider classProps="mt-6 mb-2" />
            <div className="flex flex-row gap-4 pt-2">
                <Form.Item className="mb-2 w-2/3" name={"street"} rules={[{ required: true, message: "Muss einen Wert enthalten!" }]}>
                    <BaseInputField
                        disabled={!keycloak.hasRealmRole(Roles.objekte_read)}
                        label={"Straße *"}
                        type={"text"}
                        value={props.objectData.adress.street}
                        onChange={(e: string) =>
                            props.setobjectData((prev: any) => ({
                                ...prev,
                                adress: {
                                    ...prev.adress,
                                    street: e,
                                },
                            }))
                        }
                    />
                </Form.Item>
                <Form.Item className="mb-2 w-1/3" name={"nr"} rules={[{ required: true, message: "Muss einen Wert enthalten!" }]}>
                    <BaseInputField
                        disabled={!keycloak.hasRealmRole(Roles.objekte_read)}
                        label={"Nummer *"}
                        type={"text"}
                        value={props.objectData.adress.nr}
                        onChange={(e: string) =>
                            props.setobjectData((prev: any) => ({
                                ...prev,
                                adress: {
                                    ...prev.adress,
                                    nr: e,
                                },
                            }))
                        }
                    />
                </Form.Item>
            </div>
            <div className="flex flex-row gap-4 pt-2">
                <Form.Item className="mb-2 w-1/3" name={"plz"} rules={[{ required: true, message: "Muss einen Wert enthalten!" }]}>
                    <BaseInputField
                        disabled={!keycloak.hasRealmRole(Roles.objekte_read)}
                        label={"PLZ *"}
                        width="w-64"
                        type={"text"}
                        value={props.objectData.adress.plz}
                        onChange={(e: string) =>
                            props.setobjectData((prev: any) => ({
                                ...prev,
                                adress: {
                                    ...prev.adress,
                                    plz: e,
                                },
                            }))
                        }
                    />
                </Form.Item>
                <Form.Item className="mb-2 w-2/3" name={"city"} rules={[{ required: true, message: "Muss einen Wert enthalten!" }]}>
                    <BaseInputField
                        disabled={!keycloak.hasRealmRole(Roles.objekte_read)}
                        label={"Stadt *"}
                        type={"text"}
                        value={props.objectData.adress.city}
                        onChange={(e: string) =>
                            props.setobjectData((prev: any) => ({
                                ...prev,
                                adress: {
                                    ...prev.adress,
                                    city: e,
                                },
                            }))
                        }
                    />
                </Form.Item>
            </div>
            <BaseDivider classProps="mt-6 mb-2" />
            {/* OBV Document */}
            {obvFound && (
                <>
                    <div className="flex flex-col pt-2">
                        <label className="text-sm pl-2 pb-1">Vertrag zum Objekt</label>
                        <BaseDocumentElement
                            obv
                            title={obvFound.title}
                            roles={[Roles.objekte_read_write]}
                            timestamp={obvFound.timestamp}
                            downloadFunc={() => props.handleDownload(obvFound.s3Key, obvFound.title)}
                            deleteFunc={() => {
                                props.setfileToDelete(obvFound.s3Key)
                                modalCon?.trigger(4)
                            }}
                            showPreviewFunc={() => {
                                props.setselectedS3Key(obvFound.s3Key)
                                sidePanelCon?.trigger(6)
                            }}
                        />
                    </div>
                    <BaseDivider classProps="mt-2 mb-2" />
                </>
            )}
            {/* Docuemnt-Part */}
            <div className="flex flex-col pt-2">
                <label className="text-sm pl-2 pb-1">Dokumente</label>
                {props.documents?.map((item: DocumentDataType, index: number) => (
                    <BaseDocumentElement
                        key={index}
                        title={item.title}
                        roles={[Roles.objekte_read_write]}
                        timestamp={item.timestamp}
                        downloadFunc={() => props.handleDownload(item.s3Key, item.title)}
                        deleteFunc={() => {
                            props.setfileToDelete(item.s3Key)
                            modalCon?.trigger(4)
                        }}
                        showPreviewFunc={() => {
                            props.setselectedS3Key(item.s3Key)
                            sidePanelCon?.trigger(6)
                        }}
                    />
                ))}
                {props.documents?.length === 0 && <p className="pl-4 text-xs">Keine Dokumente vorhanden</p>}
                <RenderWhenAuthorized requiresAll={[Roles.objekte_read_write]}>
                    <button
                        className="hidden md:block btn btn-sm bg-base-100 shadow-lg border-none hover:bg-white/40 mt-2 mb-4 mr-4 self-end"
                        onClick={() => modalCon?.trigger(3)}>
                        Dokument hinzufügen
                    </button>
                </RenderWhenAuthorized>
            </div>
        </section>
    )
}
