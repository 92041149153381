type ErrorPageType = {
    classProps?: string
}

export default function ErrorPage(props: ErrorPageType) {
    return (
        <div className={`w-full h-full flex justify-center flex-col items-center ${props.classProps}`}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 mt-4y">
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                />
            </svg>
            <p className="text-lg uppercase tracking-wider font-semibold leading-5 pt-1">Error aufgetreten</p>
            <p className="text-sm">Administrator kontaktieren</p>
        </div>
    )
}
