import { Popconfirm } from "antd"
import { ReactNode } from "react"

type BasePopConfirmType = {
    label: string
    placement?:
        | "topLeft"
        | "top"
        | "topRight"
        | "bottomLeft"
        | "bottom"
        | "bottomRight"
        | "left"
        | "leftBottom"
        | "leftTop"
        | "right"
        | "rightBottom"
        | "rightTop"
    icon?: ReactNode
    text?: string
    okButtonLabel: string
    okButtonClick: () => void
    okButtonClass?: string
    noButtonLabel?: string
    noButtonClass?: string
    children: ReactNode
}

export default function BasePopConfirm(props: BasePopConfirmType) {
    return (
        <Popconfirm
            placement={props.placement}
            title={props.label}
            icon={props.icon ?? <></>}
            description={props.text}
            okText={props.okButtonLabel}
            cancelText={props.noButtonLabel ?? "Abbrechen"}
            onConfirm={props.okButtonClick}
            okButtonProps={{ className: props.okButtonClass ?? "!btn-primary !btn !btn-sm !hover:btn-primary" }}
            cancelButtonProps={{ className: props.noButtonClass ?? "!btn !btn-outline !btn-sm" }}>
            {props.children}
        </Popconfirm>
    )
}
