import { Dayjs } from "dayjs"
import { getSingleTaskType } from "../../Aufgaben/types"
import { Customer } from "../../Kunden/types/Types"
import { Employee } from "../../Mitarbeiter/types/Types"
import { ObjectType } from "../../Objekte/types/types"

export let OrderRequestState: { value: string; translation: string }[] = [
    { value: "created", translation: "Neue Anfrage" },
    { value: "waiting-for-customer-approval", translation: "Wartet auf Kunden" },
    { value: "accepted", translation: "Angenommen" },
    { value: "completed", translation: "Abgeschlossen" },
    { value: "billed", translation: "Rechnung erstellt" },
    { value: "declined", translation: "Abgelehnt" },
]

export let OrderRequestActionCode: { value: string; translation: string }[] = [
    { value: "create", translation: "Neue Anfrage erstellt" },
    { value: "mark-as-waiting-for-customer-approval", translation: 'Mit "Rücksprache erforderlich" markiert' },
    { value: "accepted", translation: "Anfrage angenommen" },
    { value: "complete", translation: "Auftrag abgeschlossen" },
    { value: "billed", translation: "Rechnung erstellt" },
    { value: "declined", translation: "Anfrage abgelehnt" },
]

export type OrderRequestHistoryData = {
    actionCode: (typeof OrderRequestActionCode)[number]["value"]
    person: Employee | any
    portalAccess?: { mail: string } 
    timestamp: Dayjs
    message?: string
}

export type OrderRequestType = {
    _id: string
    requestTask: string
    descriptionOrder?: string
    object?: ObjectType
    objectFromCustomer: string
    descriptionTask?: string
    descriptionTaskFromCustomer: string
    dueDate: Date
    priceForRelease?: number
    priceForReleaseFromCustomer: number
    contactPerson?: string
    contactTel?: string
    customer: Customer
    createdAt: string
    lastModifiedBy?: string
    estimatedDurationMinutes?: number
    lastModifiedAt?: string
    task?: getSingleTaskType
    state: (typeof OrderRequestState)[number]["value"]
    history?: OrderRequestHistoryData[]
}
