import { Pagination } from "antd"
import BaseButtonRow from "../../../components/elements/BaseButtonRow"
import BaseGrowBody from "../../../components/elements/BaseGrowBody"
import BaseHeadBanner from "../../../components/elements/BaseHeadBanner"
import BaseInputSearch from "../../../components/elements/BaseInputSearch"
import BasePage from "../../../components/layout/BasePage"
import OrderRequestTable from "../components/OrderRequestTable"
import { useContext, useMemo, useState } from "react"
import { useDelayWhenSearchWithPagination } from "../../../services/functions"
import { getPaginatedData } from "../../api/apiCalls"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { useAddOrderRequest } from "../api/apiCalls"
import { useUpdateOrderRequest } from "../api/services"
import { OrderRequestType } from "../types"
import Loading from "../../../components/common/Loading"
import BillFromOrderRequestModal from "../../Buchhaltung/components/billFromOrderRequest/BillFromOrderRequestModal"
import { ModalContext } from "../../../components/contexts/ModalContext"

export default function OrderRequestPage() {
    // ORDERREQUESTS get all
    const [page, setpage] = useState<number>(1)
    const handlePageChange = (page: number) => setpage(page)
    const [searchTermOrderRequests, setsearchTermOrderRequests] = useState("")
    const searchParamsOrderRequests = useDelayWhenSearchWithPagination(searchTermOrderRequests)
    const queryKeyOrderRequests = "orderrequests" + searchParamsOrderRequests
    const {
        error: allOrderRequestsError,
        isLoading: allOrderRequestsIsLoading,
        data: allOrderRequestsData,
    } = useQuery({
        queryKey: [queryKeyOrderRequests],
        queryFn: () =>
            getPaginatedData({
                pageType: "orderrequest",
                page: page,
                limit: 20,
                search: searchParamsOrderRequests,
            }),
    })
    const displayedOrderRequests = useMemo(() => allOrderRequestsData?.docs, [allOrderRequestsData?.docs])

    // UPDATE FUNCTIONS
    const queryclient = useQueryClient()
    const { mutate: updateOrderRequestState } = useUpdateOrderRequest(queryclient, queryKeyOrderRequests)
    const handleUpdateOrderRequestState = (state: string, id: string) => {
        updateOrderRequestState({ id: id, body: { state: state } as OrderRequestType })
    }

    return (
        <BasePage>
            <BaseHeadBanner title="Anfragen" />
            <BaseButtonRow>
                <BaseInputSearch placeholder={"Anfrage suchen"} searchTerm={searchTermOrderRequests} setsearchTerm={setsearchTermOrderRequests} />
            </BaseButtonRow>
            <BaseGrowBody>
                {allOrderRequestsIsLoading && <Loading />}
                {!allOrderRequestsIsLoading && !allOrderRequestsError && (
                    <OrderRequestTable
                        orderrequest={displayedOrderRequests}
                        handleUpdateOrderRequestState={handleUpdateOrderRequestState}
                    />
                )}
            </BaseGrowBody>
            {/* PAGINATION */}
            <div className="flex justify-center w-full p-4">
                <Pagination
                    pageSize={20}
                    defaultCurrent={1}
                    current={page}
                    onChange={handlePageChange}
                    total={allOrderRequestsData?.totalDocs}
                    showSizeChanger={false}
                />
            </div>
        </BasePage>
    )
}
