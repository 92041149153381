import BaseInputField from "../../../components/elements/BaseInputField"
import BaseModal from "../../../components/layout/BaseModal"

type AddCustomerPortalAccessModalType = {
    modalId: number
    mail: string
    setmail: (e: string) => void
    submit: () => void
    close: () => void
}

export default function AddCustomerPortalAccessModal(props: AddCustomerPortalAccessModalType) {
    return (
        <BaseModal
            size
            onClose={props.close}
            modalId={props.modalId}
            title="Portalzugang"
            bottomRow={
                <div className="flex justify-end w-full">
                    <button className="btn btn-success" onClick={props.submit}>
                        Speichern
                    </button>
                </div>
            }>
            <div className="py-2">
                <p className="text-sm">Auf welche E-Mail-Adresse soll das Account erstellt werden?</p>
                <BaseInputField label={""} type={"mail"} value={props.mail} onChange={props.setmail} />
            </div>
        </BaseModal>
    )
}
