import BaseSelection from "../../../../../components/elements/BaseSelection"
import { PositionData } from "../../../../../data/Types"
import { ReactComponent as MINUS } from "../../../../../assets/MinusCircle.svg"
import BaseInputField from "../../../../../components/elements/BaseInputField"
import { Form, FormInstance } from "antd"

type InputPositionDataType = {
    form: FormInstance
    index: number
    position: PositionData
    setposition: (index: number, attribute: string, e: string | number) => void
    removePosition: (e: number) => void
}

export default function PositionInputs(props: InputPositionDataType) {
    return (
        <div className="flex flex-col w-full gap-1 px-4 pb-4 pt-2 rounded-default border relative mt-3">
            <div className="flex flex-row justify-between px-4 border-b py-2 mb-2">
                <p className="pl-0">Position {props.index + 1}</p>
                <MINUS className="w-6 h-6 cursor-pointer" onClick={() => props.removePosition(props.index)} />
            </div>
            <div className="flex flex-row w-full gap-4">
                <Form.Item className="w-48 mb-2" name={"amount"} rules={[{ required: true, message: "Muss einen Wert enthalten!" }]}>
                    <BaseInputField label={"Menge"} type={"number"} onChange={(e) => props.setposition(props.index, "amount", e)} />
                </Form.Item>
                <Form.Item className="w-full mb-2" name={"unit"} rules={[{ required: true, message: "Muss einen Wert enthalten!" }]}>
                    <BaseInputField label={"Einheit"} type={"text"} onChange={(e) => props.setposition(props.index, "unit", e)} />
                </Form.Item>
                <Form.Item className="w-64 mb-2" name={"tax"} rules={[{ required: true, message: "Muss einen Wert enthalten!" }]}>
                    <BaseSelection
                        setId={(e) => {
                            if (e === "19% MwSt.") {
                                props.form?.setFieldsValue({ tax: "19 %" })
                                props.setposition(props.index, "tax", 19)
                            } else {
                                props.form?.setFieldsValue({ tax: "7 %" })
                                props.setposition(props.index, "tax", 7)
                            }
                        }}
                        data={["19% MwSt.", "7% MwSt."]}
                        label={"Abrechnung *"}
                        placeholder={"Bitte auswählen"}
                    />
                </Form.Item>
            </div>
            <div className="flex flex-row w-full gap-4 justify-end">
                <Form.Item className="w-full mb-2" name={"description"} rules={[{ required: true, message: "Muss einen Wert enthalten!" }]}>
                    <BaseInputField
                        label={"Beschreibung"}
                        type={"text"}
                        onChange={(e) => {
                            props.setposition(props.index, "description", e)
                        }}
                    />
                </Form.Item>
                <Form.Item className="w-48 mb-2" name={"singlePrice"} rules={[{ required: true, message: "Muss einen Wert enthalten!" }]}>
                    <BaseInputField
                        prevent
                        label={"Einzelpreis (€)"}
                        type={"number"}
                        onChange={(e) => {
                            props.setposition(props.index, "singlePrice", e)
                        }}
                    />
                </Form.Item>
                <div className="flex flex-col justify-between w-48 items-end">
                    <label className="text-sm pl-2 pb-1">Gesamt</label>
                    <p className="font-semibold text-lg">{(props.position?.singlePrice * props.position?.amount).toFixed(2).replace(".", ",")} €</p>
                </div>
            </div>
        </div>
    )
}
