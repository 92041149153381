import BaseModal from "../../../components/layout/BaseModal"
import { Employee } from "../../Mitarbeiter/types/Types"
import { sessionCalendarDataType } from "../types"

type EditAttendeesOfSessionModalType = {
    modalId: number
    sessionCompleted: boolean
    employees: Employee[]
    currentAttendees: {
        _id: string
        first_name: string
        last_name: string
    }[]
    setEmployee: (e?: any) => void // TODO
    onSave: () => void
}

export default function EditAttendeesOfSessionModal(props: EditAttendeesOfSessionModalType) {
    return (
        <BaseModal
            title="Mitarbeiter verwalten"
            modalId={props.modalId}
            size
            bottomRow={
                <div className="flex justify-end w-full">
                    {!props.sessionCompleted && (
                        <button className="btn btn-sm btn-success" onClick={props.onSave}>
                            Speichern
                        </button>
                    )}
                </div>
            }>
            {props.currentAttendees && props.employees && (
                <div className={`${!props.currentAttendees ? "h-48" : "py-4"} w-96 overflow-y-scroll flex flex-col items-center max-h-80`}>
                    {props.currentAttendees.map((item) => (
                        <div className="w-full border-b flex justify-between py-2 px-4" key={item?._id}>
                            <p>{item.first_name + " " + item.last_name}</p>
                            <input
                                disabled={props.currentAttendees.length <= 1}
                                type="checkbox"
                                checked={true}
                                onChange={(e) =>
                                    props.setEmployee((prev: sessionCalendarDataType) => ({
                                        ...prev,
                                        attendees: [...prev.attendees.filter((current) => current._id !== item._id)],
                                    }))
                                }
                                className="checkbox border-black"
                            />
                        </div>
                    ))}
                    {!props.sessionCompleted &&
                        props.employees
                            .filter((item) => !props.currentAttendees.some((current) => current._id === item._id))
                            .map((item) => (
                                <div className="w-full border-b flex justify-between py-2 px-4" key={item?._id}>
                                    <p>{item.first_name + " " + item.last_name}</p>
                                    <input
                                        disabled={false}
                                        type="checkbox"
                                        checked={false}
                                        onChange={() =>
                                            props.setEmployee((prev: sessionCalendarDataType) => ({
                                                ...prev,
                                                attendees: [...prev.attendees, item as { _id: string; first_name: string; last_name: string }],
                                            }))
                                        }
                                        className="checkbox border-black"
                                    />
                                </div>
                            ))}
                </div>
            )}
        </BaseModal>
    )
}
