import { Table, Tag } from "antd"
import type { TableProps } from "antd"
import dayjs from "dayjs"
import { HashLink } from "react-router-hash-link"
import { ReactComponent as INFO } from "../../../assets/InfoICON.svg"
import { ReactComponent as DELETE } from "../../../assets/DeleteICON.svg"
import { ReactComponent as PLAY } from "../../../assets/Play.svg"
import { ReactComponent as PAUSE } from "../../../assets/Pause.svg"
import { useNavigate } from "react-router-dom"
import BasePopConfirm from "../../../components/elements/BasePopConfirm"
import { getSingleTaskTemplateType, getSingleTaskType } from "../types"
import { ReactComponent as CALENDAR } from "../../../assets/CalendarICON.svg"
import "../../../style/scroll.css"

type AllTasksTableType = {
    tasks?: getSingleTaskType[] | getSingleTaskTemplateType[]
    taskTemplates?: getSingleTaskTemplateType[]
    addOrPause?: (e: string) => void
    openSelectEmployeeModal?: () => void
    deleteTask: (e: string) => void
}

export default function AllTasksTable(props: AllTasksTableType) {
    const navigate = useNavigate()

    const columnsTasks: TableProps<getSingleTaskType>["columns"] = [
        {
            title: "Aufgabe",
            dataIndex: "activity",
            key: "activity",
            align: "center",
            render: (text) => <p>{text}</p>,
        },
        {
            title: "Objekt",
            dataIndex: "object",
            key: "object",
            align: "center",
            render: (_, record) =>
                typeof record.object !== "string" && (
                    <HashLink to={"/objekte/" + record.object._id}>
                        {/* TODO */}
                        {record.object.adress.street +
                            " " +
                            record.object.adress.nr +
                            ", " +
                            record.object.adress.plz +
                            " " +
                            record.object.adress.city}
                    </HashLink>
                ),
        },
        {
            title: "Erstellt am",
            dataIndex: "createAt",
            key: "createAt",
            align: "center",
            className: "hidden lg:table-cell",
            render: (_, { createAt }) => <p>{dayjs(createAt).format("DD.MM.YYYY")}</p>,
        },
        {
            title: "Fällig am",
            dataIndex: "dueAt",
            key: "dueAt",
            align: "center",
            render: (_, { dueAt }) => <p>{dayjs(dueAt).format("DD.MM.YYYY")}</p>,
        },
        {
            title: "Priorität",
            dataIndex: "priority",
            key: "priority",
            align: "center",
            render: (_, { priority }) => (
                <Tag className="w-20 text-center" color={priority === "default" ? "blue" : "red-inverse"}>
                    {priority === "default" ? "Standard" : "Hoch"}
                </Tag>
            ),
        },

        {
            title: "Status",
            dataIndex: "state",
            key: "state",
            align: "center",
            render: (_, { state }) => (
                <Tag className="w-32 text-center" color={state === "created" ? "blue" : state === "execution-failed" ? "red-inverse" : "gold"}>
                    {state === "created"
                        ? "Erstellt"
                        : state === "execution-failed"
                        ? "Zurückgewiesen"
                        : state === "unassigned"
                        ? "Zuweisung entfernt"
                        : "Wartet auf Kunde"}
                </Tag>
            ),
        },
        {
            title: "Frequenz",
            dataIndex: "taskFrequency",
            key: "taskFrequency",
            align: "center",
            className: "hidden lg:table-cell",
            render: (_, { taskFrequency }) => (
                <Tag className="w-24 text-center" color={taskFrequency === "once" ? "" : "grey"}>
                    {taskFrequency === "once" ? "Einmalig" : "Wiederkehrend"}
                </Tag>
            ),
        },
        {
            title: "",
            dataIndex: "",
            key: "",
            width: "4rem",
            align: "center",
            render: (_, { _id }) => <INFO className="w-6 h-6 cursor-pointer" onClick={() => navigate("/aufgaben/" + _id)} />,
        },

        {
            title: "",
            dataIndex: "",
            key: "",
            align: "center",
            width: "4rem",
            className: "hidden xl:table-cell",
            render: (_, { _id }) => <CALENDAR className="w-6 h-6 cursor-pointer" onClick={props.openSelectEmployeeModal} />,
        },
        {
            title: "",
            dataIndex: "",
            key: "",
            align: "center",
            width: "4rem",
            className: "hidden md:table-cell",
            render: (_, { _id }) => (
                <BasePopConfirm label={"Wirklich Löschen?"} okButtonLabel={"Bestätigen"} okButtonClick={() => props.deleteTask(_id)}>
                    <DELETE className="w-6 h-6 cursor-pointer" />
                </BasePopConfirm>
            ),
        },
    ]

    const columnsTaskTemplates: TableProps<getSingleTaskTemplateType>["columns"] = [
        {
            title: "Aufgabe",
            dataIndex: "activity",
            key: "activity",
            align: "center",
            render: (text) => <p>{text}</p>,
        },
        {
            title: "Objekt",
            dataIndex: "object",
            key: "object",
            align: "center",
            render: (_, record) =>
                typeof record.object !== "string" && (
                    <HashLink to={"/objekte/" + record.object._id}>
                        {/* TODO */}
                        {record.object.adress.street +
                            " " +
                            record.object.adress.nr +
                            ", " +
                            record.object.adress.plz +
                            " " +
                            record.object.adress.city}
                    </HashLink>
                ),
        },
        {
            title: "Erstellt am",
            dataIndex: "createAt",
            key: "createAt",
            align: "center",
            render: (_, { createAt }) => <p>{dayjs(createAt).format("DD.MM.YYYY")}</p>,
        },
        {
            title: "Priorität",
            dataIndex: "priority",
            key: "priority",
            align: "center",
            render: (_, { priority }) => (
                <Tag className="w-20 text-center" color={priority === "default" ? "blue" : "red-inverse"}>
                    {priority === "default" ? "Standard" : "Hoch"}
                </Tag>
            ),
        },

        {
            title: "Status",
            dataIndex: "state",
            key: "state",
            align: "center",
            render: (_, { state }) => (
                <Tag className="w-32 text-center" color={state === "active" ? "green" : state === "paused" ? "" : "grey-inverse"}>
                    {state === "active" ? "Aktiv" : state === "paused" ? "Pausiert" : "Fertig"}
                </Tag>
            ),
        },
        {
            title: "Frequenz",
            dataIndex: "taskFrequency",
            key: "taskFrequency",
            align: "center",
            className: "hidden lg:table-cell",
            render: (_, { recurrenceCreationPattern }) => (
                <Tag className="w-24 text-center">
                    {recurrenceCreationPattern.interval +
                        "x pro " +
                        (recurrenceCreationPattern.recurrenceType === "year"
                            ? "Jahr"
                            : recurrenceCreationPattern.recurrenceType === "month"
                            ? "Monat"
                            : "Woche")}
                </Tag>
            ),
        },
        {
            title: "",
            dataIndex: "",
            key: "",
            width: "4rem",
            align: "center",
            render: (_, { _id }) => <INFO className="w-6 h-6 cursor-pointer" onClick={() => navigate("/aufgaben-wiederkehrend/" + _id)} />,
        },

        {
            title: "",
            dataIndex: "",
            key: "",
            align: "center",
            width: "4rem",
            className: "hidden xl:table-cell",
            render: (_, { _id, state }) => (
                <p title="Zuweisen">
                    {state === "active" ? (
                        <PAUSE className="w-6 h-6 cursor-pointer" onClick={() => props.addOrPause !== undefined && props.addOrPause(_id)} />
                    ) : (
                        <PLAY className="w-6 h-6 cursor-pointer" onClick={() => props.addOrPause !== undefined && props.addOrPause(_id)} />
                    )}
                </p>
            ),
        },
        {
            title: "",
            dataIndex: "",
            key: "",
            width: "4rem",
            align: "center",
            render: (_, { _id }) => (
                <BasePopConfirm label={"Wirklich Löschen?"} okButtonLabel={"Bestätigen"} okButtonClick={() => props.deleteTask(_id)}>
                    <DELETE className="w-6 h-6 cursor-pointer" />
                </BasePopConfirm>
            ),
        },
    ]

    return (
        <>
            {props.tasks && (
                <Table<getSingleTaskType>
                    className={"w-full overflow-x-scroll md:overflow-hidden bg-white rounded-default"}
                    pagination={false}
                    scroll={{ y: "64vh" }}
                    columns={columnsTasks}
                    dataSource={props.tasks as getSingleTaskType[]}
                />
            )}
            {props.taskTemplates && (
                <Table<getSingleTaskTemplateType>
                    className="w-full overflow-x-scroll md:overflow-x-hidden bg-white rounded-default"
                    pagination={false}
                    scroll={{ y: "64vh" }}
                    columns={columnsTaskTemplates}
                    dataSource={props.taskTemplates as getSingleTaskTemplateType[]}
                />
            )}
        </>
    )
}
