import BaseInputField from "../../../../../components/elements/BaseInputField"
import { PositionData } from "../../../../../data/Types"

type PositionReviewType = {
    position?: PositionData
    setnewBill: (e: any) => void
}

export default function PositionReview(props: PositionReviewType) {
    return (
        <div className="flex w-full gap-2 pb-2">
            <BaseInputField label={"Nr."} type={""} onChange={() => {}} width="w-16" disabled value={props.position?.position_id} />
            <BaseInputField label={"Beschreibung"} type={""} onChange={() => {}} width="grow" disabled value={props.position?.description} />
            <BaseInputField
                label={"Einheit"}
                type={"text"}
                onChange={(e) =>
                    props.setnewBill((prev: any) => ({
                        ...prev,
                        positions: [
                            {
                                ...prev.positions[0],
                                unit: e,
                            },
                        ],
                    }))
                }
                width="w-40"
                value={props.position?.unit}
            />
            <BaseInputField
                label={"MwSt."}
                type={""}
                onChange={(e) =>
                    props.setnewBill((prev: any) => ({
                        ...prev,
                        positions: [
                            {
                                ...prev.positions[0],
                                tax: e,
                            },
                        ],
                    }))
                }
                width="w-20"
                value={props.position?.tax}
            />
            <BaseInputField label={"Menge"} type={""} onChange={() => {}} width="w-20" disabled value={props.position?.amount} />
            <BaseInputField label={"Einzelpreis"} type={""} onChange={() => {}} width="w-40" disabled value={props.position?.singlePrice} />
        </div>
    )
}
