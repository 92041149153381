import BaseModal from "../layout/BaseModal"
import { ReactComponent as INFO } from "../../assets/InfoICON.svg"
import { useQueryClient } from "@tanstack/react-query"
import { useStartZipExportMutation } from "../../pages/api/services"

type DeleteObjectModalType = {
    modalId: number
    title: string
    queryKey: string
    data: { id: string; pageType: string }
    closeModal: () => void
}

export default function DeleteWithZipModal(props: DeleteObjectModalType) {
    const queryClient = useQueryClient()
    const { mutate: startZipExport } = useStartZipExportMutation(queryClient, props.queryKey)
    const handleStart = () => {
        props.closeModal()
        startZipExport(props.data)
    }
    return (
        <BaseModal modalId={props.modalId} title={props.title + " löschen"} size>
            <div className="max-w-[24rem]">
                <p className="text-justify py-2">
                    Bitte erstellen Sie zunächst eine ZIP-Datei, die alle zum {props.title} gehörigen Informationen, Dokumente und Anhänge enthält.
                    Nachdem die Erstellung abgeschlossen ist, erhalten Sie auf Ihre Mail einen Link, über welchen Sie die Datei herunterladen können.
                    Dieser Link ist 7 Tage lang gültig.
                </p>
                <button onClick={handleStart} className="btn btn-primary w-full">
                    Zip Datei erstellen
                </button>
                <p className="text-justify py-2 flex">
                    <INFO className="w-6 h-6 text-red-600" />
                    <span className="w-fit pl-2 font-bold">
                        Durch den Vorgang werden alle zugehörigen Angebote und Rechnungen aus dem System gelöscht. Die gesetzliche Pflicht zur
                        Aufbewahrung geht auf Sie über!
                    </span>
                </p>
            </div>
        </BaseModal>
    )
}
