import { preventKeyDown } from "../../services/functions"
import { Input, Popover } from "antd"
import { ReactComponent as QUESTION } from "../../assets/Question.svg"

type BaseInputFieldType = {
    variant?: "outlined" | "borderless" | "filled" | undefined
    label: string
    type: string
    classProps?: string
    status?: "error" | "warning" | undefined
    placeholder?: string
    prevent?: boolean
    value?: string | number
    bg?: string
    width?: string
    steps?: number
    required?: boolean
    onChange: (e: string) => void
    onChangeWithNumber?: (e: number) => void
    disabled?: boolean
    min?: string
    explainText?: string
}

export default function BaseInputField(props: BaseInputFieldType) {
    return (
        <div className={`flex flex-col ${props.width ? props.width : "w-full"} ${props.classProps}`}>
            <label className="text-sm pl-2 pb-1 flex gap-2">
                {props.label}

                {props.explainText && (
                    <Popover content={props.explainText}>
                        <QUESTION className="w-5 h-6" />
                    </Popover>
                )}
            </label>
            <Input
                onKeyDown={props.prevent ? preventKeyDown : () => {}}
                // required={props.required ?? true}
                onChange={(e) => props.onChange(e.target.value)}
                prefix={<></>}
                min={props.min ?? undefined}
                status={props.status ?? undefined}
                type={props.type}
                value={props.value}
                disabled={props.disabled}
                placeholder={props.placeholder}
                variant={props.variant}
                rootClassName={"border h-14 rounded-default w-full"}
            />
        </div>
    )
}
