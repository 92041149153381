import { useEffect, useState } from "react"
import { PositionData, BillGet } from "../data/Types"
import dayjs from "dayjs"
import { CoordinateType, sessionCalendarDataType, sessionGetDataType, TaskActionCode } from "../pages/Aufgaben/types"
import { ObjectType } from "../pages/Objekte/types/types"

// prevent the tabulator key down event
export const preventKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement> | React.KeyboardEvent<HTMLTextAreaElement> | React.KeyboardEvent<HTMLSelectElement>
) => {
    if (event.key === "Tab") {
        event.preventDefault()
    }
}

// compare values - return true if a value is different to its comparison
export const compareValues = (
    values: {
        v1: string | number | boolean | undefined
        v2: string | number | boolean | undefined
    }[]
) => {
    const bool = values.map((item) => {
        if (item.v1 !== item.v2) {
            return false
        } else {
            return true
        }
    })
    const result = bool.includes(false)
    return result
}

// extract suffix from file name
export function getSuffix(fileName: string) {
    const dotIndex = fileName.lastIndexOf(".")

    if (dotIndex === -1 || dotIndex === fileName.length - 1) {
        return ""
    }

    return fileName.substring(dotIndex + 1)
}

// check if value is empty - returns TRUE if there are empty values given
export const checkForEmptyValues = (values: (string | number | Number)[]) => {
    const bool: any = values.map((item) => {
        if (item === "" || item === undefined || item === 0) {
            return true
        } else {
            return false
        }
    })

    const result = bool.includes(true)
    return result
}

// calculates total amount from bill
export const calculateTotal = (bill: BillGet) => {
    return bill?.positions
        ?.map((position) => position.amount * position.singlePrice * (1 + 0.01 * position.tax))
        .reduce((acc, value) => acc + value, 0)
        .toFixed(2)
        .replace(".", ",")
}

// calculates total amount from positions
export const calculatePositionTotal = (positions: PositionData[]) => {
    return positions
        ?.map((position) => position.amount * position.singlePrice * (1 + 0.01 * position.tax))
        .reduce((acc, value) => acc + value, 0)
        .toFixed(2)
}

// validate email input
export const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
}

// humanizes mail type in FE
export const returnHumanizedMailtype = (mailType: string) => {
    switch (mailType) {
        case "bill":
            return "Rechnung"
        case "offer":
            return "Angebot"
        case "reminder":
            return "Mahnung"
        case "cancel_bill":
            return "Stornierung"
        default:
            return
    }
}

// delays pagination request for a few seconds
export const useDelayWhenSearchWithPagination = (value: string) => {
    const [debouncedValue, setdebouncedValue] = useState(value)

    useEffect(() => {
        const handler = setTimeout(() => {
            setdebouncedValue(value)
        }, 1000)

        return () => {
            clearTimeout(handler)
        }
    }, [value])

    return debouncedValue ?? ""
}

// formats the currency
export function formatCurrency(amount: number, currency = "EUR", locale = "de-DE") {
    if (isNaN(amount)) return amount
    return new Intl.NumberFormat(locale, {
        style: "currency",
        currency: currency,
    }).format(amount)
}

// checks if a date is between today and the today in x days
export function checkDate(date: Date, timeFrame: number) {
    const now = dayjs()
    const futureDate = now.add(timeFrame, "day")
    const givenDate = dayjs(date)
    return givenDate.isAfter(now.subtract(1, "day")) && givenDate.isBefore(futureDate.add(1, "day"))
}

// export join address from object-object
export function returnAddressString(obj: ObjectType) {
    return obj?.adress?.street + " " + obj?.adress?.nr + ", " + obj?.adress?.plz + " " + obj?.adress?.city
}

// transform session-data from db-type into calendar-type
export function rearrangeSessionDataForCalendar(sessionData: sessionGetDataType[], user_id: string) {
    return sessionData?.map((item: sessionGetDataType) => ({
        title: item.title,
        id: item._id,
        start: item.startAt,
        end: item.endAt,
        calendarId: "cal1",
        category: "time",
        user: user_id,
        object_id: typeof item.object !== "string" && item.object._id,
        location:
            typeof item.object !== "string" &&
            item.object.adress.street + " " + item.object.adress.nr + ", " + item.object.adress.plz + " " + item.object.adress.city,
        totalDurationMinutesEstimated: item.totalDurationMinutesEstimated,
        totalDurationMinutesActual: item.totalDurationMinutesActual,
        checkInStatus: item.checkInStatus,
        status: item.status,
        assignedBy: item.assignedBy,
        description: item.description,
        tasks: item.tasks,
        attendees: item.attendees,
    })) as unknown as sessionCalendarDataType[]
}

// transform session-data from calendar-type into db-type for post
export function rearrangeSessionDataForDB(sessionData: sessionCalendarDataType, user_id: string, obj: ObjectType) {
    return {
        title: "Einsatz: " + returnAddressString(obj),
        description: sessionData.description,
        attendees: [user_id],
        tasks: sessionData?.tasks?.map((item) => item._id),
        object: sessionData.object_id,
        status: "scheduled",
        startAt: dayjs(`${sessionData?.date}T${sessionData.start}:00`).toDate(),
        endAt: dayjs(`${sessionData.date}T${sessionData.end}:00`).toDate(),
    } as sessionGetDataType
}

// transform session-data from calendar-type into db-type for update
export function rearrangeSessionDataForDBUpdate(sessionData: sessionCalendarDataType, actualSession: sessionCalendarDataType) {
    const { startAt, endAt } = (() => {
        return {
            startAt: dayjs(
                `${sessionData.date ?? dayjs(actualSession.start).format("YYYY-MM-DD")}T${
                    sessionData.start ?? dayjs(actualSession.start).format("HH:mm:ss")
                }`
            ).toDate(),
            endAt: dayjs(
                `${sessionData.date ?? dayjs(actualSession.end).format("YYYY-MM-DD")}T${
                    sessionData.end ?? dayjs(actualSession.end).format("HH:mm:ss")
                }`
            ).toDate(),
        }
    })()
    return {
        description: sessionData?.description ?? actualSession.description,
        startAt: startAt,
        endAt: endAt,
    } as sessionGetDataType
}

// transform the TaskActionCode in Human Readable Format
export function humanizeTaskActionCode(code: (typeof TaskActionCode)[keyof typeof TaskActionCode]["value"]) {
    const entries = Object.entries(TaskActionCode) as [keyof typeof TaskActionCode, (typeof TaskActionCode)[keyof typeof TaskActionCode]][]
    for (const [, entry] of entries) {
        if (entry.value === code) {
            return entry.translation
        }
    }
}

// NOTE currently not used, maybe later maybe not
// check if device allows to request location
export function checkIfLocationOrCamIsEnabledOnDevice() {
    // Check Location Permission
    if (!localStorage.getItem("locationPermission") || localStorage.getItem("locationPermission") !== "granted") {
        navigator.geolocation.getCurrentPosition(
            () => localStorage.setItem("locationPermission", "granted"),
            () => localStorage.setItem("locationPermission", "denied")
        )
    }

    // Check Camera Permission
    if (!localStorage.getItem("cameraPermission") || localStorage.getItem("cameraPermission") !== "granted") {
        navigator.mediaDevices
            .getUserMedia({ video: true })
            .then(() => localStorage.setItem("cameraPermission", "granted"))
            .catch(() => localStorage.setItem("cameraPermission", "denied"))
    }
}

// get Location from device in that very moment
export async function getLocationFromDevice(): Promise<CoordinateType> {
    if ("geolocation" in navigator) {
        // Helper function to get location with specific accuracy
        const getGeolocation = (enableHighAccuracy: boolean): Promise<CoordinateType> => {
            return new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const { latitude, longitude, accuracy } = position.coords
                        resolve({
                            latitude,
                            longitude,
                            accuracy,
                        })
                    },
                    (error) => {
                        console.error("Error getting location:", error.message)
                        reject(error) // Reject with the error
                    },
                    {
                        enableHighAccuracy: enableHighAccuracy,
                        timeout: 5000, // Maximum wait time in milliseconds
                        maximumAge: 0, // Do not use cached location
                    }
                )
            })
        }

        try {
            // Get high and low accuracy results concurrently
            const [highAccuracyResult, lowAccuracyResult] = await Promise.allSettled([getGeolocation(true), getGeolocation(false)])

            // Extract successful results
            const highAccuracyLocation = highAccuracyResult.status === "fulfilled" ? highAccuracyResult.value : null
            const lowAccuracyLocation = lowAccuracyResult.status === "fulfilled" ? lowAccuracyResult.value : null

            // Determine the better result based on accuracy
            if (highAccuracyLocation && lowAccuracyLocation) {
                console.log("Checkpoint")
                return highAccuracyLocation.accuracy <= lowAccuracyLocation.accuracy ? highAccuracyLocation : lowAccuracyLocation
            }

            // Fallback to whichever result succeeded
            return highAccuracyLocation || lowAccuracyLocation || Promise.reject("No location found")
        } catch (error) {
            console.error("Error during geolocation:", error)
            throw error
        }
    } else {
        throw new Error("Geolocation is not supported by this browser.")
    }
}

// shuts down camera after use
export function shutDownCamera() {
    navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {
            const tracks = stream.getTracks() // Get all media tracks
            tracks.forEach((track) => track.stop()) // Stop each track
            console.log("Camera stopped successfully")
        })
        .catch((error) => {
            console.error("Error stopping the camera:", error)
        })
}


// sets start / end of session always on full or half hours
export const handleTimeChange = (event: string) => {
    const inputValue = event
    const [hours, minutes] = inputValue.split(":")

    // Adjust minutes to be either 00 or 30
    const adjustedMinutes =
        parseInt(minutes) < 15
            ? "00"
            : parseInt(minutes) > 15 && parseInt(minutes) < 30
            ? "15"
            : parseInt(minutes) > 30 && parseInt(minutes) < 45
            ? "30"
            : "45"
    const adjustedTime = `${hours}:${adjustedMinutes}`

    return adjustedTime // Update the state with the adjusted time
}

// transform error message in human readable format
export const erroeMessageHumanzing = (error: Error) => {
    console.log(error)
}

// general translater of states/actioncodes
export const translateStateOrActionCode = (cryptic: string, translaterArray: { value: string; translation: string }[]) => {
    return translaterArray.find((item) => item.value === cryptic)?.translation
}