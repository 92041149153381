import { useNavigate, useParams } from "react-router-dom"
import BaseButton from "../../../components/elements/BaseButton"
import BaseButtonRow from "../../../components/elements/BaseButtonRow"
import BaseGrowBody from "../../../components/elements/BaseGrowBody"
import BaseHeadBanner from "../../../components/elements/BaseHeadBanner"
import BaseInputField from "../../../components/elements/BaseInputField"
import BasePage from "../../../components/layout/BasePage"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { useContext, useEffect, useState } from "react"
import { OrderRequestState, OrderRequestType } from "../types"
import { getSingleOrderRequestById } from "../api/apiCalls"
import dayjs from "dayjs"
import { returnAddressString } from "../../../services/functions"
import { ObjectType } from "../../Objekte/types/types"
import OrderRequestHistory from "../components/OrderRequestHistory"
import BaseInputArea from "../../../components/elements/BaseInputArea"
import { useUpdateOrderRequest } from "../api/services"
import { ReactComponent as APPROVE } from "../../../assets/ApprovaICON.svg"
import { ReactComponent as PERSON } from "../../../assets/People.svg"
import { ReactComponent as DENY } from "../../../assets/DeleteICON.svg"
import { ReactComponent as MONEY } from "../../../assets/Cash.svg"
import { ReactComponent as TASK } from "../../../assets/OfferIcon.svg"
import AcceptRequestModal from "../components/AcceptRequestModal"
import { ModalContext } from "../../../components/contexts/ModalContext"
import { NAVIGATION_ROUTES } from "../../../App"
import BasePopConfirm from "../../../components/elements/BasePopConfirm"
import BillFromOrderRequestModal from "../../Buchhaltung/components/billFromOrderRequest/BillFromOrderRequestModal"

export default function OrderRequestDetailPage() {
    const { id } = useParams()
    // TASK get single
    const queryKeySingleOrderRequest = "orderrequest" + id
    const {
        error: singleOrderRequestError,
        isLoading: singleOrderRequestIsLoading,
        data: singleOrderRequestData,
    } = useQuery({
        queryKey: [queryKeySingleOrderRequest],
        queryFn: () => getSingleOrderRequestById(id ?? ""),
        enabled: !!id,
    })
    const [displayedOrderRequest, setdisplayedOrderRequest] = useState<OrderRequestType>()
    useEffect(() => setdisplayedOrderRequest(singleOrderRequestData), [singleOrderRequestData])

    // UPDATE order request
    const querclient = useQueryClient()
    const { mutate: updateOrderRequest } = useUpdateOrderRequest(querclient, queryKeySingleOrderRequest)
    const updateOrderRequestState = (id: string, state: (typeof OrderRequestState)[number]["value"]) =>
        updateOrderRequest({ id: id, body: { state: state } as OrderRequestType })

    // ACCEPT order request
    const [acceptedOrderRequest, setacceptedOrderRequest] = useState<OrderRequestType>()
    const handleAcceptOrderRequest = () => {
        let orderRequestBody = {
            ...acceptedOrderRequest,
            state: "accepted",
        } as OrderRequestType
        updateOrderRequest({ id: id ?? "", body: orderRequestBody })
        modalCon?.trigger(0)
    }

    // FE LOGIC
    const modalCon = useContext(ModalContext)
    const navigate = useNavigate()

    return (
        <BasePage>
            <BaseHeadBanner
                title={
                    "Anfrage: " +
                    (displayedOrderRequest?.object
                        ? returnAddressString(displayedOrderRequest?.object as ObjectType)
                        : displayedOrderRequest?.objectFromCustomer)
                }
            />
            {displayedOrderRequest && !singleOrderRequestError && !singleOrderRequestIsLoading && (
                <>
                    {displayedOrderRequest.state !== "declined" && (
                        <BaseButtonRow justifyEnd>
                            {(displayedOrderRequest.state === "created" || displayedOrderRequest.state === "waiting-for-customer-approval") && (
                                <>
                                    <BasePopConfirm
                                        label={"Wirklich ablehnen?"}
                                        okButtonLabel={"Ablehnen"}
                                        okButtonClick={() => updateOrderRequestState(displayedOrderRequest._id, "declined")}>
                                        <>
                                            <BaseButton label={"Auftrag Ablehnen"} func={() => {}} icon={<DENY className="w-6 h-6" />} />
                                        </>
                                    </BasePopConfirm>

                                    {displayedOrderRequest.state !== "waiting-for-customer-approval" && (
                                        <BaseButton
                                            label={"Rücksprache erforderlich"}
                                            func={() => updateOrderRequestState(displayedOrderRequest._id, "waiting-for-customer-approval")}
                                            icon={<PERSON className="w-6 h-6" />}
                                        />
                                    )}
                                    <BaseButton label={"Auftrag Annehmen"} func={() => modalCon?.trigger(0)} icon={<APPROVE className="w-6 h-6" />} />
                                </>
                            )}
                            {displayedOrderRequest.state === "completed" && (
                                <BaseButton label={"Rechnung erstellen"} func={() => modalCon?.trigger(1)} icon={<MONEY className="w-6 h-6" />} />
                            )}
                            {displayedOrderRequest.task && (
                                <BaseButton
                                    label={"Aufgabe ansehen"}
                                    func={() => navigate(NAVIGATION_ROUTES.TASK + displayedOrderRequest.task)}
                                    icon={<TASK className="w-6 h-6" />}
                                />
                            )}
                            {displayedOrderRequest.state === "accepted" && (
                                <BaseButton
                                    label={"Auftrag abschließen"}
                                    func={() => updateOrderRequestState(displayedOrderRequest._id, "completed")}
                                    icon={undefined}
                                />
                            )}
                        </BaseButtonRow>
                    )}
                    <BaseGrowBody classProps="flex gap-4 pt-2">
                        {/* <OrderRequestInformation> */}
                        <div className="w-1/2 bg-white shadow-lg rounded-default p-4 overflow-y-scroll flex flex-col gap-2">
                            <BaseInputField
                                label={"Angefragte Aufgabe"}
                                value={displayedOrderRequest?.requestTask}
                                type={"text"}
                                disabled
                                onChange={() => {}}
                            />
                            <div className="flex gap-3">
                                <BaseInputField
                                    label={"Angefragtes Objekt"}
                                    value={displayedOrderRequest.objectFromCustomer}
                                    type={"text"}
                                    disabled
                                    onChange={() => {}}
                                />
                                {displayedOrderRequest?.object && (
                                    <BaseInputField
                                        label={"Zugeordnetes Objekt"}
                                        value={
                                            displayedOrderRequest?.object
                                                ? returnAddressString(displayedOrderRequest?.object as ObjectType)
                                                : "Bisher kein Objekt zugeordnet"
                                        }
                                        type={"text"}
                                        disabled
                                        onChange={() => {}}
                                    />
                                )}
                            </div>
                            <BaseInputField
                                label={"Kunde"}
                                value={displayedOrderRequest?.customer?.customer?.name}
                                type={"text"}
                                disabled
                                onChange={() => {}}
                            />
                            <div className="flex gap-3">
                                <BaseInputArea
                                    disabled
                                    minRows={6}
                                    maxRows={10}
                                    label={"Beschreibung der Aufgaben (von Kunde)"}
                                    value={displayedOrderRequest?.descriptionTaskFromCustomer}
                                    onChange={() => {}}
                                />
                                {displayedOrderRequest.descriptionTask && (
                                    <BaseInputArea
                                        disabled
                                        minRows={6}
                                        maxRows={10}
                                        label={"Beschreibung der Aufgaben (Anpassung)"}
                                        value={displayedOrderRequest?.descriptionTask}
                                        onChange={() => {}}
                                    />
                                )}
                            </div>
                            <BaseInputArea
                                disabled
                                minRows={6}
                                maxRows={10}
                                label={"Beschreibung operativ"}
                                value={displayedOrderRequest?.descriptionOrder ?? "keine Angabe"}
                                onChange={() => {}}
                            />
                            <BaseInputField
                                label={"Fällig am"}
                                value={dayjs(displayedOrderRequest?.dueDate).format("DD.MM.YYYY")}
                                type={"text"}
                                disabled
                                onChange={() => {}}
                            />
                            <div className="flex gap-3">
                                <BaseInputField
                                    label={"Preisrahmen"}
                                    value={"bis " + displayedOrderRequest?.priceForReleaseFromCustomer?.toFixed(2)?.replace(".", ",") + " €"}
                                    type={"text"}
                                    disabled
                                    onChange={() => {}}
                                />
                                {displayedOrderRequest?.priceForRelease && (
                                    <BaseInputField
                                        label={"Auf Preis geeinigt"}
                                        value={displayedOrderRequest?.priceForRelease?.toFixed(2)?.replace(".", ",") + " €"}
                                        type={"text"}
                                        disabled
                                        onChange={() => {}}
                                    />
                                )}
                            </div>
                            <div className="flex gap-4">
                                <BaseInputField
                                    label={"Ansprechpartner"}
                                    value={displayedOrderRequest?.contactPerson ?? "keine Angabe"}
                                    type={"text"}
                                    disabled
                                    onChange={() => {}}
                                />
                                <BaseInputField
                                    label={"Telefon"}
                                    value={displayedOrderRequest?.contactTel ?? "keine Angabe"}
                                    type={"text"}
                                    disabled
                                    onChange={() => {}}
                                />
                            </div>
                        </div>
                        <div className="flex flex-col gap-4 w-1/2 h-full">
                            <OrderRequestHistory history={displayedOrderRequest?.history} />
                        </div>
                    </BaseGrowBody>
                    <AcceptRequestModal
                        modalId={0}
                        displayedRequest={displayedOrderRequest as OrderRequestType}
                        setacceptedOrderRequest={setacceptedOrderRequest}
                        acceptRequest={handleAcceptOrderRequest}
                    />
                    <BillFromOrderRequestModal id={displayedOrderRequest._id} modalId={1} billData={displayedOrderRequest as OrderRequestType} />
                </>
            )}
        </BasePage>
    )
}
