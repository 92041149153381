import BaseInputSelection from "../../../../components/elements/BaseInputSelection"
import { InputObject } from "../../../../components/types"
import { Customer } from "../../../Kunden/types/Types"

type ObjectDetailPartLeftCustomerType = {
    customer: Customer[]
    searchTerm?: string
    customerOfObject: string
    setobjectData: (e: any) => void
    setsearchTermCustomer: (e: any) => void
}

export default function ObjectDetailPartLeftCustomer(props: ObjectDetailPartLeftCustomerType) {
    return (
        <div className="flex py-4 border-y-2 mt-2">
            <BaseInputSelection
                label={"Kunde"}
                placeholder={""}
                value={props.searchTerm ?? props.customerOfObject}
                data={(props.customer as InputObject[]) ?? []}
                setId={(e: string) =>
                    props.setobjectData((prev: any) => ({
                        ...prev,
                        customer_id: e,
                    }))
                }
                onSearch={props.setsearchTermCustomer}
            />
        </div>
    )
}
