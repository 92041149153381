import { HeadBannerType } from "../types"

export default function BaseHeadBanner({ title, button }: HeadBannerType) {
    return (
        <div className="w-full bg-white shadow-lg px-8 sm:px-12 md:px-20 py-8 xl:p-8 rounded-default flex flex-row justify-between items-center relative h-24">
            <h1 className="text-lg sm:text-xl md:text-2xl font-bold uppercase tracking-wider line-clamp-2 sm:line-clamp-none sm:truncate">{title}</h1>
            {button}
        </div>
    )
}
