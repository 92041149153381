import { Table, Tag } from "antd"
import type { TableProps } from "antd"
import dayjs from "dayjs"
import { HashLink } from "react-router-hash-link"
import { ReactComponent as INFO } from "../../../assets/InfoICON.svg"
import { ReactComponent as DELETE } from "../../../assets/DeleteICON.svg"
import { useNavigate } from "react-router-dom"
import BasePopConfirm from "../../../components/elements/BasePopConfirm"
import { ReactComponent as MONEY } from "../../../assets/Cash.svg"
import { ReactComponent as CLOCK } from "../../../assets/Clock.svg"
import { OrderRequestState, OrderRequestType } from "../types"
import { translateStateOrActionCode } from "../../../services/functions"

type AllTasksTableType = {
    orderrequest?: OrderRequestType[]
    handleUpdateOrderRequestState: (e: string, f: string) => void
}

export default function OrderRequestTable(props: AllTasksTableType) {
    const navigate = useNavigate()

    const columnsTasks: TableProps<OrderRequestType>["columns"] = [
        // REQUEST TASK
        {
            title: "Anfrage",
            dataIndex: "requestTask",
            key: "requestTask",
            align: "center",
            render: (text) => <p>{text}</p>,
        },
        // OBJECT
        {
            title: "Objekt",
            dataIndex: "object",
            key: "object",
            align: "center",
            render: (_, { object, objectFromCustomer }) =>
                typeof object !== "undefined" ? (
                    <HashLink to={"/objekte/" + object?._id}>
                        {/* TODO */}
                        {object?.adress?.street + " " + object?.adress?.nr + ", " + object?.adress?.plz + " " + object?.adress?.city}
                    </HashLink>
                ) : (
                    <p>{objectFromCustomer}</p>
                ),
        },
        // CREATED AT
        {
            title: "Erstellt am",
            dataIndex: "createdAt",
            key: "createdAt",
            align: "center",
            className: "hidden lg:table-cell",
            render: (_, { createdAt }) => <p>{dayjs(createdAt).format("DD.MM.YYYY")}</p>,
        },
        // DUE DATEA
        {
            title: "Fällig am",
            dataIndex: "dueDate",
            key: "dueDate",
            align: "center",
            render: (_, { dueDate }) => <p>{dayjs(dueDate).format("DD.MM.YYYY")}</p>,
        },
        // PRICE
        {
            title: "Preisrahmen",
            dataIndex: "priceForRelease",
            key: "priceForRelease",
            align: "center",
            render: (_, { priceForRelease, priceForReleaseFromCustomer }) => (
                <p>
                    {priceForRelease ? priceForRelease?.toFixed(2)?.replace(".", ",") : priceForReleaseFromCustomer?.toFixed(2)?.replace(".", ",")} €
                </p>
            ),
        },
        // STATUS
        {
            title: "Status",
            dataIndex: "state",
            key: "state",
            align: "center",
            render: (_, { state }) => (
                <Tag
                    className="w-32 text-center"
                    color={state === "created" ? "blue" : state === "declined" ? "red-inverse" : state === "completed" ? "green" : "gold"}>
                    {translateStateOrActionCode(state, OrderRequestState)}
                </Tag>
            ),
        },
        // INFO -> navigate to detail page
        {
            title: "",
            dataIndex: "",
            key: "",
            align: "center",
            width: "6rem",
            render: (_, { _id }) => <INFO className="w-6 h-6 cursor-pointer" onClick={() => navigate("/anfragen/" + _id)} />,
        },
        // APPROVE OR WAINTING_FOR_CUSTOMER
        {
            title: "",
            dataIndex: "",
            key: "",
            align: "center",
            width: "6rem",
            className: "hidden xl:table-cell",
            render: (_, render) => (
                <>
                    {render.state === "created" && (
                        <BasePopConfirm
                            label={"Wirklich Ablehnen?"}
                            okButtonLabel={"Bestätigen"}
                            okButtonClick={() => props.handleUpdateOrderRequestState("declined", render._id)}>
                            <DELETE className="w-6 h-6 cursor-pointer" />
                        </BasePopConfirm>
                    )}
                    {render.state === "accepted" && (
                        <CLOCK
                            onClick={() => props.handleUpdateOrderRequestState("waiting-for-customer-approval", render._id)}
                            className="w-6 h-6 cursor-pointer"
                        />
                    )}
                </>
            ),
        },
    ]

    return (
        <Table<OrderRequestType>
            className="w-full overflow-x-scroll md:overflow-x-hidden bg-white rounded-default "
            pagination={false}
            scroll={{ y: "64vh" }}
            columns={columnsTasks}
            dataSource={props.orderrequest as OrderRequestType[]}
        />
    )
}
