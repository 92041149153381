import { useState } from "react"
import BaseInputArea from "../../../components/elements/BaseInputArea"
import BaseModal from "../../../components/layout/BaseModal"

type AddNoteModalType = {
    taskIsOnCompletion: boolean
    session_id: string
    modalId: number
    task_id: string
    note?: string
    close: () => void
    sendNote: (taskId: string, e?: { note: string; session: string }) => void
}

export default function AddNoteModal(props: AddNoteModalType) {
    const [note, setnote] = useState<{ note: string; session: string }>()

    const handleSetNote = (e: string) => {
        setnote({ note: e, session: props.session_id })
    }

    return (
        <BaseModal size modalId={props.modalId} title={"Problem melden"}>
            <p className="text-center text-sm font-bold py-4 max-w-sm">
                {props.taskIsOnCompletion
                    ? "Soll eine Notiz hinzugefügt werden?"
                    : "Die Aufgabe konnte nicht ordungsgemäß erfüllt werden? Bitte beschreiben Sie das Problem!"}
            </p>
            <BaseInputArea
                label={"Problembeschreibung"}
                placeholder="Problem A, B, C..."
                minRows={10}
                maxRows={10}
                value={props.note ?? undefined}
                onChange={handleSetNote}
            />
            <div className="flex justify-between w-full pt-2 gap-4">
                <button className="btn btn-ghost btn-sm" onClick={props.close}>
                    Abbrechen
                </button>
                <button
                    className="btn btn-success btn-sm"
                    disabled={!props.taskIsOnCompletion && note === undefined}
                    onClick={() => {
                        props.close()
                        props.sendNote(props.task_id, note ?? undefined)
                        setnote(undefined)
                    }}>
                    {props.taskIsOnCompletion && note === undefined ? "Ohne Notiz speichern" : "Speichern"}
                </button>
            </div>
        </BaseModal>
    )
}
