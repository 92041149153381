import { useQuery, useQueryClient } from "@tanstack/react-query"
import BaseHeadBanner from "../../../components/elements/BaseHeadBanner"
import BasePage from "../../../components/layout/BasePage"
import { getCostCenterData, getEmployeeByIdData, getUserGroupsFromKC } from "../api/apiCalls"
import { useParams } from "react-router-dom"
import EmployeeDetailPart from "../components/EmployeeDetailPart"
import ErrorPage from "../../404/ErrorPage"
import { Loading } from "react-daisyui"
import { useContext, useMemo, useState } from "react"
import { Employee } from "../types/Types"
import {
    useAddDocToEmployeeMutation,
    usePatchDocEmployeeMutation,
    usePWResetEmployeeMutation,
    useUpdateEmployeeKcGroupMutation,
    useUpdateEmployeeMutation,
} from "../api/services"
import BaseGrowBody from "../../../components/elements/BaseGrowBody"
import { useDownloadDocumentMutation } from "../../api/services"
import { API } from "../../../api/constants"
import { ModalContext } from "../../../components/contexts/ModalContext"
import { SidePanelContext } from "../../../components/contexts/SidePanelContext"
import BaseAddDocumentModal from "../../../components/elements/BaseAddDocumentModal"
import BasePreview from "../../../components/elements/BasePreview"
import { ReactComponent as CALENDAR } from "../../../assets/CalendarICON.svg"
import { HashLink } from "react-router-hash-link"

export default function MitarbeiterDetailPage() {
    const { id } = useParams()
    const modalCon = useContext(ModalContext)
    const sidePanelCon = useContext(SidePanelContext)

    // GET ALL Keycloak User Groups
    const {
        data: kcData,
        error: kcError,
        isLoading: kcIsLoading,
    } = useQuery({
        queryKey: ["kcUserGroups"],
        queryFn: getUserGroupsFromKC,
    })

    // get single employee by id
    const queryKeySingleEmployee = "employeeData" + id
    const {
        error: singleEmployeeError,
        data: singleEmployeeData,
        isLoading: singleEmployeeIsLoading,
    } = useQuery({
        queryKey: [queryKeySingleEmployee],
        queryFn: () => getEmployeeByIdData(id ?? ""),
        enabled: !!id,
    })
    const displayedEmployee: Employee = useMemo(() => singleEmployeeData, [singleEmployeeData])
    const [updatedEmployee, setupdatedEmployee] = useState<Employee>()

    // GET ALL Cost Center Query
    const {
        error: costcenterError,
        data: costcenterData,
        isLoading: costcenterisLoading,
    } = useQuery({
        queryKey: ["cost-center-all"],
        queryFn: getCostCenterData,
    })

    // reset Employee Password
    const queryClient = useQueryClient()
    const { mutate: resetPW } = usePWResetEmployeeMutation(queryClient, id ?? "")
    const resetPassword = () => {
        resetPW(displayedEmployee.kcId ?? "")
    }

    const { mutate: updateEmployee } = useUpdateEmployeeMutation(queryClient, id ?? "", queryKeySingleEmployee)
    const { mutate: updateEmployeeKcGroup } = useUpdateEmployeeKcGroupMutation(queryClient, id ?? "", queryKeySingleEmployee)
    const handleUpdateEmployee = () => {
        if (
            Object.keys(updatedEmployee as Employee).length > 1 ||
            (Object.keys(updatedEmployee as Employee).length === 1 && Object.keys(updatedEmployee as Employee)[0] !== "kcId")
        ) {
            updateEmployee({
                id: displayedEmployee._id ?? "",
                body: updatedEmployee as Employee,
            })
            if (updatedEmployee?.kcId && updatedEmployee?.kcId !== displayedEmployee.position) {
                updateEmployeeKcGroup({
                    kcid: displayedEmployee.kcId ?? singleEmployeeData.kcId ?? "",
                    gid: kcData.rbac.find((item: { name: string; id: any }) => item.name === updatedEmployee.kcId).id,
                })
            }
        }
        if (Object.keys(updatedEmployee as Employee).length === 1 && Object.keys(updatedEmployee as Employee)[0] === "kcId") {
            updateEmployeeKcGroup({
                kcid: displayedEmployee.kcId ?? singleEmployeeData.kcId ?? "",
                gid: kcData.rbac.find((item: { name: string; id: any }) => item.name === updatedEmployee?.kcId).id,
            })
        }
        setupdatedEmployee(undefined)
    }

    // add file
    const { mutate: addDoc } = useAddDocToEmployeeMutation(queryClient, id ?? "")

    // download file from employee
    const { mutate: downloadFile } = useDownloadDocumentMutation()
    const handleDownload = (s3Key: string, filename: string) => {
        downloadFile({ id: displayedEmployee._id ?? "", s3Key: s3Key, filetype: "employee", filename: filename })
    }

    // delete file
    const { mutate: deleteDoc } = usePatchDocEmployeeMutation(queryClient, id ?? "")
    const handleDeleteDoc = (e: string) => {
        deleteDoc({
            id: id ?? "",
            file: e,
        })
    }

    // get preview of pdf file
    const [selectedS3Key, setselectedS3Key] = useState<string>()
    const employeeDownloadUrl = useMemo(() => {
        return singleEmployeeError || singleEmployeeIsLoading || !selectedS3Key ? undefined : API + `/employee/${id}/download?s3Key=${selectedS3Key}`
    }, [id, selectedS3Key, singleEmployeeError, singleEmployeeIsLoading])

    return (
        <BasePage>
            <BaseHeadBanner
                title={displayedEmployee?.first_name + " " + displayedEmployee?.last_name}
                button={
                    <HashLink className="btn btn-primary" to={"/kalender/" + displayedEmployee?._id}>
                        <CALENDAR className="w-6 h-6" />
                    </HashLink>
                }
            />
            {!kcError && !kcIsLoading && kcData && singleEmployeeData && (
                <BaseGrowBody classProps="pt-4 flex ">
                    {/* Detailed Information */}
                    {(singleEmployeeIsLoading || costcenterisLoading) && <Loading />}
                    {(singleEmployeeError || costcenterError) && <ErrorPage />}
                    {singleEmployeeData && !singleEmployeeError && !singleEmployeeIsLoading && costcenterData && (
                        <EmployeeDetailPart
                            updatedEmployee={updatedEmployee}
                            setupdatedEmployee={setupdatedEmployee}
                            resetDataToOriginal={() => setupdatedEmployee(undefined)}
                            displayedEmployee={displayedEmployee}
                            resetPassword={resetPassword}
                            kcGroups={kcData}
                            costcenter={costcenterData}
                            handleUpdateEmployee={handleUpdateEmployee}
                            handleDownload={handleDownload}
                            handleDeleteFile={handleDeleteDoc}
                            showPreviewFunc={(e: string) => {
                                sidePanelCon?.trigger(1)
                                setselectedS3Key(e)
                            }}
                            openAddDocModal={() => modalCon?.trigger(0)}
                        />
                    )}
                    {/* <div className="w-full h-full bg-blue-400" /> */}
                </BaseGrowBody>
            )}
            <BaseAddDocumentModal addDoc={addDoc} closeModal={() => modalCon?.trigger(0)} modalId={0} selectedId={displayedEmployee?._id ?? ""} />
            <BasePreview title={"Dokumentvorschau"} sidePanelId={1} previewType={"document"} content={employeeDownloadUrl} />
        </BasePage>
    )
}
