import BasePage from "../../../components/layout/BasePage"
import HeadBanner from "../../../components/elements/BaseHeadBanner"
import { useContext, useState } from "react"
import Transactions from "../components/Transactions/Transactions"
import { ModalContext } from "../../../components/contexts/ModalContext"
import { transactionElementType } from "../types"
import Charts from "../components/Charts/Charts"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { getPaginatedData } from "../../api/apiCalls"
import { useDelayWhenSearchWithPagination } from "../../../services/functions"
import AssignTransactionModal from "../components/Transactions/AssignTransactionModal"
import { Loading } from "react-daisyui"
import ErrorPage from "../../404/ErrorPage"
import { defaultTransactionStatus } from "../../../data/defaultValues"
import { useRefetchTransactions } from "../../Buchhaltung/api/services"
import { getHardcodedData } from "../api/apiCalls"
import BaseGrowBody from "../../../components/elements/BaseGrowBody"

export default function Dashboard() {
    const modalCon = useContext(ModalContext)
    const [transaction, settransaction] = useState<transactionElementType>()
    const [page, setpage] = useState(1)
    const handlePageChange = (page: number) => {
        setpage(page)
    }
    const [searchTerm, setsearchTerm] = useState("")
    const searchParams = useDelayWhenSearchWithPagination(searchTerm)
    const queryKey = "transactionData" + page + searchParams
    const { data, isLoading, error } = useQuery({
        queryKey: [queryKey],
        queryFn: () =>
            getPaginatedData({ pageType: "banking", page: page, search: searchParams, limit: 20, status: defaultTransactionStatus.UNASSIGNED }),
    })

    // QUERY TO SHOW STATS
    const queryKeyPaymentsHardecoded = "hardCodeDataPaymentsJanuary&February"
    const {
        data: hardcodeDataPayments,
        isLoading: hardcodeLoadingPayments,
        error: hardcodeErrorPayments,
    } = useQuery({
        queryKey: [queryKeyPaymentsHardecoded],
        queryFn: getHardcodedData,
    })

    const queryClient = useQueryClient()
    const { mutate: refreshTransactions } = useRefetchTransactions(queryClient, queryKey)
    // const refreshTransactions = () => userefreshTransactions

    return (
        <BasePage>
            <HeadBanner title="Dashboard" />
            <BaseGrowBody>
                <div className="bg-white rounded-default p-4 h-full w-full my-4 text-3xl">
                    <h1 className="pb-8">
                        Erstellte Zahlungsaufforderungen für Januar in Summe:
                        <br />
                        <span className="text-5xl">
                            {new Intl.NumberFormat("de-DE", { style: "currency", currency: "EUR" }).format(hardcodeDataPayments?.paymentsJanuary)}
                        </span>
                    </h1>
                    <h1 className="pb-8">
                        Erstellte Zahlungsaufforderungen für Februar in Summe:
                        <br />
                        <span className="text-5xl">
                            {new Intl.NumberFormat("de-DE", { style: "currency", currency: "EUR" }).format(hardcodeDataPayments?.paymentsFebruary)}
                        </span>
                    </h1>
                </div>
                {/* <Charts />
                {error && <ErrorPage />}
                {isLoading && <Loading />}
                {data && !error && !isLoading && (
                    <Transactions
                        refreshTransactions={refreshTransactions}
                        openModal={() => modalCon?.trigger(1)}
                        func={settransaction}
                        data={data}
                        searchTerm={searchTerm}
                        setsearchTerm={setsearchTerm}
                        page={page}
                        handlePageChange={handlePageChange}
                    />
                )} */}
            </BaseGrowBody>
            <AssignTransactionModal
                modalId={1}
                transaction={transaction}
                transactionReloadLink={"transactionData" + page + searchParams}
                closeModal={() => modalCon?.trigger(1)}
            />
        </BasePage>
    )
}
