import { Select } from "antd"
import { useMemo } from "react"
import { BaseSelectionType } from "../types"

export default function BaseSelection(props: BaseSelectionType) {
    // Transforms the input array in the needed format
    const arrayData = useMemo(() => {
        return props.data.map((obj) => ({
            value: obj,
            label: obj,
        }))
    }, [props.data])

    return (
        <div className={`flex flex-col w-full z-10 ${props.classProps}`}>
            <label className={`text-sm pl-2 ${!props.bgwhite && "pb-1"}`}>{props.label}</label>
            <Select
                variant={props.variant}
                style={props.bgwhite ? { height: "3.5rem", backgroundColor: "white", margin: 0 } : { height: "3.5rem" }}
                value={props.value ?? undefined}
                defaultValue={props.defaultValue ?? undefined}
                disabled={arrayData.length === 0 || arrayData === undefined || props.disabled}
                placeholder={props.placeholder}
                className={`w-full ${props.bgwhite && "rounded-default shadow-lg"}`}
                bordered={props.bgwhite ? false : true}
                onChange={(e: string) => props.setId(e)}
                options={arrayData}
            />
        </div>
    )
}
