import dayjs from "dayjs"
import { motion } from "framer-motion"
import BaseInputField from "../../../../components/elements/BaseInputField"
import { BillPost, OfferPost } from "../../../../data/Types"
import { Customer } from "../../../Kunden/types/Types"
import { returnAddressString } from "../../../../services/functions"
import { ObjectType } from "../../../Objekte/types/types"

type AddBillOrOfferModalScreen4Type = {
    newBillOrOffer: BillPost | OfferPost
    visible: boolean
    customer: Customer | any
}

export default function AddBillOrOfferModalScreen4(props: AddBillOrOfferModalScreen4Type) {
    return (
        <motion.div
            initial={{
                x: "100%",
            }}
            animate={{
                x: props.visible ? 0 : "100%",
            }}
            transition={{
                type: "ease",
            }}
            className="absolute top-0 left-0 w-full h-full pl-3 z-30 bg-white pb-2 overflow-y-scroll pt-4">
            {(props.newBillOrOffer as BillPost)?.billType && (
                <BaseInputField
                    classProps="pt-2"
                    disabled
                    label={"Rechnungstyp"}
                    type={"text"}
                    value={(props.newBillOrOffer as BillPost)?.billType}
                    onChange={() => {}}
                />
            )}
            {props.newBillOrOffer?.customer_id && (
                <BaseInputField
                    classProps="pt-2"
                    disabled
                    label={"Kunde"}
                    type={"text"}
                    value={props.customer?.customer?.name ?? props.customer?.customer?.customer?.name ?? ""}
                    onChange={() => {}}
                />
            )}
            {props.newBillOrOffer?.object_id && (
                <BaseInputField
                    classProps="pt-2"
                    disabled
                    label={"Objekt"}
                    type={"text"}
                    value={returnAddressString(props.customer.objects?.find((item: { _id: string | undefined }) => item._id === props.newBillOrOffer?.object_id) as ObjectType)}
                    onChange={() => {}}
                />
            )}
            {(props.newBillOrOffer as BillPost)?.billCategorie && (
                <BaseInputField
                    classProps="pt-2"
                    disabled
                    label={"Rechnungskategorie"}
                    type={"text"}
                    value={(props.newBillOrOffer as BillPost)?.billCategorie}
                    onChange={() => {}}
                />
            )}
            {(props.newBillOrOffer as BillPost)?.billDay && (props.newBillOrOffer as BillPost)?.billType !== "Rechnung" && (
                <BaseInputField
                    classProps="pt-2"
                    disabled
                    label={"Zahltag des Monats"}
                    type={"text"}
                    value={(props.newBillOrOffer as BillPost)?.billDay}
                    onChange={() => {}}
                />
            )}
            {(props.newBillOrOffer as BillPost)?.billDue && (
                <BaseInputField
                    classProps="pt-2"
                    disabled
                    label={"Fällig nach"}
                    type={"text"}
                    value={(props.newBillOrOffer as BillPost)?.billDue}
                    onChange={() => {}}
                />
            )}
            {(props.newBillOrOffer as BillPost)?.serviceDate && (
                <BaseInputField
                    classProps="pt-2"
                    disabled
                    label={"Leistungsdatum"}
                    type={"text"}
                    value={dayjs((props.newBillOrOffer as BillPost)?.serviceDate).format("DD.MM.YYYY")}
                    onChange={() => {}}
                />
            )}
            {(props.newBillOrOffer as BillPost)?.reminderStartOffsetInDays && (props.newBillOrOffer as BillPost)?.manualReminderEnabled && (
                <BaseInputField
                    classProps="pt-2"
                    disabled
                    label={"Nach wie vielen Tagen wird gemahnt"}
                    type={"text"}
                    value={(props.newBillOrOffer as BillPost)?.reminderStartOffsetInDays}
                    onChange={() => {}}
                />
            )}
            {(props.newBillOrOffer as BillPost)?.reminderIntervalInDays && (props.newBillOrOffer as BillPost)?.manualReminderEnabled && (
                <BaseInputField
                    classProps="pt-2"
                    disabled
                    label={"In welchem Abstand wird gemahnt"}
                    type={"text"}
                    value={(props.newBillOrOffer as BillPost)?.reminderIntervalInDays}
                    onChange={() => {}}
                />
            )}
            {(props.newBillOrOffer as BillPost)?.maxReminders && (props.newBillOrOffer as BillPost)?.manualReminderEnabled && (
                <BaseInputField
                    classProps="pt-2"
                    disabled
                    label={"Anzahl an Mahnungen"}
                    type={"text"}
                    value={(props.newBillOrOffer as BillPost)?.maxReminders}
                    onChange={() => {}}
                />
            )}
            {props.newBillOrOffer?.additionalText && (
                <BaseInputField
                    classProps="pt-2"
                    disabled
                    label={"Zusatztext"}
                    type={"text"}
                    value={props.newBillOrOffer?.additionalText}
                    onChange={() => {}}
                />
            )}
            {(props.newBillOrOffer as BillPost)?.forceFirstPayreqSameMonth && (
                <p>Es wird eine vergangene Zahlungsanforderung für diesen Monat erstellt</p>
            )}
            <p className="font-semibold pt-2">Positionen:</p>
            <div className="flex flex-col gap-2 pl-4">
                {props.newBillOrOffer?.positions?.map((item: any) => (
                    <div className="flex flex-row gap-4">
                        <p>{item.description}</p>
                        <p>|</p>
                        <p>Steuern: {item.tax} %</p>
                        <p>|</p>
                        <p>
                            {item.amount} x {item.unit} für {Number(item.singlePrice).toFixed(2)} €
                        </p>
                    </div>
                ))}
            </div>
        </motion.div>
    )
}
