import { useEffect } from "react"
import { Roles } from "../../../auth/RoleMapping"
import { RenderWhenAuthorized } from "../../../components/authentication/RenderWhenAuthorized"
import BaseInputField from "../../../components/elements/BaseInputField"
import { Employee } from "../types/Types"
import BaseSelection from "../../../components/elements/BaseSelection"
import BaseInputArea from "../../../components/elements/BaseInputArea"
import { DocumentDataType } from "../../../components/types"
import BaseDocumentElement from "../../../components/elements/BaseDocumentElement"
import { useKeycloak } from "@react-keycloak/web"
import { ReactComponent as CLOSE } from "../../../assets/CloseICON.svg"
import { Form } from "antd"
import dayjs from "dayjs"

type EmployeeDetailPartType = {
    updatedEmployee: Employee | undefined
    setupdatedEmployee: (e: any) => void // TODO any
    resetDataToOriginal: () => void
    displayedEmployee: Employee
    resetPassword: () => void
    kcGroups: any // TODO any
    costcenter: any[]
    handleUpdateEmployee: () => void
    handleDownload: (a: string, b: string) => void
    showPreviewFunc: (e: string) => void
    openAddDocModal: () => void
    handleDeleteFile: (e: string) => void
}

export default function EmployeeDetailPart(props: EmployeeDetailPartType) {
    const { keycloak } = useKeycloak()
    const [form] = Form.useForm()
    useEffect(() => {
        form.setFieldsValue({
            first_name: props.displayedEmployee.first_name,
            last_name: props.displayedEmployee.last_name,
            birthday: dayjs(props.displayedEmployee.geb).format("YYYY-MM-DD"),
            position: props.displayedEmployee.position,
            contract_start: props.displayedEmployee.contractStart,
            contract_end: props.displayedEmployee.contractEnd,
            email: props.displayedEmployee?.email,
            costcenter: props.costcenter.find((item) => item._id === props.displayedEmployee.kostenstelle).name,
            notes: props.displayedEmployee.notes,
        })
    }, [form, props.displayedEmployee])

    return (
        <div className="flex flex-col mb-4 sm:pb-0 w-full overflow-x-hidden bg-white rounded-default shadow-lg p-4 overflow-y-scroll h-full">
            <Form
                form={form}
                className="w-full h-full overflow-x-hidden"
                onFinish={() => {
                    props.handleUpdateEmployee()
                }}>
                <div className="flex justify-between items-end">
                    <h3 className="font-bold text-2xl pt-2">Persönliche Informationen</h3>
                    <RenderWhenAuthorized requiresAll={[Roles.mitarbeiter_read_write]}>
                        {/* TODO Popconfirm */}
                        <button className="btn btn-ghost btn-sm" onClick={props.resetPassword}>
                            Passwort Zurücksetzen
                        </button>
                    </RenderWhenAuthorized>
                </div>
                <div className="flex flex-col overflow-y-scroll w-full overflow-x-hidden items-center">
                    <div className="w-full border-b my-3" />
                    <div className="flex flex-col md:flex-row gap-2 md:gap-4 w-full">
                        <Form.Item className="mb-2 w-full" name="first_name" rules={[{ required: true, message: "Muss einen Wert enthalten" }]}>
                            <BaseInputField
                                label={"Vorname"}
                                type={"text"}
                                onChange={(newFirstName: string) => {
                                    props.setupdatedEmployee((prevState: any) => ({
                                        ...prevState,
                                        first_name: newFirstName,
                                    }))
                                }}
                            />
                        </Form.Item>
                        <Form.Item className="mb-2 w-full" name="last_name" rules={[{ required: true, message: "Muss einen Wert enthalten" }]}>
                            <BaseInputField
                                label={"Nachname"}
                                type={"text"}
                                onChange={(newItem: string) => {
                                    props.setupdatedEmployee((prevState: any) => ({
                                        ...prevState,
                                        last_name: newItem,
                                    }))
                                }}
                            />
                        </Form.Item>
                    </div>
                    <div className="flex flex-col md:flex-row gap-2 md:gap-4 w-full">
                        <Form.Item className="mb-2 w-full" name="birthday" rules={[{ required: true, message: "Muss einen Wert enthalten" }]}>
                            <BaseInputField
                                label={"Geburtstag"}
                                type={"date"}
                                onChange={(newItem: string) => {
                                    props.setupdatedEmployee((prevState: any) => ({
                                        ...prevState,
                                        geb: newItem,
                                    }))
                                }}
                            />
                        </Form.Item>
                        <Form.Item className="mb-2 w-full" name="position" rules={[{ required: true, message: "Muss einen Wert enthalten" }]}>
                            <BaseSelection
                                disabled={!keycloak.hasRealmRole(Roles.mitarbeiter_manage_permissions)}
                                label={"Position"}
                                setId={(newItem: string) => {
                                    form.setFieldsValue({ position: newItem })
                                    props.setupdatedEmployee((prev: any) => ({
                                        ...prev,
                                        kcId: newItem,
                                    }))
                                }}
                                placeholder={"Position auswählen"}
                                data={props.kcGroups.rbac.map((item: { id: string; name: string }) => item.name)}
                            />
                        </Form.Item>
                    </div>
                    <div className="flex flex-col md:flex-row gap-2 md:gap-4 w-full">
                        <Form.Item className="mb-2 w-full" name="contract_start" rules={[{ required: true, message: "Muss einen Wert enthalten" }]}>
                            <BaseInputField
                                label={"Vertragsbeginn"}
                                type={"date"}
                                onChange={(newItem: string) => {
                                    props.setupdatedEmployee((prevState: any) => ({
                                        ...prevState,
                                        contractStart: newItem,
                                    }))
                                }}
                            />
                        </Form.Item>
                        <Form.Item className="mb-2 w-full" name="contract_end" rules={[{ required: false, message: "Muss einen Wert enthalten" }]}>
                            <BaseInputField
                                label={"Vertragsende (optional)"}
                                type={"date"}
                                onChange={(newItem: string) => {
                                    props.setupdatedEmployee((prevState: any) => ({
                                        ...prevState,
                                        contractEnd: newItem,
                                    }))
                                }}
                            />
                        </Form.Item>
                    </div>
                    <div className="flex flex-col md:flex-row gap-2 md:gap-4 w-full">
                        <Form.Item className="mb-2 w-full" name="email" rules={[{ required: true, message: "Muss einen Wert enthalten" }]}>
                            <BaseInputField
                                label={"E-Mail"}
                                type={"email"}
                                // status={!validateEmail(props.selectedEmployee.email) ? "error" : undefined}
                                onChange={(newItem: string) => {
                                    props.setupdatedEmployee((prevState: any) => ({
                                        ...prevState,
                                        email: newItem,
                                    }))
                                }}
                            />
                        </Form.Item>
                        <div className="flex flex-row lg:justify-evenly gap-4 md:py-0 w-full items-center">
                            <div className="form-control pt-8">
                                <label className="label cursor-pointer">
                                    <input
                                        type="checkbox"
                                        checked={props.updatedEmployee?.vehicle ?? props.displayedEmployee.vehicle}
                                        onChange={() => {
                                            props.setupdatedEmployee((prevState: any) => ({
                                                ...prevState,
                                                vehicle: !props.updatedEmployee?.vehicle,
                                            }))
                                        }}
                                        className="checkbox"
                                    />
                                    <span className="label-text pl-4">Dienstwagen</span>
                                </label>
                            </div>
                            <div className="form-control pt-8">
                                <label className="label cursor-pointer">
                                    <input
                                        type="checkbox"
                                        checked={props.updatedEmployee?.trailperiod ?? props.displayedEmployee?.trailperiod}
                                        className="checkbox"
                                        onChange={() => {
                                            props.setupdatedEmployee((prevState: any) => ({
                                                ...prevState,
                                                trailperiod: !props.updatedEmployee?.trailperiod,
                                            }))
                                        }}
                                    />
                                    <span className="label-text pl-4">Probezeit</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <Form.Item className="mb-2 w-full" name="costcenter" rules={[{ required: true, message: "Muss einen Wert enthalten" }]}>
                        <BaseSelection
                            label={"Kostenstelle"}
                            setId={(newItem: any) => {
                                form.setFieldsValue({ costcenter: newItem })
                                let id = props.costcenter?.find((item: { name: string }) => newItem === item.name)?._id
                                props.setupdatedEmployee((prevState: any) => ({
                                    ...prevState,
                                    kostenstelle: id ?? "",
                                }))
                            }}
                            placeholder={"Kostenstelle auswählen"}
                            data={props.costcenter?.map((item: { _id?: string; name: string }) => item.name)}
                        />
                    </Form.Item>
                    <Form.Item className="mb-2 w-full" name="notes" rules={[{ required: false, message: "Muss einen Wert enthalten" }]}>
                        <BaseInputArea
                            label={"Bemerkung"}
                            onChange={(newItem: string) => {
                                props.setupdatedEmployee((prevState: any) => ({
                                    ...prevState,
                                    notes: newItem,
                                }))
                            }}
                        />
                    </Form.Item>
                    <RenderWhenAuthorized requiresAll={[Roles.mitarbeiter_read_write]}>
                        {props.updatedEmployee !== undefined && (
                            <div className="w-[98%] flex items-center h-fit justify-between">
                                <button type="reset" className="btn btn-sm btn-error btn-outline" onClick={props.resetDataToOriginal}>
                                    <CLOSE className="w-6 h-6" />
                                </button>
                                <button className="btn btn-sm btn-success" type="submit" onClick={props.handleUpdateEmployee}>
                                    Speichern
                                </button>
                            </div>
                        )}
                    </RenderWhenAuthorized>
                    <div className="flex flex-col pt-2 w-full overflow-x-hidden">
                        <label className="text-sm pl-2 pb-1">Dokumente</label>
                        {props.displayedEmployee?.s3Documents?.map((item: DocumentDataType, index: number) => (
                            <BaseDocumentElement
                                key={index}
                                title={item.title}
                                timestamp={item.timestamp}
                                roles={[Roles.mitarbeiter_read_write]}
                                downloadFunc={() => props.handleDownload(item.s3Key, item.title)}
                                deleteFunc={() => props.handleDeleteFile(item.s3Key)}
                                showPreviewFunc={() => props.showPreviewFunc(item.s3Key)}
                            />
                        ))}
                        {props.displayedEmployee?.s3Documents?.length === 0 && <p className="pl-4 text-xs">Keine Dokumente vorhanden</p>}

                        <RenderWhenAuthorized requiresAll={[Roles.mitarbeiter_read_write]}>
                            <button
                                type="button"
                                className="hidden md:block btn btn-sm bg-base-100 shadow-lg border-none hover:bg-white/40 mt-2 mb-4 mr-4 self-end"
                                onClick={props.openAddDocModal}>
                                Dokument hinzufügen
                            </button>
                        </RenderWhenAuthorized>
                    </div>
                </div>
            </Form>
        </div>
    )
}
