import React, { Key } from "react"
import BaseDocumentElement from "../../../../../components/elements/BaseDocumentElement"
import { DocumentDataType } from "../../../../../components/types"

type OfferDetailFilesType = {
    data: {
        additional_s3Documents: DocumentDataType[]
    }
    handleDownload: (item: { s3Key: string; filename: string }) => void
    handleChangePreviewElement: (e: string) => void
}

export default function OfferDetailFiles(props: OfferDetailFilesType) {
    console.log(props.data.additional_s3Documents)
    return (
        <div className="mt-4 w-full h-fit max-h-[16vh] rounded-default shadow-lg bg-white p-4 flex flex-col">
            <p className="w-full border-b pb-2 font-medium">Zugeordnete Dateien</p>
            <div className="overflow-y-scroll">
                {!props.data.additional_s3Documents || props.data.additional_s3Documents.length === 0 ? (
                    <p className="text-center uppercase text-sm text-gray-300 font-medium pt-1">Keine Dokumente hochgeladen</p>
                ) : (
                    props.data?.additional_s3Documents?.map((item: DocumentDataType, index: Key) => (
                        <BaseDocumentElement
                            key={index}
                            title={item.title}
                            timestamp={item.timestamp}
                            showPreviewFunc={() => props.handleChangePreviewElement(item.s3Key)}
                            downloadFunc={() => props.handleDownload({ s3Key: item.s3Key, filename: item.title })}
                        />
                    ))
                )}
            </div>
        </div>
    )
}
